import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/home/home";
import About from "./pages/about/about";
import Waitlist from "./pages/waitlist/waitlist";
import Features from "./pages/features/features";
import Resources from "./pages/resources/resources";
import Blogs from "./pages/resources/blogs";
import Contact from "./pages/contact/contact";
import Store from "./pages/store/store";
import ProductDetail from "./pages/store/productDetail";
import StoreBrowse from "./pages/store/storeBrowse";
import ResKnowMoreCard from "./pages/store/resKnowmore";
import RefundCancellation from "./pages/refundCancellation/refundCancellation";
import PrivacyPolicy from "./pages/privacyPolicy/privacyPolicy";
import TermsConditions from "./pages/termsConditions/termsConditions";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<About />} />
        <Route path="/waitlist" element={<Waitlist />} />
        <Route path="/features" element={<Features />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/store" element={<Store />} />
        <Route path="/productDetail" element={<ProductDetail />} />
        <Route path="/storeBrowse" element={<StoreBrowse />} />
        <Route path="/resKnowmore" element={<ResKnowMoreCard />} />
        <Route path="/refundCancellation" element={<RefundCancellation />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/termsConditions" element={<TermsConditions />} />
      </Routes>
    </>
  );
}

export default App;
