import React from "react";
import Navbar from "../../components/navbar";
import image from "../../assets/image/discription.png";
import image2 from "../../assets/image/detailimg2.png";
import image3 from "../../assets/image/resppd.png";
import Footer from "../../components/footer";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/image/logo.png";
import ResponsiveNav from "../../components/responsiveNav";

export default function ProductDetail() {
  const navigate = useNavigate();

  const [description, setDescription] = useState(false);
  const [started, setStarted] = useState(false);
  const [pricing, setPricing] = useState(true);
  const [support, setSupport] = useState(false);

  const descriptionHandler = () => {
    setDescription(true);
    setStarted(false);
    setPricing(false);
    setSupport(false);
  };
  const startedHandler = () => {
    setDescription(false);
    setStarted(true);
    setPricing(false);
    setSupport(false);
  };

  const pricingHandler = () => {
    setDescription(false);
    setStarted(false);
    setSupport(false);

    setPricing(true);
  };

  const supportHandler = () => {
    setSupport(true);
    setDescription(false);
    setStarted(false);
    setPricing(false);
  };

  return (
    <div className="font-nunito">
      <div
        // style={{
        //   background:
        //     "linear-gradient(160deg, rgba(12, 78, 126, 0.711) 24.17%, rgba(4, 37, 61, 0.9) 90.04%, rgba(11, 50, 96, 0.9) 90.04%)",
        // }}
        className="h-[665px] hidden md:block bg-center bg-cover bg-no-repeat bg-[url('./assets/image/navbackground.png')] "
      >
        <div className="w-full h-[120px]">
          <div className="px-2 flex justify-between items-center w-full h-full">
            <div className="flex items-center">
              <div className="flex gap-[12px]">
                <img
                  src={logo}
                  alt="logo"
                  className="ml-[35px] cursor-pointer w-[34px] h-[33px]"
                  onClick={() => navigate("/")}
                />
                <h1
                  className="text-[26px] text-[#FFFFFF] font-[700] tracking-widest cursor-pointer"
                  onClick={() => navigate("/")}
                >
                  PANKH
                </h1>
              </div>
              <ul className="flex ml-[50px] font-[700]">
                <li className="p-4 text-[20px] text-[#FFFFFF]  tracking-wide">
                  <Link to="/store"> Store</Link>
                </li>
                <li className="p-4 text-[20px] text-[#FFFFFF] ml-[15px]  tracking-wide relative">
                  <Link to="/resources"> Resources </Link>
                </li>
                <li className="p-4 text-[20px] text-[#FFFFFF] ml-[15px]  tracking-wide">
                  <Link to="/about">About</Link>
                </li>
                <li className="p-4 text-[20px] text-[#FFFFFF] ml-[15px]  tracking-wide">
                  <Link to="/features"> Features </Link>
                </li>
                <li className="p-4 text-[20px] text-[#FFFFFF] ml-[15px]  tracking-wide">
                  <Link to="/contact"> Contact Us </Link>
                </li>
              </ul>
            </div>
            {/* <div className="flex m-4 relative">
              <button
                className={`mr-[20px] flex items-center font-[700] justify-center text-center border-[0.4px] capitalize border-solid rounded-[10px] w-[138px] h-[51px] text-[#FFFFFF]`}
              >
                Log In
              </button>

              <button
                style={{
                  background:
                    "conic-gradient(from 300deg at 105.92% -41.84%, rgb(255, 165, 2) 192deg, rgb(212, 99, 9) 343deg)",
                }}
                className={`flex items-center font-[700] justify-center text-center tracking-widest capitalize border-solid rounded-[10px] w-[138px] h-[51px] bg-[#FFA502] text-[#FFFFFF]`}
              >
                Sign up
              </button>
            </div> */}
              <div
              className="flex m-4 relative"
              onClick={() => navigate("/waitlist")}
            >
              <span className="absolute -ml-[68px]">
                <svg
                  width="96"
                  height="65"
                  viewBox="0 0 96 65"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className=" "
                >
                  <g clip-path="url(#clip0_3055_64766)">
                    <path
                      d="M78.8164 10.0469L91.8941 20.8338L79.0131 29.6426"
                      stroke="#FFFFFF"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <path
                    d="M92 20.2263C42.7679 9.71354 12.4256 26.6061 2 64.1523"
                    stroke="#FFFFFF"
                    stroke-width="3"
                  />
                  <defs>
                    <clipPath id="clip0_3055_64766">
                      <rect
                        width="39.1935"
                        height="19.5255"
                        fill="white"
                        transform="matrix(0.0100383 0.99995 0.997109 0.0759842 75.4727 0)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <Link to="/waitlist">
                  <p className="w-[220px] italic text-center text-[22px] text-[#FFFFFF] -ml-[120px]">
                    Join the Waitlist instead
                  </p>
                </Link>
              </span>

              <button
                style={{
                  background:
                    "conic-gradient(from 300deg at 105.92% -41.84%, rgb(255, 165, 2) 192deg, rgb(212, 99, 9) 343deg)",
                }}
                className={`flex items-center font-[700] justify-center text-center tracking-widest capitalize border-solid rounded-[10px] w-[168px] h-[51px] bg-[#FFA502] text-[#FFFFFF]`}
              >
                Join Waitlist
              </button>
            </div>
          </div>
        </div>
        <div className="ml-[50px] text-[#E0E0E0B8] text-[18px] flex">
          <Link to="/"> Home {">"} </Link>
          <p className="ml-[8px] font-[600] tracking-wider text-[#E0E0E0B8] text-[20px] -mt-[3px]">
            Accounting {">"}
          </p>
          <p className="ml-[8px] font-[600] tracking-wider text-[#FFFFFF] text-[20px] -mt-[2px]">
            Lorem
          </p>
        </div>
        <div className="flex flex-col justify-center items-center">
          <div className="text-[55px] tracking-wider  font-[800] text-[#FFFFFF] mt-[60px]">
            LoremIp
          </div>
          <div className="text-[#FFFFFFB8] mt-[20px] text-[24px] font-[800] text-center w-[850px]">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce risus
            eu purus odio egestas cursus.
          </div>
          <div className="flex justify-around mt-[50px]">
            <button className="text-[#B3F0FEC7] text-[24px] font-[600] bg-[#5DB0FC36] px-[34px] py-[10px]">
              Connection : Tc6
            </button>
            <button className="text-[#B3F0FEC7] text-[24px] font-[600] bg-[#5DB0FC36] px-[34px] py-[10px] ml-[64px] mr-[64px]">
              Connection : Tc6
            </button>
            <button className="text-[#B3F0FEC7] text-[24px] font-[600] bg-[#5DB0FC36] px-[34px] py-[10px]">
              Connection : Tc6
            </button>
          </div>
          <div className="flex relative mt-[50px] ">
            <button
              style={{
                background:
                  "conic-gradient(from 300deg at 105.92% -41.84%, rgb(255, 165, 2) 192deg, rgb(212, 99, 9) 343deg)",
              }}
              className={` flex items-center text-[18px] tracking-widest font-[700] justify-center text-center  capitalize rounded-[10px] py-[14px] px-[74px] text-[#FFFFFF]`}
            >
              Deploy
            </button>
          </div>
        </div>
      </div>
      <ResponsiveNav Main={"Lorempal"}/>
      <div className="bg-[#F8FDFF] md:pt-[80px] md:pb-[200px] md:px-[80px] p-5 md:p-0">
      <button onClick={() => navigate(-1)} className="text-[#747474] md:hidden mt-5 text-[14px] font-[700] gap-1 flex">
          <span className="mt-[3px] mr-1">
            <svg
              width="16"
              height="14"
              viewBox="0 0 16 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 6.00125H3.14L6.77 1.64125C6.93974 1.43704 7.0214 1.17375 6.99702 0.909329C6.97264 0.644902 6.84422 0.400991 6.64 0.231252C6.43578 0.0615137 6.1725 -0.0201482 5.90808 0.0042315C5.64365 0.0286112 5.39974 0.157036 5.23 0.361252L0.23 6.36125C0.196361 6.40898 0.166279 6.45911 0.14 6.51125C0.14 6.56125 0.14 6.59125 0.0700002 6.64125C0.0246737 6.75591 0.000941121 6.87796 0 7.00125C0.000941121 7.12454 0.0246737 7.24659 0.0700002 7.36125C0.0700002 7.41125 0.0699999 7.44125 0.14 7.49125C0.166279 7.54339 0.196361 7.59353 0.23 7.64125L5.23 13.6413C5.32402 13.7541 5.44176 13.8449 5.57485 13.9071C5.70793 13.9694 5.85309 14.0015 6 14.0013C6.23365 14.0017 6.46009 13.9203 6.64 13.7713C6.74126 13.6873 6.82496 13.5842 6.88631 13.4679C6.94766 13.3515 6.98546 13.2242 6.99754 13.0932C7.00961 12.9622 6.99573 12.8302 6.95669 12.7046C6.91764 12.579 6.8542 12.4623 6.77 12.3613L3.14 8.00125H15C15.2652 8.00125 15.5196 7.8959 15.7071 7.70836C15.8946 7.52082 16 7.26647 16 7.00125C16 6.73604 15.8946 6.48168 15.7071 6.29415C15.5196 6.10661 15.2652 6.00125 15 6.00125Z"
                fill="#747474"
              />
            </svg>
          </span>{" "}
          Back
        </button>
        <div className="bg-[#FFFFFF] hidden md:flex cursor-pointer border-[#D6D6D63D] border-[1px] h-[65px] justify-evenly items-center">
          <h2
            className={`text-[#8D9298] text-[26px] font-[500] cursor-pointer hover:text-[#0D2950] ${
              description && "bg-[#FFFFFF] text-[#0D2950] font-[600]"
            }`}
            onClick={descriptionHandler}
          >
            Description
          </h2>
          <div className="h-[60px] w-[0px] border-[#E6E6E6] border-[1px]"></div>
          <h2
            className={`text-[#8D9298] text-[26px] font-[500] cursor-pointer hover:text-[#0D2950] ${
              started && "bg-[#FFFFFF] text-[#0D2950] font-[600]"
            }`}
            onClick={startedHandler}
          >
            Getting Started
          </h2>
          <div className="h-[60px] w-[0px] border-[#E6E6E6] border-[1px]"></div>

          <h2
            className={`text-[#8D9298] text-[26px] font-[500] cursor-pointer hover:text-[#0D2950] ${
              pricing && "bg-[#FFFFFF] text-[#0D2950] font-[600]"
            }`}
            onClick={pricingHandler}
          >
            Pricing
          </h2>
          <div className="h-[60px] w-[0px] border-[#E6E6E6] border-[1px]"></div>

          <h2
            className={`text-[#8D9298] text-[26px] font-[500] cursor-pointer hover:text-[#0D2950] ${
              support && "bg-[#FFFFFF] text-[#0D2950] font-[600]"
            }`}
            onClick={supportHandler}
          >
            Support
          </h2>
        </div>
        <div className="flex overflow-x-scroll md:hidden scrollbar-hide mt-[28px]">
        <div class="flex flex-nowrap gap-2">

          <h2 className={`text-[16px] w-[120px] pt-[1px]  h-[28px] px-[15px] font-[600] tracking-wider text-[#747474B8] hover:bg-[#FFFFFF] hover:text-[#2687C8] hover:border-[1px] hover:border-[#2687C8]     ${
              description && "bg-[#FFFFFF] text-[#2687C8] border-[1px] border-[#2687C8]  font-[600]"
            } `}                           onClick={descriptionHandler}
            >

          Description
          </h2>
          <h2 className={`text-[16px] w-[158px] h-[28px] mt-[1px] px-[15px] font-[600] tracking-wider text-[#747474B8] hover:bg-[#FFFFFF] hover:text-[#2687C8] hover:border-[1px] hover:border-[#2687C8]      ${
             started && "bg-[#FFFFFF] text-[#2687C8] border-[1px] border-[#2687C8]  font-[600]"
            } `}             onClick={startedHandler}
            >
          Getting Started

          </h2>
          <h2 className={`text-[16px] w-[100px] mt-[1.5px]  h-[28px] px-[25px] font-[600] tracking-wider text-[#747474B8] hover:bg-[#FFFFFF] hover:bg-[#FFFFFF] hover:text-[#2687C8] hover:border-[1px] hover:border-[#2687C8]   ${
              pricing && "bg-[#FFFFFF] text-[#2687C8] border-[1px] border-[#2687C8]  font-[600]"
            } `}                          onClick={pricingHandler}
            >

          Plans
          </h2>
          <h2 className={`text-[16px] mt-[1px] w-[100px] h-[28px] px-[15px] font-[600] tracking-wider text-[#747474B8] hover:bg-[#FFFFFF] hover:text-[#2687C8] hover:border-[1px] hover:border-[#2687C8]   ${
              support && "bg-[#FFFFFF] text-[#2687C8] border-[1px] border-[#2687C8] font-[600]"
            } `}                          onClick={supportHandler}
            >

          Support
          </h2>
          </div>
        </div>
        {description && (
          <div className="flex flex-col justify-center items-center md:items-start mt-[25px] md:mt-0">
            
              <h2 className="text-[#074381] text-[20px] font-[700] md:hidden">Lorem Discription </h2>
              <h3 className="text-[#747474D1] text-[16px] font-[600] md:hidden">Lörem ipsum multinar mivevis och. </h3>
            
            <div className="flex mt-[106px] w-full hidden md:block">
              <img src={image} alt="#" />
            </div>
            <div className="md:hidden mt-[10px]">
              <img src={image3} alt="" />
            </div>
            <div className="md:mt-[65px] mt-5 flex flex-col justify-center items-center md:items-start ">
              <h2 className="text-[#0D2950] text-[20px] font-[700] md:text-[32px] md:font-[800]">
                About Product
              </h2>
              <p className="text-[#615F5F] mt-4 text-[16px] text-center md:text-left md:text-[22px] md:font-[600]">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Ultrices imperdiet non sit sollicitudin non turpis donec auctor.
                Eget quis mi convallis proin eu justo suspendisse. Ut ipsum,
                aliquam posuere vitae pellentesque. Adipiscing ornare mi quisque
                nunc sit vel mauris, vitae leo. Placerat quisque semper
                scelerisque dolor.
              </p>
              <ul className="text-[#615F5F] list-disc md:text-[20px] mt-[20px] md:mt-[40px] md:ml-[24px]">
                <li className="md:mt-[5px]">Lorem ipsum</li>
                <li className="md:mt-[5px]">Eget quis</li>
                <li className="md:mt-[5px]">Adipiscing ornare mior</li>
                <li className="md:mt-[5px]">Ultrices imperdiet</li>
                <li className="md:mt-[5px]">Lectus id amet.</li>
                <li className="md:mt-[5px]">Lorem, ipsum.</li>
                <li className="md:mt-[5px]">Lorem, ipsum dolor.</li>
                <li className="md:mt-[5px]">Lorem ipsum</li>
              </ul>

              <h2 className="text-[#0D2950] mt-[44px] text-[20px] font-[700] md:text-[32px] md:font-[800] md:mt-[90px]">
                Lorem Ipsum
              </h2>
              <p className="md:text-[22px] mt-[15px] text-[16px] text-[#615F5F] font-[600] md:mt-[20px]">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.{" "}
              <br />
              <span className="md:text-[22px] text-[16px] text-[#1E7BD0] font-[600]">
                Adipiscing ornare mi quisque nunc sit .
              </span>
              </p>

              <p className="md:text-[22px] text-[16px] text-[#615F5F] font-[600] mt-[20px]">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.{""}
              <br />
              <span className="md:text-[22px] text-[16px] text-[#1E7BD0] font-[600]">
                 Adipiscing ornare mi quisque nunc sit .
              </span>
              </p>

              <p className="md:text-[22px] text-[16px] text-[#615F5F] font-[600] mt-[20px]">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.{" "}
              <br />
              <span className="md:text-[22px] text-[16px] text-[#1E7BD0] font-[600] ">
                Adipiscing ornare mi quisque nunc sit .
              </span>
              </p>
            </div>
          </div>
        )}
        {started && (
          <>
            <div className="flex flex-col mt-6 md:mt-0 justify-center items-center md:items-start">
              <h2 className="text-[#124870] text-[20px] font-[700] md:text-[32px] md:font-[800] md:mt-[105px]">
                Lorem Ipsum consier
              </h2>
              <h3 className="text-[#5D6268] md:text-[22px] text-[16px] font-[600] md:font-[500] md:mt-[15px]">
                Following lorem ispum amet amdedsing elit
              </h3>
              <p className="text-[#615F5F] mt-4 text-[16px] font-[600] md:text-[22px] md:font-[600] md:mt-[42px]">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Ultrices imperdiet amedries elitiser Adipiscing ornare mi
                quisque nunc sit2.
              </p>
              <img src={image2} alt="#" className="md:mt-[25px] mt-4" />
              <p className="text-[#615F5F] text-[16px] mt-4 font-[600] md:text-[22px] md:font-[600] md:mt-[65px] md:pb-[80px]">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Ultrices imperdiet amedries elitiser Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. Fusce risus eu purus odio egestas
                cursus. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Fusce risus eu purus odio egestas cursus. Adipiscing ornare mi
              </p>
              <p className="text-[#615F5F] text-[16px] mt-4 font-[600] md:text-[22px] md:font-[600] md:mt-[35px] ">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Ultrices imperdiet amedries elitiser Adipiscing ornare mi
                quisque nunc sit .
              </p>
              <img src={image2} alt="#" className="md:mt-[50px] mt-4" />

              <p className="text-[#615F5F] text-[16px] font-[600] mt-4 md:text-[22px] md:font-[600] md:mt-[65px]">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Ultrices imperdiet amedries elitiser Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. Fusce risus eu purus odio egestas
                cursus. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Fusce risus eu purus odio egestas cursus. Adipiscing ornare mi
              </p>
            </div>
          </>
        )}
        {pricing && (
          <>
            <div className=" md:mt-[110px] ">
              <div className="md:flex justify-between hidden ">
                <div>
                  <h2 className="text-[#124870] text-[32px] font-[800]">
                    Simple pricing for your business
                  </h2>
                  <h3 className="text-[#747474B8] text-[24px] font-[800] mt-[12px]">
                    Following lorem ispum amet amdedsing elit
                  </h3>
                </div>
                <div>
                  <span className="text-[#747474D6] text-[28px] font-[500]">
                    Monthly
                  </span>
                  <label
                    for="default-toggle"
                    className="inline-flex relative items-center cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      value=""
                      id="default-toggle"
                      className="sr-only peer"
                    />
                    <div className="bg-[#124870] w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-[#124870]-300 dark:peer-focus:ring-[#124870]-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-[#FFFFFF] after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-[#FFFFFF]-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-[#FFFFFF]-600 peer-checked:bg-blue-600"></div>
                  </label>
                  <span className="text-[#124870] text-[28px] font-[600]">
                    Yearly
                  </span>
                </div>
              </div>
              <div className="md:hidden flex flex-col justify-center items-center mt-[27px]">
                <h2 className="text-[20px] font-[700] text-[#074381]">Plans Lorem </h2>
                <h3 className="text-[16px] font-[700] text-[#747474D1]">Lörem ipsum multinar mivevis och. </h3>
              </div>
              <div className="md:flex md:justify-between md:gap-[20px]">
                <div className="md:w-[400px] md:h-[656px] p-8 md:p-0 bg-[#FFFFFF] border-[#7474743D] border-[1px] md:rounded-[16px] rounded-lg md:mt-[44px] mt-5">
                  <h2 className="md:text-[36px] text-[32px] font-[700] text-[#181059] md:mt-[37px] md:ml-[36px]">
                    Plan1
                  </h2>
                  <div>
                    <div className="flex md:ml-[40px] md:mt-[43px] mt-8">
                      <svg
                        width="15"
                        height="13"
                        viewBox="0 0 15 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.08721 12.9048C5.63206 12.9048 5.20097 12.7312 4.92474 12.4319L1.18621 8.39538C1.07063 8.27072 0.986244 8.12866 0.937884 7.97734C0.889525 7.82601 0.878138 7.66838 0.904375 7.51345C0.930612 7.35853 0.993959 7.20935 1.09079 7.07444C1.18763 6.93953 1.31605 6.82155 1.46872 6.72722C1.62134 6.63251 1.79533 6.56333 1.98072 6.52367C2.16611 6.484 2.35926 6.47462 2.54909 6.49607C2.73893 6.51751 2.92172 6.56936 3.08699 6.64864C3.25226 6.72793 3.39675 6.83308 3.5122 6.95808L5.97211 9.61208L12.157 1.49618C12.3621 1.22822 12.6889 1.03767 13.0657 0.966343C13.4425 0.895014 13.8385 0.948726 14.167 1.1157C14.8502 1.46284 15.0605 2.19816 14.6336 2.75734L7.32606 12.3422C7.20132 12.5065 7.02937 12.6435 6.82516 12.7413C6.62094 12.839 6.39061 12.8945 6.15417 12.9031C6.13116 12.9048 6.11023 12.9048 6.08721 12.9048Z"
                          fill="#181059"
                        />
                      </svg>
                      <h2 className="text-[#181059] md:text-[24px] font-[400] text-[20px] -mt-[10px] ml-[20px]">
                        Uorem ipsum dolorconsectetur adipis
                      </h2>
                    </div>
                    <div className="flex md:ml-[40px] md:mt-[15px] mt-4">
                      <svg
                        width="15"
                        height="13"
                        viewBox="0 0 15 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.08721 12.9048C5.63206 12.9048 5.20097 12.7312 4.92474 12.4319L1.18621 8.39538C1.07063 8.27072 0.986244 8.12866 0.937884 7.97734C0.889525 7.82601 0.878138 7.66838 0.904375 7.51345C0.930612 7.35853 0.993959 7.20935 1.09079 7.07444C1.18763 6.93953 1.31605 6.82155 1.46872 6.72722C1.62134 6.63251 1.79533 6.56333 1.98072 6.52367C2.16611 6.484 2.35926 6.47462 2.54909 6.49607C2.73893 6.51751 2.92172 6.56936 3.08699 6.64864C3.25226 6.72793 3.39675 6.83308 3.5122 6.95808L5.97211 9.61208L12.157 1.49618C12.3621 1.22822 12.6889 1.03767 13.0657 0.966343C13.4425 0.895014 13.8385 0.948726 14.167 1.1157C14.8502 1.46284 15.0605 2.19816 14.6336 2.75734L7.32606 12.3422C7.20132 12.5065 7.02937 12.6435 6.82516 12.7413C6.62094 12.839 6.39061 12.8945 6.15417 12.9031C6.13116 12.9048 6.11023 12.9048 6.08721 12.9048Z"
                          fill="#181059"
                        />
                      </svg>
                      <h2 className="text-[#181059] md:text-[24px] font-[400] text-[20px] -mt-[10px] ml-[20px]">
                        Attachment & Post Scheduling{" "}
                      </h2>
                    </div>
                    <div className="flex md:ml-[40px] md:mt-[15px] mt-4">
                      <svg
                        width="15"
                        height="13"
                        viewBox="0 0 15 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.08721 12.9048C5.63206 12.9048 5.20097 12.7312 4.92474 12.4319L1.18621 8.39538C1.07063 8.27072 0.986244 8.12866 0.937884 7.97734C0.889525 7.82601 0.878138 7.66838 0.904375 7.51345C0.930612 7.35853 0.993959 7.20935 1.09079 7.07444C1.18763 6.93953 1.31605 6.82155 1.46872 6.72722C1.62134 6.63251 1.79533 6.56333 1.98072 6.52367C2.16611 6.484 2.35926 6.47462 2.54909 6.49607C2.73893 6.51751 2.92172 6.56936 3.08699 6.64864C3.25226 6.72793 3.39675 6.83308 3.5122 6.95808L5.97211 9.61208L12.157 1.49618C12.3621 1.22822 12.6889 1.03767 13.0657 0.966343C13.4425 0.895014 13.8385 0.948726 14.167 1.1157C14.8502 1.46284 15.0605 2.19816 14.6336 2.75734L7.32606 12.3422C7.20132 12.5065 7.02937 12.6435 6.82516 12.7413C6.62094 12.839 6.39061 12.8945 6.15417 12.9031C6.13116 12.9048 6.11023 12.9048 6.08721 12.9048Z"
                          fill="#181059"
                        />
                      </svg>
                      <h2 className="text-[#181059] md:text-[24px] font-[400] text-[20px] -mt-[10px] ml-[20px]">
                        Set your rates{" "}
                      </h2>
                    </div>
                    <div className="flex md:ml-[40px] md:mt-[15px] mt-4">
                      <svg
                        width="15"
                        height="13"
                        viewBox="0 0 15 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.08721 12.9048C5.63206 12.9048 5.20097 12.7312 4.92474 12.4319L1.18621 8.39538C1.07063 8.27072 0.986244 8.12866 0.937884 7.97734C0.889525 7.82601 0.878138 7.66838 0.904375 7.51345C0.930612 7.35853 0.993959 7.20935 1.09079 7.07444C1.18763 6.93953 1.31605 6.82155 1.46872 6.72722C1.62134 6.63251 1.79533 6.56333 1.98072 6.52367C2.16611 6.484 2.35926 6.47462 2.54909 6.49607C2.73893 6.51751 2.92172 6.56936 3.08699 6.64864C3.25226 6.72793 3.39675 6.83308 3.5122 6.95808L5.97211 9.61208L12.157 1.49618C12.3621 1.22822 12.6889 1.03767 13.0657 0.966343C13.4425 0.895014 13.8385 0.948726 14.167 1.1157C14.8502 1.46284 15.0605 2.19816 14.6336 2.75734L7.32606 12.3422C7.20132 12.5065 7.02937 12.6435 6.82516 12.7413C6.62094 12.839 6.39061 12.8945 6.15417 12.9031C6.13116 12.9048 6.11023 12.9048 6.08721 12.9048Z"
                          fill="#181059"
                        />
                      </svg>
                      <h2 className="text-[#181059] md:text-[24px] font-[400] text-[20px] -mt-[10px] ml-[20px]">
                        Exclusive Deals{" "}
                      </h2>
                    </div>
                    <div className="flex md:ml-[40px] md:mt-[15px] mt-4">
                      <svg
                        width="15"
                        height="13"
                        viewBox="0 0 15 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.08721 12.9048C5.63206 12.9048 5.20097 12.7312 4.92474 12.4319L1.18621 8.39538C1.07063 8.27072 0.986244 8.12866 0.937884 7.97734C0.889525 7.82601 0.878138 7.66838 0.904375 7.51345C0.930612 7.35853 0.993959 7.20935 1.09079 7.07444C1.18763 6.93953 1.31605 6.82155 1.46872 6.72722C1.62134 6.63251 1.79533 6.56333 1.98072 6.52367C2.16611 6.484 2.35926 6.47462 2.54909 6.49607C2.73893 6.51751 2.92172 6.56936 3.08699 6.64864C3.25226 6.72793 3.39675 6.83308 3.5122 6.95808L5.97211 9.61208L12.157 1.49618C12.3621 1.22822 12.6889 1.03767 13.0657 0.966343C13.4425 0.895014 13.8385 0.948726 14.167 1.1157C14.8502 1.46284 15.0605 2.19816 14.6336 2.75734L7.32606 12.3422C7.20132 12.5065 7.02937 12.6435 6.82516 12.7413C6.62094 12.839 6.39061 12.8945 6.15417 12.9031C6.13116 12.9048 6.11023 12.9048 6.08721 12.9048Z"
                          fill="#181059"
                        />
                      </svg>
                      <h2 className="text-[#181059] md:text-[24px] font-[400] text-[20px] -mt-[10px] ml-[20px]">
                        Advanced Statistics{" "}
                      </h2>
                    </div>
                  </div>
                  <div className="md:ml-[33px] mt-[32px] ">
                    <span className="text-[#181059] text-[25px] font-[400]">
                      $
                    </span>
                    <span className="text-[#181059] md:text-[38px] text-[32px] font-[700]">
                      123
                    </span>
                    <span className="text-[#181059] md:text-[24px] text-[20px] font-[400]">
                      / month
                    </span>
                  </div>
                  <button className="w-full md:w-fit md:h-fit h-[40px] rounded-md  md:pt-[21px] md:pb-[21px] border-[#DA6C08] border-[1px] text-[#DA6C08] md:text-[24px] font-[700] md:pl-[118px] md:pr-[118px] md:rounded-[12px] md:ml-[30px] mt-[30px] md:mt-[38px]">
                    Choose
                  </button>
                </div>
                <div className="md:w-[400px] md:h-[656px] p-8 md:p-0 bg-[#124870] border-[#7474743D] border-[1px] md:rounded-[16px] rounded-lg md:mt-[44px] mt-5">
                  <h2 className="md:text-[36px] text-[32px] font-[700] text-[#FFFFFF] md:mt-[37px] md:ml-[36px]">
                    Plan1
                  </h2>
                  <div>
                    <div className="flex md:ml-[40px] md:mt-[43px] mt-8">
                      <svg
                        width="15"
                        height="13"
                        viewBox="0 0 15 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.08721 12.9048C5.63206 12.9048 5.20097 12.7312 4.92474 12.4319L1.18621 8.39538C1.07063 8.27072 0.986244 8.12866 0.937884 7.97734C0.889525 7.82601 0.878138 7.66838 0.904375 7.51345C0.930612 7.35853 0.993959 7.20935 1.09079 7.07444C1.18763 6.93953 1.31605 6.82155 1.46872 6.72722C1.62134 6.63251 1.79533 6.56333 1.98072 6.52367C2.16611 6.484 2.35926 6.47462 2.54909 6.49607C2.73893 6.51751 2.92172 6.56936 3.08699 6.64864C3.25226 6.72793 3.39675 6.83308 3.5122 6.95808L5.97211 9.61208L12.157 1.49618C12.3621 1.22822 12.6889 1.03767 13.0657 0.966343C13.4425 0.895014 13.8385 0.948726 14.167 1.1157C14.8502 1.46284 15.0605 2.19816 14.6336 2.75734L7.32606 12.3422C7.20132 12.5065 7.02937 12.6435 6.82516 12.7413C6.62094 12.839 6.39061 12.8945 6.15417 12.9031C6.13116 12.9048 6.11023 12.9048 6.08721 12.9048Z"
                          fill="#FFFFFF"
                        />
                      </svg>
                      <h2 className="text-[#FFFFFF] md:text-[24px] text-[20px] font-[400] -mt-[10px] ml-[20px]">
                        Uorem ipsum dolorconsectetur adipis
                      </h2>
                    </div>
                    <div className="flex md:ml-[40px] md:mt-[15px] mt-4">
                      <svg
                        width="15"
                        height="13"
                        viewBox="0 0 15 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.08721 12.9048C5.63206 12.9048 5.20097 12.7312 4.92474 12.4319L1.18621 8.39538C1.07063 8.27072 0.986244 8.12866 0.937884 7.97734C0.889525 7.82601 0.878138 7.66838 0.904375 7.51345C0.930612 7.35853 0.993959 7.20935 1.09079 7.07444C1.18763 6.93953 1.31605 6.82155 1.46872 6.72722C1.62134 6.63251 1.79533 6.56333 1.98072 6.52367C2.16611 6.484 2.35926 6.47462 2.54909 6.49607C2.73893 6.51751 2.92172 6.56936 3.08699 6.64864C3.25226 6.72793 3.39675 6.83308 3.5122 6.95808L5.97211 9.61208L12.157 1.49618C12.3621 1.22822 12.6889 1.03767 13.0657 0.966343C13.4425 0.895014 13.8385 0.948726 14.167 1.1157C14.8502 1.46284 15.0605 2.19816 14.6336 2.75734L7.32606 12.3422C7.20132 12.5065 7.02937 12.6435 6.82516 12.7413C6.62094 12.839 6.39061 12.8945 6.15417 12.9031C6.13116 12.9048 6.11023 12.9048 6.08721 12.9048Z"
                          fill="#FFFFFF"
                        />
                      </svg>
                      <h2 className="text-[#FFFFFF] md:text-[24px] text-[20px] font-[400] -mt-[10px] ml-[20px]">
                        Attachment & Post Scheduling{" "}
                      </h2>
                    </div>
                    <div className="flex md:ml-[40px] md:mt-[15px] mt-4">
                      <svg
                        width="15"
                        height="13"
                        viewBox="0 0 15 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.08721 12.9048C5.63206 12.9048 5.20097 12.7312 4.92474 12.4319L1.18621 8.39538C1.07063 8.27072 0.986244 8.12866 0.937884 7.97734C0.889525 7.82601 0.878138 7.66838 0.904375 7.51345C0.930612 7.35853 0.993959 7.20935 1.09079 7.07444C1.18763 6.93953 1.31605 6.82155 1.46872 6.72722C1.62134 6.63251 1.79533 6.56333 1.98072 6.52367C2.16611 6.484 2.35926 6.47462 2.54909 6.49607C2.73893 6.51751 2.92172 6.56936 3.08699 6.64864C3.25226 6.72793 3.39675 6.83308 3.5122 6.95808L5.97211 9.61208L12.157 1.49618C12.3621 1.22822 12.6889 1.03767 13.0657 0.966343C13.4425 0.895014 13.8385 0.948726 14.167 1.1157C14.8502 1.46284 15.0605 2.19816 14.6336 2.75734L7.32606 12.3422C7.20132 12.5065 7.02937 12.6435 6.82516 12.7413C6.62094 12.839 6.39061 12.8945 6.15417 12.9031C6.13116 12.9048 6.11023 12.9048 6.08721 12.9048Z"
                          fill="#FFFFFF"
                        />
                      </svg>
                      <h2 className="text-[#FFFFFF] md:text-[24px] text-[20px] font-[400] -mt-[10px] ml-[20px]">
                        Set your rates
                      </h2>
                    </div>
                    <div className="flex md:ml-[40px] md:mt-[15px] mt-4">
                      <svg
                        width="15"
                        height="13"
                        viewBox="0 0 15 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.08721 12.9048C5.63206 12.9048 5.20097 12.7312 4.92474 12.4319L1.18621 8.39538C1.07063 8.27072 0.986244 8.12866 0.937884 7.97734C0.889525 7.82601 0.878138 7.66838 0.904375 7.51345C0.930612 7.35853 0.993959 7.20935 1.09079 7.07444C1.18763 6.93953 1.31605 6.82155 1.46872 6.72722C1.62134 6.63251 1.79533 6.56333 1.98072 6.52367C2.16611 6.484 2.35926 6.47462 2.54909 6.49607C2.73893 6.51751 2.92172 6.56936 3.08699 6.64864C3.25226 6.72793 3.39675 6.83308 3.5122 6.95808L5.97211 9.61208L12.157 1.49618C12.3621 1.22822 12.6889 1.03767 13.0657 0.966343C13.4425 0.895014 13.8385 0.948726 14.167 1.1157C14.8502 1.46284 15.0605 2.19816 14.6336 2.75734L7.32606 12.3422C7.20132 12.5065 7.02937 12.6435 6.82516 12.7413C6.62094 12.839 6.39061 12.8945 6.15417 12.9031C6.13116 12.9048 6.11023 12.9048 6.08721 12.9048Z"
                          fill="#FFFFFF"
                        />
                      </svg>
                      <h2 className="text-[#FFFFFF] md:text-[24px] text-[20px] font-[400] -mt-[10px] ml-[20px]">
                        Exclusive Deals{" "}
                      </h2>
                    </div>
                    <div className="flex md:ml-[40px] md:mt-[15px] mt-4">
                      <svg
                        width="15"
                        height="13"
                        viewBox="0 0 15 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.08721 12.9048C5.63206 12.9048 5.20097 12.7312 4.92474 12.4319L1.18621 8.39538C1.07063 8.27072 0.986244 8.12866 0.937884 7.97734C0.889525 7.82601 0.878138 7.66838 0.904375 7.51345C0.930612 7.35853 0.993959 7.20935 1.09079 7.07444C1.18763 6.93953 1.31605 6.82155 1.46872 6.72722C1.62134 6.63251 1.79533 6.56333 1.98072 6.52367C2.16611 6.484 2.35926 6.47462 2.54909 6.49607C2.73893 6.51751 2.92172 6.56936 3.08699 6.64864C3.25226 6.72793 3.39675 6.83308 3.5122 6.95808L5.97211 9.61208L12.157 1.49618C12.3621 1.22822 12.6889 1.03767 13.0657 0.966343C13.4425 0.895014 13.8385 0.948726 14.167 1.1157C14.8502 1.46284 15.0605 2.19816 14.6336 2.75734L7.32606 12.3422C7.20132 12.5065 7.02937 12.6435 6.82516 12.7413C6.62094 12.839 6.39061 12.8945 6.15417 12.9031C6.13116 12.9048 6.11023 12.9048 6.08721 12.9048Z"
                          fill="#FFFFFF"
                        />
                      </svg>
                      <h2 className="text-[#FFFFFF] md:text-[24px] text-[20px] font-[400] -mt-[10px] ml-[20px]">
                        Advanced Statistics{" "}
                      </h2>
                    </div>
                  </div>
                  <div className="md:ml-[33px] md:mt-[32px] mt-8" >
                    <span className="text-[#FFFFFF] md:text-[25px] text-[20px] font-[400]">
                      $
                    </span>
                    <span className="text-[#FFFFFF] md:text-[38px] text-[32px] font-[700]">
                      123
                    </span>
                    <span className="text-[#FFFFFF] md:text-[24px] text-[20px] font-[400]">
                      / month
                    </span>
                  </div>
                  <button className="w-full md:w-fit md:h-fit h-[40px] rounded-md  md:pt-[21px] md:pb-[21px] border-[#DA6C08] border-[1px] text-[#DA6C08] md:text-[24px] font-[700] md:pl-[118px] md:pr-[118px] md:rounded-[12px] md:ml-[30px] mt-[30px] md:mt-[38px]">
                    Choose
                  </button>
                </div>
                <div className="md:w-[400px] md:h-[656px] p-8 md:p-0 bg-[#FFFFFF] border-[#7474743D] border-[1px] md:rounded-[16px] rounded-lg md:mt-[44px] mt-5">
                <h2 className="md:text-[36px] text-[32px] font-[700] text-[#181059] md:mt-[37px] md:ml-[36px]">
                    Plan1
                  </h2>
                  <div>
                  <div className="flex md:ml-[40px] md:mt-[43px] mt-8">
                      <svg
                        width="15"
                        height="13"
                        viewBox="0 0 15 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.08721 12.9048C5.63206 12.9048 5.20097 12.7312 4.92474 12.4319L1.18621 8.39538C1.07063 8.27072 0.986244 8.12866 0.937884 7.97734C0.889525 7.82601 0.878138 7.66838 0.904375 7.51345C0.930612 7.35853 0.993959 7.20935 1.09079 7.07444C1.18763 6.93953 1.31605 6.82155 1.46872 6.72722C1.62134 6.63251 1.79533 6.56333 1.98072 6.52367C2.16611 6.484 2.35926 6.47462 2.54909 6.49607C2.73893 6.51751 2.92172 6.56936 3.08699 6.64864C3.25226 6.72793 3.39675 6.83308 3.5122 6.95808L5.97211 9.61208L12.157 1.49618C12.3621 1.22822 12.6889 1.03767 13.0657 0.966343C13.4425 0.895014 13.8385 0.948726 14.167 1.1157C14.8502 1.46284 15.0605 2.19816 14.6336 2.75734L7.32606 12.3422C7.20132 12.5065 7.02937 12.6435 6.82516 12.7413C6.62094 12.839 6.39061 12.8945 6.15417 12.9031C6.13116 12.9048 6.11023 12.9048 6.08721 12.9048Z"
                          fill="#181059"
                        />
                      </svg>
                      <h2 className="text-[#181059] md:text-[24px] font-[400] text-[20px] -mt-[10px] ml-[20px]">
                        Uorem ipsum dolorconsectetur adipis
                      </h2>
                    </div>
                    <div className="flex md:ml-[40px] md:mt-[15px] mt-4">
                      <svg
                        width="15"
                        height="13"
                        viewBox="0 0 15 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.08721 12.9048C5.63206 12.9048 5.20097 12.7312 4.92474 12.4319L1.18621 8.39538C1.07063 8.27072 0.986244 8.12866 0.937884 7.97734C0.889525 7.82601 0.878138 7.66838 0.904375 7.51345C0.930612 7.35853 0.993959 7.20935 1.09079 7.07444C1.18763 6.93953 1.31605 6.82155 1.46872 6.72722C1.62134 6.63251 1.79533 6.56333 1.98072 6.52367C2.16611 6.484 2.35926 6.47462 2.54909 6.49607C2.73893 6.51751 2.92172 6.56936 3.08699 6.64864C3.25226 6.72793 3.39675 6.83308 3.5122 6.95808L5.97211 9.61208L12.157 1.49618C12.3621 1.22822 12.6889 1.03767 13.0657 0.966343C13.4425 0.895014 13.8385 0.948726 14.167 1.1157C14.8502 1.46284 15.0605 2.19816 14.6336 2.75734L7.32606 12.3422C7.20132 12.5065 7.02937 12.6435 6.82516 12.7413C6.62094 12.839 6.39061 12.8945 6.15417 12.9031C6.13116 12.9048 6.11023 12.9048 6.08721 12.9048Z"
                          fill="#181059"
                        />
                      </svg>
                      <h2 className="text-[#181059] md:text-[24px] font-[400] text-[20px] -mt-[10px] ml-[20px]">
                        Attachment & Post Scheduling{" "}
                      </h2>
                    </div>
                    <div className="flex md:ml-[40px] md:mt-[15px] mt-4">
                      <svg
                        width="15"
                        height="13"
                        viewBox="0 0 15 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.08721 12.9048C5.63206 12.9048 5.20097 12.7312 4.92474 12.4319L1.18621 8.39538C1.07063 8.27072 0.986244 8.12866 0.937884 7.97734C0.889525 7.82601 0.878138 7.66838 0.904375 7.51345C0.930612 7.35853 0.993959 7.20935 1.09079 7.07444C1.18763 6.93953 1.31605 6.82155 1.46872 6.72722C1.62134 6.63251 1.79533 6.56333 1.98072 6.52367C2.16611 6.484 2.35926 6.47462 2.54909 6.49607C2.73893 6.51751 2.92172 6.56936 3.08699 6.64864C3.25226 6.72793 3.39675 6.83308 3.5122 6.95808L5.97211 9.61208L12.157 1.49618C12.3621 1.22822 12.6889 1.03767 13.0657 0.966343C13.4425 0.895014 13.8385 0.948726 14.167 1.1157C14.8502 1.46284 15.0605 2.19816 14.6336 2.75734L7.32606 12.3422C7.20132 12.5065 7.02937 12.6435 6.82516 12.7413C6.62094 12.839 6.39061 12.8945 6.15417 12.9031C6.13116 12.9048 6.11023 12.9048 6.08721 12.9048Z"
                          fill="#181059"
                        />
                      </svg>
                      <h2 className="text-[#181059] md:text-[24px] font-[400] text-[20px] -mt-[10px] ml-[20px]">
                        Set your rates{" "}
                      </h2>
                    </div>
                    <div className="flex md:ml-[40px] md:mt-[15px] mt-4">
                      <svg
                        width="15"
                        height="13"
                        viewBox="0 0 15 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.08721 12.9048C5.63206 12.9048 5.20097 12.7312 4.92474 12.4319L1.18621 8.39538C1.07063 8.27072 0.986244 8.12866 0.937884 7.97734C0.889525 7.82601 0.878138 7.66838 0.904375 7.51345C0.930612 7.35853 0.993959 7.20935 1.09079 7.07444C1.18763 6.93953 1.31605 6.82155 1.46872 6.72722C1.62134 6.63251 1.79533 6.56333 1.98072 6.52367C2.16611 6.484 2.35926 6.47462 2.54909 6.49607C2.73893 6.51751 2.92172 6.56936 3.08699 6.64864C3.25226 6.72793 3.39675 6.83308 3.5122 6.95808L5.97211 9.61208L12.157 1.49618C12.3621 1.22822 12.6889 1.03767 13.0657 0.966343C13.4425 0.895014 13.8385 0.948726 14.167 1.1157C14.8502 1.46284 15.0605 2.19816 14.6336 2.75734L7.32606 12.3422C7.20132 12.5065 7.02937 12.6435 6.82516 12.7413C6.62094 12.839 6.39061 12.8945 6.15417 12.9031C6.13116 12.9048 6.11023 12.9048 6.08721 12.9048Z"
                          fill="#181059"
                        />
                      </svg>
                      <h2 className="text-[#181059] md:text-[24px] font-[400] text-[20px] -mt-[10px] ml-[20px]">
                        Exclusive Deals{" "}
                      </h2>
                    </div>
                    <div className="flex md:ml-[40px] md:mt-[15px] mt-4">
                      <svg
                        width="15"
                        height="13"
                        viewBox="0 0 15 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.08721 12.9048C5.63206 12.9048 5.20097 12.7312 4.92474 12.4319L1.18621 8.39538C1.07063 8.27072 0.986244 8.12866 0.937884 7.97734C0.889525 7.82601 0.878138 7.66838 0.904375 7.51345C0.930612 7.35853 0.993959 7.20935 1.09079 7.07444C1.18763 6.93953 1.31605 6.82155 1.46872 6.72722C1.62134 6.63251 1.79533 6.56333 1.98072 6.52367C2.16611 6.484 2.35926 6.47462 2.54909 6.49607C2.73893 6.51751 2.92172 6.56936 3.08699 6.64864C3.25226 6.72793 3.39675 6.83308 3.5122 6.95808L5.97211 9.61208L12.157 1.49618C12.3621 1.22822 12.6889 1.03767 13.0657 0.966343C13.4425 0.895014 13.8385 0.948726 14.167 1.1157C14.8502 1.46284 15.0605 2.19816 14.6336 2.75734L7.32606 12.3422C7.20132 12.5065 7.02937 12.6435 6.82516 12.7413C6.62094 12.839 6.39061 12.8945 6.15417 12.9031C6.13116 12.9048 6.11023 12.9048 6.08721 12.9048Z"
                          fill="#181059"
                        />
                      </svg>
                      <h2 className="text-[#181059] md:text-[24px] font-[400] text-[20px] -mt-[10px] ml-[20px]">
                        Advanced Statistics{" "}
                      </h2>
                    </div>
                  </div>
                  <div className="md:ml-[33px] mt-[32px] ">
                    <span className="text-[#181059] md:text-[25px] text-[20px] font-[400]">
                      $
                    </span>
                    <span className="text-[#181059] md:text-[38px] text-[32px] font-[700]">
                      123
                    </span>
                    <span className="text-[#181059] md:text-[24px] text-[20px] font-[400]">
                      / month
                    </span>
                  </div>
                  <button className="w-full md:w-fit md:h-fit h-[40px] rounded-md  md:pt-[21px] md:pb-[21px] border-[#DA6C08] border-[1px] text-[#DA6C08] md:text-[24px] font-[700] md:pl-[118px] md:pr-[118px] md:rounded-[12px] md:ml-[30px] mt-[30px] md:mt-[38px]">
                    Choose
                  </button>
                </div>
              </div>
              <div className="md:mt-[150px] flex flex-col md:justify-start justify-center items-center md:items-start mt-[44px]">
                <h2 className="text-[#124870] md:text-[32px] text-[20px] font-[700] md:font-[800]">
                  Valur Added Services
                </h2>
                <h3 className="text-[#747474B8] md:text-[24px] text-[16px] font-[700] md:font-[800]">
                  Following lorem ispum amet amdedsing elit
                </h3>
              </div>

              <div>
              <div className="flex overflow-x-scroll scrollbar-hide w-full  md:block  ">
              <div class="flex flex-nowrap w-full">

                <table className=" border-[#7474743D] border-[1px] mt-[45px]">
                  <thead className="border-[#7474743D] border-[1px] px-[41px] py-[15px]">
                    <tr className="bg-[#124870] text-[#FFFFFF] text-[20px] font-[600]">
                      <th className="border-[#7474743D] border-[1px] px-[41px] py-[15px]">
                        Additional Users
                      </th>
                      <th className="border-[#7474743D] border-[1px]">
                        Additional Accounts
                      </th>
                      <th className="border-[#7474743D] border-[1px]">
                        Additional Labels
                      </th>
                      <th className="border-[#7474743D] border-[1px]">
                        Messages
                      </th>
                      <th className="border-[#7474743D] border-[1px]">
                        Messagess
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="bg-[#FFFFFF] ">
                      <td className="border-[#7474743D] border-[1px]">
                        <div className="ml-[24px] mt-[24px]">
                          <span className="text-[#181059] text-[25px] font-[400]">
                            $
                          </span>
                          <span className="text-[#181059] text-[38px] font-[700]">
                            123
                          </span>
                          <p className="text-[#747474D6] text-[24px] w-[217px] font-[400] mt-[5px] mb-[34px]">
                            for each additionl user per user / organisation.
                          </p>
                        </div>
                      </td>
                      <td className="border-[#7474743D] border-[1px]">
                        <div className="ml-[24px] mt-[24px]">
                          <span className="text-[#181059] text-[25px] font-[400]">
                            $
                          </span>
                          <span className="text-[#181059] text-[38px] font-[700]">
                            123
                          </span>
                          <p className="text-[#747474D6] text-[24px] w-[217px] font-[400] mt-[5px] mb-[34px]">
                            for each additionl user per user / organisation.
                          </p>
                        </div>
                      </td>
                      <td className="border-[#7474743D] border-[1px]">
                        <div className="ml-[24px] mt-[24px]">
                          <span className="text-[#181059] text-[25px] font-[400]">
                            $
                          </span>
                          <span className="text-[#181059] text-[38px] font-[700]">
                            123
                          </span>
                          <p className="text-[#747474D6] text-[24px] font-[400] w-[217px] mt-[5px] mb-[34px]">
                            for each additionl user per user / organisation.
                          </p>
                        </div>
                      </td>
                      <td className="border-[#7474743D] border-[1px]">
                        <div className="ml-[24px] mt-[24px]">
                          <span className="text-[#181059] text-[25px] font-[400]">
                            $
                          </span>
                          <span className="text-[#181059] text-[38px] font-[700]">
                            123
                          </span>
                          <p className="text-[#747474D6] text-[24px] font-[400] w-[217px] mt-[5px] mb-[34px]">
                            for each additionl user per user / organisation.
                          </p>
                        </div>
                      </td>
                      <td className="border-[#7474743D] border-[1px]">
                        <div className="ml-[24px] mt-[24px]">
                          <span className="text-[#181059] text-[25px] font-[400]">
                            $
                          </span>
                          <span className="text-[#181059] text-[38px] font-[700]">
                            123
                          </span>
                          <p className="text-[#747474D6] text-[24px] font-[400] w-[217px] mt-[5px] mb-[34px]">
                            for each additionl user per user / organisation.
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                </div>
                </div>
              </div>
            </div>
          </>
        )}
        {support && (
          <>
            <div>
              <h2 className="text-[#124870] md:text-[34px] text-[20px] font-[700] mt-[105px]">
                Support Information
              </h2>
              <h3 className="text-[#747474B8] md:text-[22px] text-[16px] font-[500] mt-[15px]">
                Following lorem ispum amet amdedsing elit
              </h3>
            </div>
          </>
        )}
      </div>
      <Footer />
    </div>
  );
}
