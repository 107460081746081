import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Popup from "./Popup";
import { useEffect } from "react";

export default function Sliders({ toggleModal, setData }) {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div>
      <Carousel
        swipeable={true}
        draggable={true}
        showDots={true}
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={1500}
        keyBoardControl={true}
        transitionDuration={500}
        centerMode={true}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["superLargeDesktop", "desktop","tablet", "mobile"]}
        itemClass="carousel-item-padding-40-px"
      >
        <div className="w-[120px] lg:w-[350px] lg:mb-[50px] mb-[35px]">
          <div className="w-[120px] lg:w-[250px]">
            <img src="ourrelations1.png" />
          </div>
        </div>
        {/* <div className="w-[251px] h-[200px] lg:w-[570px] p-[16px] lg:p-0 lg:mr-10 mr-2 lg:h-[225px] bg-[#FFFFFF] ">
          <p className="text-[#747474] hidden lg:block lg:text-[20px] lg:font-[400] lg:pl-[35px] lg:pt-[52px] lg:pr-[29px]">
            “Pankh brings about with conviction on how B2B businesses especially
            the MSMEs...
            <Popup
              toggleModal={toggleModal}
              setData={setData}
              data={{
                Name: "Supun Dissanayake",
                ImgSrc: "Recommendation1.png",
                Post: "Founder at LessCode Plus",
                Text: "Pankh brings about with conviction on how B2B businesses especially the MSMEs operate with high effectiveness & could take a lead through ease of digitization in enabling efficiency through an accessible way to automate & run their day-to-day processes digitally. With an overload of tech apps, for the first time ever, an app helps businesses through an all-in-one solution on business that is dealt with taking care of each aspect including each function, the team & its collaboration, task management, and interactions with its stakeholders with better mechanisms for sales & payments and so much more. I really enjoy how even B2B markets can now own an e-commerce website whose accessibility is so well catered & designed with its private & public view! All in all, a solution that takes care of all facets of each aspect of the business delivered in a way that businesses find is relatively easier to access & accomplish technology for the betterment.",
              }}
            />
          </p>

          <p className="md:hidden text-[14px] text-[#747474]">
            “Pankh brings about with conviction on how B2B businesses especially
            the MSMEs...
            <Popup
              toggleModal={toggleModal}
              setData={setData}
              data={{
                Name: "Supun Dissanayake",
                ImgSrc: "Recommendation1.png",
                Post: "Founder at LessCode Plus",
                Text: "Pankh brings about with conviction on how B2B businesses especially the MSMEs operate with high effectiveness & could take a lead through ease of digitization in enabling efficiency through an accessible way to automate & run their day-to-day processes digitally. With an overload of tech apps, for the first time ever, an app helps businesses through an all-in-one solution on business that is dealt with taking care of each aspect including each function, the team & its collaboration, task management, and interactions with its stakeholders with better mechanisms for sales & payments and so much more.",
              }}
            />
          </p>
          <div className="lg:ml-[35px] lg:mt-[20px] lg:pr-[29px] flex mt-[17px]">
            <div className="bg-[#DE8350] lg:w-[47px] lg:h-[43px] w-[30px] h-[30px] rounded-[50%]">
              <img src="Recommendation1.png" alt="" />
            </div>
            <div className="lg:ml-[16px]">
              <h2 className="text-[#074381] lg:text-[16px] text-[14px] font-[600] mt-[-2px] ml-[8px] lg:mt-[0] lg:ml-[5px]">
                Supun Dissanayake
              </h2>
              <h2 className="lg:text-[12px] text-[#3A4047D1] text-[12px] font-[0] lg:mt-[-3px] ml-[8px] mt-[-1px] lg:ml-[4px]">
                Founder at LessCode Plus
              </h2>
            </div>
          </div>
        </div> */}
        <div className="w-[120px] lg:w-[350px]">
          <div className="w-[120px] lg:w-[250px]">
            <img src="ourrelations2.png" />
          </div>
        </div>
        <div className="w-[120px] lg:w-[350px]">
          <div className="w-[120px] lg:w-[250px]">
            <img src="ourrelations3.png" />
          </div>
        </div>
        <div className="w-[120px] lg:w-[350px]">
          <div className="w-[120px] lg:w-[250px]">
            <img src="ourrelations4.png" />
          </div>
        </div>
        <div className="w-[120px] lg:w-[350px]">
          <div className="w-[120px] lg:w-[250px]">
            <img src="ourrelations5.png" />
          </div>
        </div>
      </Carousel>
    </div>
  );
}

// import React, { useState } from 'react';
// import Slider from 'react-slick';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// // import './App.css';
// // import { dataDigitalBestSeller } from './data';
// // import imgGirl from './assets/images/defaultImage.jpg';

// const Sliders = () => {
//   const [defaultImage, setDefaultImage] = useState({});
//   const settings = {
//     dots: true,
//     infinite: false,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 3,
//     initialSlide: 0,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 2,
//           infinite: true,
//           dots: true,
//         },
//       },
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 2,
//           initialSlide: 2,
//         },
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//     ],
//   };

//   const handleErrorImage = (data) => {
//     setDefaultImage((prev) => ({
//       ...prev,
//       [data.target.alt]: data.target.alt,
//     //   linkDefault: imgGirl,
//     }));
//   };

//   return (
//     <div className="App">
//       <Slider {...settings}>
//         {/* {dataDigitalBestSeller.map((item) => ( */}
//           <div className="card">
//             <div className="card-top">
//               {/* <img
//                 src={
//                   defaultImage[item.title] === item.title
//                     ? defaultImage.linkDefault
//                     : item.linkImg
//                 }
//                 alt={item.title}
//                 onError={handleErrorImage}
//               /> */}
//               <h1>hhi</h1>
//             </div>
//             <div className="card-bottom">
//               <h3>lol</h3>
//               <span className="category">read</span>
//             </div>
//           </div>
//         ))
//         {/* } */}
//       </Slider>
//     </div>
//   );
// }

// export default Sliders;
// / import React, { Component } from "react";
// import Slider from "react-slick";

// function SampleNextArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{ ...style, display: "block", background: "red" }}
//       onClick={onClick}
//     />
//   );
// }

// function SamplePrevArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{ ...style, display: "block", background: "green" }}
//       onClick={onClick}
//     />
//   );
// }

// export default function CustomArrows () {
//     const settings = {
//       dots: true,
//       infinite: true,
//       slidesToShow: 3,
//       slidesToScroll: 1,
//       nextArrow: <SampleNextArrow />,
//       prevArrow: <SamplePrevArrow />
//     };
//     return (
//       <div>
//         <h2>Custom Arrows</h2>
//         <Slider {...settings}>
//           <div>
//             <h3>1</h3>
//           </div>
//           <div>
//             <h3>2</h3>
//           </div>
//           <div>
//             <h3>3</h3>
//           </div>
//           <div>
//             <h3>4</h3>
//           </div>
//           <div>
//             <h3>5</h3>
//           </div>
//           <div>
//             <h3>6</h3>
//           </div>
//         </Slider>
//       </div>
//     );

// }
