import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/image/logo.png";
import img from "../assets/image/footerimg.png";

export default function Footer() {
  const navigate = useNavigate();

  const [category1Open, setCategory1Open] = useState(false);
  const [category2Open, setCategory2Open] = useState(false);
  const [category3Open, setCategory3Open] = useState(false);
  const [category4Open, setCategory4Open] = useState(false);
  const [category5Open, setCategory5Open] = useState(false);

  const handleCategory1Toggle = () => {
    setCategory1Open(!category1Open);
  };

  const handleCategory2Toggle = () => {
    setCategory2Open(!category2Open);
  };

  const handleCategory3Toggle = () => {
    setCategory3Open(!category3Open);
  };

  const handleCategory4Toggle = () => {
    setCategory4Open(!category4Open);
  };

  const handleCategory5Toggle = () => {
    setCategory5Open(!category5Open);
  };

  return (
    <div className="font-nunito ">
      <div className="lg:px-[100px] lg:pr-[100px] bg-[#062E4E] lg:w-full md:w-[full]">
        <div className=" lg:flex lg:justify-between lg:p-8 pl-[20px] pr-[22px] lg:pr-8 py-[40px] lg:pl-[0px] lg:pt-[80px] lg:pb-[70px] ">
          <div className="">
            <div className="flex gap-[8px] lg:gap-0  ">
              <img
                src={logo}
                alt="#"
                className="lg:w-[52.5px] lg:h-[48px] w-[24.73px] h-[24px]"
              />
              <h2 className="lg:text-[30.17px] text-[16px] lg:ml-[10px]  font-semibold text-[#FFFFFF] tracking-widest">
                PANKH
              </h2>
            </div>
            <p className="lg:text-[20px] text-[18px] font-bold text-[#FFFFFF] md:w-full lg:mt-[42px] mt-[28px]">
              Ready to make the move?
            </p>
            <p className="lg:text-[18px] text-[16px]  text-[#FFFFFFB8] mt-3 sm:w-[200px] lg:mt-[25px]">
              Be more productive, generate more revenue and make business easier
              to run through an exciting path to becoming digital.{" "}
            </p>
            <div className="md:hidden">
              {/* <div className="flex justify-between mt-[28px]">
                <div>
                  <h2 className="text-[#FFFFFF] text-[18px] font-[700]">
                    Company
                  </h2>
                </div>
                <span className="mt-[9px]">
                  <svg
                    width="18"
                    height="11"
                    viewBox="0 0 18 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.7656 0.261719L9.00346 6.02505L3.24129 0.261719L0.765625 2.73739L9.00346 10.9741L17.2413 2.73739L14.7656 0.261719Z"
                      fill="white"
                      fill-opacity="0.72"
                    />
                  </svg>
                </span>
              </div>
              <div className="flex justify-between mt-[28px]">
                <div>
                  <h2 className="text-[#FFFFFF] text-[18px] font-[700]">
                    Store
                  </h2>
                </div>
                <span className="mt-[9px]">
                  <svg
                    width="18"
                    height="11"
                    viewBox="0 0 18 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.7656 0.261719L9.00346 6.02505L3.24129 0.261719L0.765625 2.73739L9.00346 10.9741L17.2413 2.73739L14.7656 0.261719Z"
                      fill="white"
                      fill-opacity="0.72"
                    />
                  </svg>
                </span>
              </div>
              <div className="flex justify-between mt-[28px]">
                <div>
                  <h2 className="text-[#FFFFFF] text-[18px] font-[700]">
                    Products
                  </h2>
                </div>
                <span className="mt-[9px]">
                  <svg
                    width="18"
                    height="11"
                    viewBox="0 0 18 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.7656 0.261719L9.00346 6.02505L3.24129 0.261719L0.765625 2.73739L9.00346 10.9741L17.2413 2.73739L14.7656 0.261719Z"
                      fill="white"
                      fill-opacity="0.72"
                    />
                  </svg>
                </span>
              </div> */}
              <div>
                <ul className="relative m-0 list-none px-[0.2rem] mt-[20px]" >
                  <li className="relative">
                    <a
                      className="text-[#FFFFFF] text-[18px] font-[500] cursor-pointer"
                      onClick={handleCategory1Toggle}
                      data-te-sidenav-link-ref
                    >
                      <span>Company</span>
                      <span
                        className={`absolute right-0 ml-auto mr-[0.8rem] transition-transform duration-300 ease-linear motion-reduce:transition-none ${category1Open ? 'rotate-180' : ''}`}
                        
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                          <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                        </svg>
                      </span>
                    </a>
                    <ul className={`!visible relative m-0 transition duration-300 ease-linear ${category1Open ? 'block' : 'hidden'} list-none p-4 text-[#808080]`} data-te-sidenav-collapse-ref>
                      <li className="relative pb-2 pt-2">
                        {/* <a
                          className="text-[#FFFFFF] text-[16px] font-[500]"
                          data-te-sidenav-link-ref
                        >
                          About 
                        </a> */}
                        <Link className="text-[#FFFFFF] text-[16px] font-[500]" to="/aboutus">About</Link>
                      </li>
                      <li className="relative pb-2">
                        <a
                          className="text-[16px] font-[500]"
                          data-te-sidenav-link-ref
                        >
                          Our Team
                        </a>
                      </li>
                      <li className="relative pb-2">
                        <a
                          className="text-[16px] font-[500]"
                          data-te-sidenav-link-ref
                        >
                          Resources
                        </a>
                      </li>
                      <li className="relative pb-2">
                        <a
                          className="text-[16px] font-[500]"
                          data-te-sidenav-link-ref
                        >
                          Pricing 
                        </a>
                      </li>
                      <li className="relative pb-2">
                        <a
                          className="text-[16px] font-[500]"
                          data-te-sidenav-link-ref
                        >
                          Careers
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="relative m-0 list-none px-[0.2rem] mt-[20px]" >
                  <li className="relative">
                    <a
                      className="text-[#FFFFFF] text-[18px] font-[500] cursor-pointer"
                      onClick={handleCategory2Toggle}
                      data-te-sidenav-link-ref
                    >
                      <span>Store</span>
                      <span
                        className={`absolute right-0 ml-auto mr-[0.8rem] transition-transform duration-300 ease-linear motion-reduce:transition-none ${category2Open ? 'rotate-180' : ''}`}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                          <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                        </svg>
                      </span>
                    </a>
                    <ul className={`!visible relative m-0 transition duration-300 ease-linear ${category2Open ? 'block' : 'hidden'} list-none p-4 text-[#808080]`} data-te-sidenav-collapse-ref>
                      <li className="relative pb-2 pt-2">
                        <a
                          className=" text-[16px] font-[500]"
                          data-te-sidenav-link-ref
                        >
                          Tally 
                        </a>
                      </li>
                      <li className="relative pb-2">
                        <a
                          className=" text-[16px] font-[500]"
                          data-te-sidenav-link-ref
                        >
                          Busy
                        </a>
                      </li>
                      <li className="relative pb-2">
                        <a
                          className=" text-[16px] font-[500]"
                          data-te-sidenav-link-ref
                        >
                          Many more...
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="relative m-0 list-none px-[0.2rem] mt-[20px]" >
                  <li className="relative">
                    <a
                      className="text-[#FFFFFF] text-[18px] font-[500] cursor-pointer"
                      onClick={handleCategory3Toggle}
                      data-te-sidenav-link-ref
                    >
                      <span>Products</span>
                      <span
                        className={`absolute right-0 ml-auto mr-[0.8rem] transition-transform duration-300 ease-linear motion-reduce:transition-none ${category3Open ? 'rotate-180' : ''}`}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                          <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                        </svg>
                      </span>
                    </a>
                    <ul className={`!visible relative m-0 transition duration-300 ease-linear ${category3Open ? 'block' : 'hidden'} list-none p-4 text-[#808080]`} data-te-sidenav-collapse-ref>
                      <li className="relative pb-2 pt-2">
                        <a
                          className="text-[16px] font-[500]"
                          data-te-sidenav-link-ref
                        >
                          Account
                        </a>
                      </li>
                      <li className="relative pb-2">
                        <a
                          className=" text-[16px] font-[500]"
                          data-te-sidenav-link-ref
                        >
                          Inventory
                        </a>
                      </li>
                      <li className="relative pb-2">
                        <a
                          className=" text-[16px] font-[500]"
                          data-te-sidenav-link-ref
                        >
                          Customer Portal
                        </a>
                      </li>
                      <li className="relative pb-2">
                        <a
                          className=" text-[16px] font-[500]"
                          data-te-sidenav-link-ref
                        >
                          Employee Portal
                        </a>
                      </li>
                      <li className="relative pb-2">
                        <a
                          className="text-[16px] font-[500]"
                          data-te-sidenav-link-ref
                        >
                          Employee App
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="relative m-0 list-none px-[0.2rem] mt-[20px]" >
                  <li className="relative">
                    <a
                      className="text-[#FFFFFF] text-[18px] font-[500] cursor-pointer"
                      onClick={handleCategory4Toggle}
                      data-te-sidenav-link-ref
                    >
                      <span>Link</span>
                      <span
                        className={`absolute right-0 ml-auto mr-[0.8rem] transition-transform duration-300 ease-linear motion-reduce:transition-none ${category4Open ? 'rotate-180' : ''}`}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                          <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                        </svg>
                      </span>
                    </a>
                    <ul className={`!visible relative m-0 transition duration-300 ease-linear ${category4Open ? 'block' : 'hidden'} list-none p-4 text-[#808080]`} data-te-sidenav-collapse-ref>
                      <li className="relative pb-2 pt-2">
                        <Link className="text-[#FFFFFF] text-[16px] font-[500]" to="/privacyPolicy">Privacy</Link>
                      </li>
                      <li className="relative pb-2">
                        <Link className="text-[#FFFFFF] text-[16px] font-[500]" to="/termsConditions">Terms and Conditions</Link>
                      </li>
                      <li className="relative pb-2">
                        {/* <a
                          className="text-[#FFFFFF] text-[16px] font-[500]"
                          data-te-sidenav-link-ref
                        >
                          Refund and Cancellation
                        </a> */}
                        <Link className="text-[#FFFFFF] text-[16px] font-[500]" to="/refundCancellation">Refund and Cancellation</Link>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="relative m-0 list-none px-[0.2rem] mt-[20px]" >
                  <li className="relative">
                    <a
                      className="text-[#FFFFFF] text-[18px] font-[500] cursor-pointer"
                      onClick={handleCategory5Toggle}
                      data-te-sidenav-link-ref
                    >
                      <span>Contact Us</span>
                      <span
                        className={`absolute right-0 ml-auto mr-[0.8rem] transition-transform duration-300 ease-linear motion-reduce:transition-none ${category5Open ? 'rotate-180' : ''}`}
                      >
                        
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                          <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                        </svg>
                      </span>
                    </a>
                    <ul className={`!visible relative m-0 transition duration-300 ease-linear ${category5Open ? 'block' : 'hidden'} list-none p-4`} data-te-sidenav-collapse-ref>
                      <li className="relative pb-2 text-[#FFFFFF] pt-2">
                        WhatsApp: <a
                          className="text-[#FFFFFF] text-[16px] font-[500]"
                          href="https://wa.me/+919740993046"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          ‪+91 97409 93046‬
                        </a>
                      </li>
                      <li className="relative pb-2 text-[#FFFFFF]">
                        Phone: <a className="text-[#FFFFFF] text-[16px] font-[500]" href="tel:+919500101976">‪+91 97409 93046‬</a>
                      </li>
                      <li className="relative pb-2 text-[#FFFFFF]">
                        Email: <a className="text-[#FFFFFF] text-[16px] font-[500]" href="mailto:nadit@pankh.io"> nadit@pankh.io</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              {/* <div className="flex justify-between mt-[28px]">
                <div>
                  <h2 className="text-[#FFFFFF] text-[18px] font-[700]">
                    Contact Us
                  </h2>
                </div>
                <span className="mt-[9px]">
                  <svg
                    width="18"
                    height="11"
                    viewBox="0 0 18 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.7656 0.261719L9.00346 6.02505L3.24129 0.261719L0.765625 2.73739L9.00346 10.9741L17.2413 2.73739L14.7656 0.261719Z"
                      fill="white"
                      fill-opacity="0.72"
                    />
                  </svg>
                </span>
              </div> */}
            </div>
            <div className="">
              <div className="hidden md:block lg:pt-[70px]">
                <p className="text-[#FFFFFF] text-[16px] font-[600]">
                  &copy; 2023 Pankh Global Solutions Private Limited
                </p>
              </div>
            </div>
          </div>
          <div className="lg:ml-[20px] hidden md:block">
            <h2 className="text-[20px] font-[500] text-[#FFFFFF] ">Company</h2>
            <ul className="text-[18px]  text-[#808080] mt-[26px] font-[500] sm:hidden lg:contents">
              <li className="mt-[17px] text-[#FFFFFF]">
                {/* <a href="">About</a> */}
                <Link to="/aboutus">About</Link>
              </li>
              <li className="mt-[17px]">
                <a href="">Our Team</a>
              </li>
              <li className="mt-[17px] text-[#FFFFFF]">
                <Link to="/resources"> Resource </Link>
              </li>
              <li className="mt-[17px]">
                <a href="">Pricing</a>
              </li>
              <li className="mt-[17px]">
                <a href="">Careers</a>
              </li>
            </ul>
          </div>
          <div className="lg:ml-[20px] hidden md:block">
            <h2 className="text-[20px] font-[500] text-[#FFFFFF]">Store</h2>
            <ul className="text-[18px]  text-[#808080] mt-[26px] font-[500] sm:hidden lg:contents">
              <li className="mt-[17px]">
                <a href="">Tally</a>
              </li>
              <li className="mt-[17px]">
                <a href="">Busy</a>
              </li>
              <li className="mt-[17px]">
                <a href="">Many more...</a>
              </li>
              {/* <li className="mt-[17px]"><a href="">Pricing</a></li>
              <li className="mt-[17px]"><a href="">Careers</a></li> */}
            </ul>
          </div>
          <div className="lg:ml-[20px] hidden md:block">
            <h2 className="text-[20px] font-[500] text-[#FFFFFF]">Products</h2>
            <ul className="text-[18px]  text-[#808080] mt-[26px] font-[500] sm:hidden lg:contents">
              <li className="mt-[17px]">
                <a href="">Account</a>
              </li>
              <li className="mt-[17px]">
                <a href="">Inventory</a>
              </li>
              <li className="mt-[17px]">
                <a href="">Customer Portal</a>
              </li>
              <li className="mt-[17px]">
                <a href="">Employee Portal</a>
              </li>
              <li className="mt-[17px]">
                <a href="">Employee App</a>
              </li>
            </ul>
          </div>
          <div className="lg:ml-[20px] hidden md:block">
            <h2 className="text-[20px] font-[500] text-[#FFFFFF]">Link</h2>
            <ul className="text-[18px]  text-[#808080] mt-[26px] font-[500] sm:hidden lg:contents">
              <li className="mt-[17px] text-[#FFFFFF]">
                <Link to="/privacyPolicy">Privacy</Link>
              </li>
              <li className="mt-[17px] text-[#FFFFFF]">
                <Link to="/termsConditions">Terms and Conditions</Link>
              </li>
              <li className="mt-[17px] text-[#FFFFFF]">
                {/* <a href="">Refund and Cancellation</a> */}
                <Link to="/refundCancellation">Refund and Cancellation</Link>
              </li>
            </ul>
          </div>
          <div className="lg:ml-[20px] ">
            <h2 className="text-[20px] hidden md:block font-[500] text-[#FFFFFF]">
              Contact Us
            </h2>
            <ul className="text-[18px] hidden md:block  text-[#FFFFFF] sm:hidden lg:contents">
              <li className="mt-[17px]">
                WhatsApp: <a
                  href="https://wa.me/+919740993046"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                ‪+91 97409 93046‬
                </a>
              </li>
              <li className="mt-[17px]">
                Phone: <a href="tel:+919500101976">‪+91 97409 93046‬</a>
              </li>
              <li className="mt-[17px]">
                Email: <a href="mailto:nadit@pankh.io"> nadit@pankh.io</a>
              </li>
            </ul>
            <br />
            <h2 className="text-[18px] hidden md:block font-[500] text-[#FFFFFF] mt-[26px] sm:hidden lg:contents">
              Address
            </h2>
            {/* <div className="mt-5 hidden md:block">
              <p className="text-[#F9F9FA] text-p text-[700] mb-1 ">
                Subscribe to Newsletter
              </p>
              <div className=" lg:max-w-[371px] hidden md:flex  h-[48px] w-full  justify-between rounded-[4px] border-[1px] border-solid border-[#FFFFFFD1] items-center ">
                <input
                  className="w-full h-full bg-[#062E4E] p-2 focus:outline-none border-none rounded-[4px] text-p text-[#FFFFFFD1] font-[400] "
                  placeholder="Email Adress"
                />
                <button className="lg:w-[111.1px] lg:h-full bg-[#fff] sm:w-[150px] rounded-[4px] text-[#615F5F] text-p font-[500] flex justify-center items-center  ">
                  Subscribe
                </button>
              </div>
            </div> */}
            <div className="w-[200px] hidden md:block h-[82px] sm:hidden lg:contents ">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d35442.14156745132!2d77.21231500024783!3d28.606048836608068!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce2daa9eb4d0b%3A0x717971125923e5d!2sIndia%20Gate!5e0!3m2!1sen!2sin!4v1668507979547!5m2!1sen!2sin"
                width="200"
                height="82"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <div className="flex gap-[28px] lg:gap-[40px] items-center justify-start  mt-[42px]">
              <span>
                <a href="https://www.facebook.com/pankhio8" target="_blank">
                <button >
                  <svg
                    width="12"
                    height="21"
                    viewBox="0 0 12 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.2137 11.8118L11.8362 8.0115H7.94515V5.54531C7.94515 4.50581 8.4885 3.49191 10.2313 3.49191H12V0.256594C12 0.256594 10.3951 0 8.86031 0C5.6562 0 3.56191 1.82044 3.56191 5.11547V8.01216H0V11.8125H3.56191V21H7.94515V11.8125L11.2137 11.8118Z"
                      fill="#F3F3F3"
                    />
                  </svg>
                </button></a>
              </span>
              <span>
                <a href="https://www.linkedin.com/company/pankhio/" target="_blank">
                <button>
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.387097 21H4.25806V6.43125H0.387097V21ZM2.32258 0C1.03226 0 0 1.05 0 2.3625C0 3.675 1.03226 4.725 2.32258 4.725C3.6129 4.725 4.64516 3.675 4.64516 2.3625C4.64516 1.05 3.6129 0 2.32258 0ZM10.8387 8.6625V6.43125H6.96774V21H10.8387V13.5188C10.8387 9.31875 16.129 9.05625 16.129 13.5188V21H20V12.075C20 4.9875 12.6452 5.25 10.8387 8.6625Z"
                      fill="#F3F3F3"
                    />
                  </svg>
                </button></a>
              </span>
              <span>
                <a href="https://twitter.com/pankhio" target="_blank">
                <button>
                  <svg
                    width="25"
                    height="21"
                    viewBox="0 0 25 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M25 2.48593C24.0801 2.90755 23.0916 3.19251 22.0541 3.3207C23.1132 2.66459 23.9264 1.62563 24.3093 0.387708C23.3025 1.00524 22.201 1.44033 21.0523 1.67418C20.1167 0.643891 18.7838 0 17.3085 0C14.4759 0 12.1793 2.37369 12.1793 5.30133C12.1793 5.71689 12.2247 6.12146 12.3121 6.50957C8.04941 6.28841 4.27012 4.17787 1.74033 0.97043C1.29893 1.75342 1.046 2.66419 1.046 3.63563C1.046 5.47494 1.95156 7.09754 3.32773 8.04829C2.51323 8.02192 1.71665 7.79454 1.00449 7.38512C1.0042 7.40732 1.0042 7.42953 1.0042 7.45184C1.0042 10.0204 2.77217 12.1632 5.11846 12.6502C4.36317 12.8625 3.57093 12.8936 2.80225 12.7411C3.45488 14.8473 5.34912 16.3799 7.59346 16.4228C5.83809 17.8446 3.62646 18.6922 1.22354 18.6922C0.809473 18.6922 0.40127 18.6671 0 18.6181C2.26982 20.1223 4.96582 21 7.8623 21C17.2966 21 22.4555 12.9217 22.4555 5.91615C22.4555 5.68621 22.4506 5.45758 22.4406 5.23027C23.4448 4.47997 24.3114 3.55065 25 2.48593Z"
                      fill="#F3F3F3"
                    />
                  </svg>
                </button></a>
              </span>
              <span>
                <a href="https://www.instagram.com/pankhio/" target="_blank">
                <button>
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"
                      fill="#ffffff"
                    ></path>
                  </svg>
                </button></a>
              </span>
            </div>
            <div className=" md:hidden mt-[40px] lg:pt-[70px]">
              <p className="text-[#FFFFFF] text-[16px] font-[600]">
                &copy; 2023 Pankh Global Solutions Private Limited
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// export default function Footer() {
//   const navigate = useNavigate();

//   return (
//     <div className="font-nunito ">
//       <div className="lg:px-[100px] lg:pr-[100px] bg-[#062E4E] lg:w-full md:w-[full]">
//         <div className=" lg:flex lg:justify-between lg:p-8 pl-[20px] pr-[22px] lg:pr-8 py-[40px] lg:pl-[0px] lg:pt-[80px] lg:pb-[70px] ">
//           <div className="">
//             <div className="flex gap-[8px] lg:gap-0  ">
//               <img
//                 src={logo}
//                 alt="#"
//                 className="lg:w-[52.5px] lg:h-[48px] w-[24.73px] h-[24px]"
//               />
//               <h2 className="lg:text-[30.17px] text-[16px] lg:ml-[10px]  font-semibold text-[#FFFFFF] tracking-widest">
//                 PANKH
//               </h2>
//             </div>
//             <p className="lg:text-[20px] text-[18px] font-bold text-[#FFFFFF] md:w-full lg:mt-[42px] mt-[28px]">
//               Ready to make the move?
//             </p>
//             <p className="lg:text-[18px] text-[16px]  text-[#FFFFFFB8] mt-3 sm:w-[200px] lg:mt-[25px]">
//               Be more productive, generate more revenue and make business easier
//               to run through an exciting path to becoming digital.{" "}
//             </p>
//             <div className="md:hidden">
//               <div className="flex justify-between mt-[28px]">
//                 <div>
//                   <h2 className="text-[#FFFFFF] text-[18px] font-[700]">
//                     Company
//                   </h2>
//                 </div>
//                 <span className="mt-[9px]">
//                   <svg
//                     width="18"
//                     height="11"
//                     viewBox="0 0 18 11"
//                     fill="none"
//                     xmlns="http://www.w3.org/2000/svg"
//                   >
//                     <path
//                       d="M14.7656 0.261719L9.00346 6.02505L3.24129 0.261719L0.765625 2.73739L9.00346 10.9741L17.2413 2.73739L14.7656 0.261719Z"
//                       fill="white"
//                       fill-opacity="0.72"
//                     />
//                   </svg>
//                 </span>
//               </div>
//               <div className="flex justify-between mt-[28px]">
//                 <div>
//                   <h2 className="text-[#FFFFFF] text-[18px] font-[700]">
//                     Store
//                   </h2>
//                 </div>
//                 <span className="mt-[9px]">
//                   <svg
//                     width="18"
//                     height="11"
//                     viewBox="0 0 18 11"
//                     fill="none"
//                     xmlns="http://www.w3.org/2000/svg"
//                   >
//                     <path
//                       d="M14.7656 0.261719L9.00346 6.02505L3.24129 0.261719L0.765625 2.73739L9.00346 10.9741L17.2413 2.73739L14.7656 0.261719Z"
//                       fill="white"
//                       fill-opacity="0.72"
//                     />
//                   </svg>
//                 </span>
//               </div>
//               <div className="flex justify-between mt-[28px]">
//                 <div>
//                   <h2 className="text-[#FFFFFF] text-[18px] font-[700]">
//                     Products
//                   </h2>
//                 </div>
//                 <span className="mt-[9px]">
//                   <svg
//                     width="18"
//                     height="11"
//                     viewBox="0 0 18 11"
//                     fill="none"
//                     xmlns="http://www.w3.org/2000/svg"
//                   >
//                     <path
//                       d="M14.7656 0.261719L9.00346 6.02505L3.24129 0.261719L0.765625 2.73739L9.00346 10.9741L17.2413 2.73739L14.7656 0.261719Z"
//                       fill="white"
//                       fill-opacity="0.72"
//                     />
//                   </svg>
//                 </span>
//               </div>
//               <div className="flex justify-between mt-[28px]">
//                 <div>
//                   <h2 className="text-[#FFFFFF] text-[18px] font-[700]">
//                     Links
//                   </h2>
//                 </div>
//                 <span className="mt-[9px]">
//                   <svg
//                     width="18"
//                     height="11"
//                     viewBox="0 0 18 11"
//                     fill="none"
//                     xmlns="http://www.w3.org/2000/svg"
//                   >
//                     <path
//                       d="M14.7656 0.261719L9.00346 6.02505L3.24129 0.261719L0.765625 2.73739L9.00346 10.9741L17.2413 2.73739L14.7656 0.261719Z"
//                       fill="white"
//                       fill-opacity="0.72"
//                     />
//                   </svg>
//                 </span>
//               </div>
//               <div className="flex justify-between mt-[28px]">
//                 <div>
//                   <h2 className="text-[#FFFFFF] text-[18px] font-[700]">
//                     Contact Us
//                   </h2>
//                 </div>
//                 <span className="mt-[9px]">
//                   <svg
//                     width="18"
//                     height="11"
//                     viewBox="0 0 18 11"
//                     fill="none"
//                     xmlns="http://www.w3.org/2000/svg"
//                   >
//                     <path
//                       d="M14.7656 0.261719L9.00346 6.02505L3.24129 0.261719L0.765625 2.73739L9.00346 10.9741L17.2413 2.73739L14.7656 0.261719Z"
//                       fill="white"
//                       fill-opacity="0.72"
//                     />
//                   </svg>
//                 </span>
//               </div>
//             </div>
//             <div className="">
//               <div className="hidden md:block lg:pt-[70px]">
//                 <p className="text-[#FFFFFF] text-[16px] font-[600]">
//                   &copy; 2023 Pankh Global Solutions Private Limited
//                 </p>
//               </div>
//             </div>
//           </div>
//           <div className="lg:ml-[20px] hidden md:block lg:mr-[10px]">
//             <h2 className="text-[20px] font-[500] text-[#FFFFFF] ">Company</h2>
//             <ul className="text-[18px]  text-[#808080] mt-[26px] font-[500] sm:hidden lg:contents">
//               <li className="mt-[17px] text-[#FFFFFF]">
//                 {/* <a href="">About</a> */}
//                 <Link to="/aboutus">About</Link>
//               </li>
//               <li className="mt-[17px]">
//                 <a href="">Our Team</a>
//               </li>
//               <li className="mt-[17px]">
//                 <a href="">Resources</a>
//                 {/* <Link to="/resources"> Resources </Link> */}
//               </li>
//               <li className="mt-[17px]">
//                 <a href="">Pricing</a>
//               </li>
//               <li className="mt-[17px]">
//                 <a href="">Careers</a>
//               </li>
//             </ul>
//           </div>
//           <div className="lg:ml-[20px] hidden md:block">
//             <h2 className="text-[20px] font-[500] text-[#FFFFFF]">Store</h2>
//             <ul className="text-[18px]  text-[#808080] mt-[26px] font-[500] sm:hidden lg:contents">
//               <li className="mt-[17px]">
//                 <a href="">Tally</a>
//               </li>
//               <li className="mt-[17px]">
//                 <a href="">Busy</a>
//               </li>
//               <li className="mt-[17px]">
//                 <a href="">Many more...</a>
//               </li>
//               {/* <li className="mt-[17px]"><a href="">Pricing</a></li>
//               <li className="mt-[17px]"><a href="">Careers</a></li> */}
//             </ul>
//           </div>
//           <div className="lg:ml-[20px] hidden md:block">
//             <h2 className="text-[20px] font-[500] text-[#FFFFFF]">Products</h2>
//             <ul className="text-[18px]  text-[#808080] mt-[26px] font-[500] sm:hidden lg:contents">
//               <li className="mt-[17px]">
//                 <a href="">Account</a>
//               </li>
//               <li className="mt-[17px]">
//                 <a href="">Inventory</a>
//               </li>
//               <li className="mt-[17px]">
//                 <a href="">Customer Portal</a>
//               </li>
//               <li className="mt-[17px]">
//                 <a href="">Employee Portal</a>
//               </li>
//               <li className="mt-[17px]">
//                 <a href="">Employee App</a>
//               </li>
//             </ul>
//           </div>
//           <div className="lg:ml-[20px] hidden md:block">
//             <h2 className="text-[20px] font-[500] text-[#FFFFFF]">Link</h2>
//             <ul className="text-[18px]  text-[#808080] mt-[26px] font-[500] sm:hidden lg:contents">
//               <li className="mt-[17px]">
//                 <a href="">Privacy</a>
//               </li>
//               <li className="mt-[17px]">
//                 <a href="">Terms and Conditions</a>
//               </li>
//               <li className="mt-[17px] text-[#FFFFFF]">
//                 {/* <a href="">Refund and Cancellation</a> */}
//                 <Link to="/refundCancellation">Refund and Cancellation</Link>
//               </li>
//             </ul>
//           </div>
//           <div className="lg:ml-[20px] ">
//             <h2 className="text-[20px] hidden md:block font-[500] text-[#FFFFFF]">
//               Contact Us
//             </h2>
//             <ul className="text-[18px] hidden md:block  text-[#FFFFFFB8] sm:hidden lg:contents">
//               <li className="mt-[17px]">
//                 WhatsApp: <a href="">‪+91 97409 93046‬</a>
//               </li>
//               <li className="mt-[17px]">
//                 Phone: <a href="">‪+91 97409 93046‬</a>
//               </li>

//               <li className="mt-[17px]">
//                 Email: <a href="">nadit@pankh.io</a>
//               </li>
//             </ul>
//             <br />
//             <h2 className="text-[18px] hidden md:block font-[500] text-[#FFFFFF] mt-[26px] sm:hidden lg:contents">
//               Address
//             </h2>
//             {/* <div className="mt-5 hidden md:block">
//               <p className="text-[#F9F9FA] text-p text-[700] mb-1 ">
//                 Subscribe to Newsletter
//               </p>
//               <div className=" lg:max-w-[371px] hidden md:flex  h-[48px] w-full  justify-between rounded-[4px] border-[1px] border-solid border-[#FFFFFFD1] items-center ">
//                 <input
//                   className="w-full h-full bg-[#062E4E] p-2 focus:outline-none border-none rounded-[4px] text-p text-[#FFFFFFD1] font-[400] "
//                   placeholder="Email Adress"
//                 />
//                 <button className="lg:w-[111.1px] lg:h-full bg-[#fff] sm:w-[150px] rounded-[4px] text-[#615F5F] text-p font-[500] flex justify-center items-center  ">
//                   Subscribe
//                 </button>
//               </div>
//             </div> */}
//             <div className="w-[200px] hidden md:block h-[82px] sm:hidden lg:contents ">
//               <iframe
//                 src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d35442.14156745132!2d77.21231500024783!3d28.606048836608068!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce2daa9eb4d0b%3A0x717971125923e5d!2sIndia%20Gate!5e0!3m2!1sen!2sin!4v1668507979547!5m2!1sen!2sin"
//                 width="200"
//                 height="82"
//                 allowfullscreen=""
//                 loading="lazy"
//                 referrerpolicy="no-referrer-when-downgrade"
//               ></iframe>
//             </div>
//             <div className="flex gap-[28px] lg:gap-[40px] items-center justify-start  mt-[42px]">
//               <span>
//                 <a href="https://www.facebook.com/pankhio8" target="_blank">
//                 <button >
//                   <svg
//                     width="12"
//                     height="21"
//                     viewBox="0 0 12 21"
//                     fill="none"
//                     xmlns="http://www.w3.org/2000/svg"
//                   >
//                     <path
//                       d="M11.2137 11.8118L11.8362 8.0115H7.94515V5.54531C7.94515 4.50581 8.4885 3.49191 10.2313 3.49191H12V0.256594C12 0.256594 10.3951 0 8.86031 0C5.6562 0 3.56191 1.82044 3.56191 5.11547V8.01216H0V11.8125H3.56191V21H7.94515V11.8125L11.2137 11.8118Z"
//                       fill="#F3F3F3"
//                     />
//                   </svg>
//                 </button></a>
//               </span>
//               <span>
//                 <a href="https://www.linkedin.com/company/pankhio/" target="_blank">
//                 <button>
//                   <svg
//                     width="20"
//                     height="21"
//                     viewBox="0 0 20 21"
//                     fill="none"
//                     xmlns="http://www.w3.org/2000/svg"
//                   >
//                     <path
//                       d="M0.387097 21H4.25806V6.43125H0.387097V21ZM2.32258 0C1.03226 0 0 1.05 0 2.3625C0 3.675 1.03226 4.725 2.32258 4.725C3.6129 4.725 4.64516 3.675 4.64516 2.3625C4.64516 1.05 3.6129 0 2.32258 0ZM10.8387 8.6625V6.43125H6.96774V21H10.8387V13.5188C10.8387 9.31875 16.129 9.05625 16.129 13.5188V21H20V12.075C20 4.9875 12.6452 5.25 10.8387 8.6625Z"
//                       fill="#F3F3F3"
//                     />
//                   </svg>
//                 </button></a>
//               </span>
//               <span>
//                 <a href="https://twitter.com/pankhio" target="_blank">
//                 <button>
//                   <svg
//                     width="25"
//                     height="21"
//                     viewBox="0 0 25 21"
//                     fill="none"
//                     xmlns="http://www.w3.org/2000/svg"
//                   >
//                     <path
//                       d="M25 2.48593C24.0801 2.90755 23.0916 3.19251 22.0541 3.3207C23.1132 2.66459 23.9264 1.62563 24.3093 0.387708C23.3025 1.00524 22.201 1.44033 21.0523 1.67418C20.1167 0.643891 18.7838 0 17.3085 0C14.4759 0 12.1793 2.37369 12.1793 5.30133C12.1793 5.71689 12.2247 6.12146 12.3121 6.50957C8.04941 6.28841 4.27012 4.17787 1.74033 0.97043C1.29893 1.75342 1.046 2.66419 1.046 3.63563C1.046 5.47494 1.95156 7.09754 3.32773 8.04829C2.51323 8.02192 1.71665 7.79454 1.00449 7.38512C1.0042 7.40732 1.0042 7.42953 1.0042 7.45184C1.0042 10.0204 2.77217 12.1632 5.11846 12.6502C4.36317 12.8625 3.57093 12.8936 2.80225 12.7411C3.45488 14.8473 5.34912 16.3799 7.59346 16.4228C5.83809 17.8446 3.62646 18.6922 1.22354 18.6922C0.809473 18.6922 0.40127 18.6671 0 18.6181C2.26982 20.1223 4.96582 21 7.8623 21C17.2966 21 22.4555 12.9217 22.4555 5.91615C22.4555 5.68621 22.4506 5.45758 22.4406 5.23027C23.4448 4.47997 24.3114 3.55065 25 2.48593Z"
//                       fill="#F3F3F3"
//                     />
//                   </svg>
//                 </button></a>
//               </span>
//               <span>
//                 <a href="https://www.instagram.com/pankhio/" target="_blank">
//                 <button>
//                   <svg
//                     width="20"
//                     height="21"
//                     viewBox="0 0 20 21"
//                     fill="none"
//                     xmlns="http://www.w3.org/2000/svg"
//                   >
//                     <path
//                       d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"
//                       fill="#ffffff"
//                     ></path>
//                   </svg>
//                 </button></a>
//               </span>
//             </div>
//             <div className=" md:hidden mt-[40px] lg:pt-[70px]">
//               <p className="text-[#FFFFFF] text-[16px] font-[600]">
//                 &copy; 2023 Pankh Global Solutions Private Limited
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
