import react from "react";
import image from "../../assets/image/cardimg.png";
import { Link, useNavigate } from "react-router-dom";

export default function KnowMoreCard({ setModal }) {
  const navigate = useNavigate();

  return (
    <div className="w-fit">
      <div className="w-[390px] h-[679px] hidden md:block bg-[#FFFFFF] relative border-[#CACACA7D] border-[1px] p-[24px]   ">
        <button
          className="absolute top-4 right-4"
          onClick={() => setModal((prev) => ({ ...prev, knowMore: false }))}
        >
          <svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.3125 2.01562L12.6875 12.9844M12.6875 2.01562L1.3125 12.9844L12.6875 2.01562Z"
              stroke="#616161"
              stroke-width="2.4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
        <div className="flex flex-col justify-center items-center ">
          <div className="mt-[20px] w-[190px] h-[165px] flex justify-center items-center">
            <img src={image} alt="" />
          </div>
        </div>
        <div className="flex flex-col justify-center items-center mt-[20px]">
          <h2 className="text-[#124870] text-[24px] font-[600]">LoremIp</h2>
          <h3 className="text-[#615F5F] text-[20px] font-[600] mt-[12px]">
            Accounting tools
          </h3>
          <p className="text-[#9B9B9B] text-[16px] font-[600] mt-[30px]">
            Lorem ipsum dolor sit amet, consec tetur adip is cing elit. Quisque
            est male suada dict umst morbi varius justo, vulputate tincidunt
            nequ eest males.
          </p>
        </div>
        <div className="mt-[15px]">
          <div className="flex justify-between ">
            <h2 className="text-[#797979] text-[16px] font-[700]">
              Offered By:
            </h2>
            <h2 className="text-[#124870] text-[16px] font-[700]">
              SolutionsPvt.
            </h2>
          </div>
          <div className="flex justify-between mt-[5px]">
            <h2 className="text-[#797979] text-[16px] font-[700]">
              Connection Type:
            </h2>
            <h2 className="text-[#124870] text-[16px] font-[700]">TallySync</h2>
          </div>
          <div className="flex justify-between mt-[5px]">
            <h2 className="text-[#797979] text-[16px] font-[700]">Price:</h2>
            <h2 className="text-[#E47C07] text-[16px] font-[700]">₹4000.90</h2>
          </div>
        </div>
        <div>
          <button
            onClick={() => navigate("/productDetail")}
            className="bg-[#FFA502] text-[#FFFFFF] py-[13px] mt-[5px] w-full rounded-[2px] text-[16px] font-[600]"
          >
            Buy
          </button>
          <button
            onClick={() => navigate("/productDetail")}
            className="border-[#124870] border-[1px] mt-[10px] py-[13px] w-full rounded-[2px] text-[#124870] text-[16px] font-[600]"
          >
            See details
          </button>
        </div>
      </div>
    </div>
  );
}
