import React from 'react';

const Popup = ({ isOpen, onClose }) => {
  return (
    <div className={`fixed top-0 left-0 w-full h-full flex justify-center items-center ${isOpen ? 'block' : 'hidden'}`}>
      <div className="bg-white rounded-lg p-8 shadow-lg">
        <p className="text-gray-800 text-lg">
          Thank you for contacting! We will reach out to you soon.
        </p>
        <button
          className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default Popup;
