import React from "react";
import { useState } from "react";
import image1 from "../../assets/image/dashboard.png";
import image2 from "../../assets/image/customerboard.png";
import image3 from "../../assets/image/employeeboard.png";
import image4 from "../../assets/image/b2becom.png";
// import image5 from "../../assets/image/dashbord5.png";
// import image6 from "../../assets/image/Customer1.png";
// import image7 from "../../assets/image/Customer2.png";
// import image8 from "../../assets/image/Customer3.png";
// import image9 from "../../assets/image/Customer4.png";
// import image10 from "../../assets/image/Employee1.png";
// import image11 from "../../assets/image/Employee2.png";
// import image12 from "../../assets/image/Employee3.png";
// import image13 from "../../assets/image/b2b.png";

export default function Dashbord() {
  const [dashboard, setDashboard] = useState(true);
  const [customerBoard, setCustomerBoard] = useState(false);
  const [employeeBoard, setEmployeeBoard] = useState(false);
  const [b2b, setB2b] = useState(false);

  const dashboardHandler = () => {
    setDashboard(true);
    setCustomerBoard(false);
    setEmployeeBoard(false);
    setB2b(false);
  };
  const customerBoardHandler = () => {
    setDashboard(false);
    setCustomerBoard(true);
    setEmployeeBoard(false);
    setB2b(false);
  };

  const employeeBoardHandler = () => {
    setDashboard(false);
    setCustomerBoard(false);
    setB2b(false);

    setEmployeeBoard(true);
  };

  const b2bHandler = () => {
    setB2b(true);
    setDashboard(false);
    setCustomerBoard(false);
    setEmployeeBoard(false);
  };
  return (
    <div className="lg:px-[100px] lg:pr-[100px] bg-[#062E4E] md:p-[60px] p-5 font-nunito">
      <div className="flex flex-col items-center">
        <h2 className="text-[#FFFFFF] hidden md:block text-center text-[32px] font-[800] lg:w-[950px]">
          Explore how Businesses around the world can use our Software to
          Improve the way of work
        </h2>
        <h2 className="text-[#FFFFFF] text-[20px] font-[700] text-center px-8  md:hidden" >Software to improve the way of work</h2>
      </div>

      <div className="flex  overflow-x-scroll md:overflow-hidden w-full scrollbar-hide text-[#FFFFFF7A] md:text-[20px] text-[16px] font-[700] mt-7 md:mt-[60px]">
        <div className="flex flex-nowrap md:flex md:justify-around md:w-full ">
        <button
          className={`h-[28px] w-[160px] md:h-[40px] md:w-[200px] hover:bg-[#FFFFFF] hover:text-[#062E4E] rounded ${
            dashboard && "bg-[#FFFFFF] text-[#062E4E]"
          }`}
          onClick={dashboardHandler}
        >
          Dashboard
        </button>
        <button
          className={`h-[28px] w-[160px] md:h-[40px] md:w-[200px] hover:bg-[#FFFFFF] hover:text-[#062E4E] rounded ${
            customerBoard && "bg-[#FFFFFF] text-[#062E4E]"
          }`}
          onClick={customerBoardHandler}
        >
          Customer Board
        </button>
        <button
          className={`h-[28px] w-[160px] md:h-[40px] md:w-[200px] hover:bg-[#FFFFFF]  hover:text-[#062E4E]  rounded ${
            employeeBoard && "bg-[#FFFFFF] text-[#062E4E]"
          }`}
          onClick={employeeBoardHandler}
        >
          Employee Board
        </button>
        <button
          className={` h-[28px] w-[160px] md:h-[40px] md:w-[200px] hover:bg-[#FFFFFF]   hover:text-[#062E4E] rounded ${
            b2b && "bg-[#FFFFFF] text-[#062E4E]"
          }`}
          onClick={b2bHandler}
        >
          B2B E-Comm
        </button>
      </div>
      </div>

      <div className="bg-[#D8E9F0] lg:p-[28px] rounded-lg mt-[35px]">
        {dashboard && (
          <>
            <div className="flex lg:gap-[2px]">
            <img style={{width: "100%"}} src={image1} alt="" />

              {/* <div>
                <img src={image1} alt="" />
                <img src={image3} alt="" className="lg:mt-[20px]" />
              </div>
              <div>
                <img src={image2} alt="" />
                <img src={image4} alt="" className="lg:mt-[20px]" />
              </div> */}
            </div>
            {/* <div>
              <img src={image5} alt="" className="lg:mt-[20px]" />
            </div> */}
          </>
        )}
        {customerBoard && (
          <>
            <div className="flex lg:gap-[20px]">
            <img style={{width: "100%"}} src={image2} alt="" />

              {/* <div>
                <img src={image6} alt="" />
                <img src={image7} alt="" className="lg:mt-[20px]" />
              </div>
              <div>
                <img src={image9} alt="" />
                <img src={image8} alt="" className="lg:mt-[20px]" />
              </div> */}
            </div>
          </>
        )}
        {employeeBoard && (
          <>
            <div>
            <img style={{width: "100%"}} src={image3} alt="" />

              {/* <div>
                <img src={image10} alt="" />
              </div>
              <div className="flex lg:gap-[20px] lg:mt-[20px]">
                <div className="w-[640px] h-[499px] rounded-[8px]">
                  <img src={image11} alt="" className=" object-cover w-full" />
                </div>
                <div className="w-[605px] h-[497px] rounded-[8px]">
                  <img src={image12} alt="" className=" object-cover w-full" />
                </div>
              </div> */}
            </div>
          </>
        )}
        {b2b && (
          <>
            <div className="flex lg:gap-[20px]">
            <img style={{width: "100%"}} src={image4} alt="" />

              {/* <div>
                <img src={image13} alt="" />
                <img src={image3} alt="" className="lg:mt-[20px]" />
              </div>
              <div>
                <img src={image2} alt="" />
                <img src={image4} alt="" className="lg:mt-[20px]" />
              </div>
            </div>
            <div>
              <img src={image5} alt="" className="mt-[20px]" /> */}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
