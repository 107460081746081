import React from "react";
import image from "../../assets/image/featuresing.png";
import Footer from "../../components/footer";
import logo from "../../assets/image/logo.png";
import { Link, useNavigate } from "react-router-dom";
import ResponsiveNav from "../../components/responsiveNav";
import Navbar from "../../components/navbar";

export default function Features() {
  const navigate = useNavigate();

  return (
    <div className="font-nunito">
      <Navbar 
      heading={"Unleash the Power of Pankh"}
      headingSm={'Unleash the Power of Pankh'}
      subheading={'Digitizing Processes for Seamless Business Evolution'}
      buttonText={"Learn More"}
      description={'Discover a world of possibilities with Pankh.io. Our robust suite of features empowers you to streamline your workflow, enhance productivity, and achieve more than you ever thought possible. Explore the tools that will revolutionize the way you work.'}
      />
      {/* <ResponsiveNav Main={"Features"} /> */}
      <div className="bg-[#f1f7fa] pt-6">
        <button
          onClick={() => navigate(-1)}
          className="text-[#747474] md:hidden ml-5 text-[14px] font-[700] gap-1 flex"
        >
          <span className="mt-[3px] mr-1">
            <svg
              width="16"
              height="14"
              viewBox="0 0 16 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 6.00125H3.14L6.77 1.64125C6.93974 1.43704 7.0214 1.17375 6.99702 0.909329C6.97264 0.644902 6.84422 0.400991 6.64 0.231252C6.43578 0.0615137 6.1725 -0.0201482 5.90808 0.0042315C5.64365 0.0286112 5.39974 0.157036 5.23 0.361252L0.23 6.36125C0.196361 6.40898 0.166279 6.45911 0.14 6.51125C0.14 6.56125 0.14 6.59125 0.0700002 6.64125C0.0246737 6.75591 0.000941121 6.87796 0 7.00125C0.000941121 7.12454 0.0246737 7.24659 0.0700002 7.36125C0.0700002 7.41125 0.0699999 7.44125 0.14 7.49125C0.166279 7.54339 0.196361 7.59353 0.23 7.64125L5.23 13.6413C5.32402 13.7541 5.44176 13.8449 5.57485 13.9071C5.70793 13.9694 5.85309 14.0015 6 14.0013C6.23365 14.0017 6.46009 13.9203 6.64 13.7713C6.74126 13.6873 6.82496 13.5842 6.88631 13.4679C6.94766 13.3515 6.98546 13.2242 6.99754 13.0932C7.00961 12.9622 6.99573 12.8302 6.95669 12.7046C6.91764 12.579 6.8542 12.4623 6.77 12.3613L3.14 8.00125H15C15.2652 8.00125 15.5196 7.8959 15.7071 7.70836C15.8946 7.52082 16 7.26647 16 7.00125C16 6.73604 15.8946 6.48168 15.7071 6.29415C15.5196 6.10661 15.2652 6.00125 15 6.00125Z"
                fill="#747474"
              />
            </svg>
          </span>{" "}
          Back
        </button>
        <div className="flex lg:py-[80px] py-6 lg:px-[50px] mt-4 bg-[#FFFFFF] px-5 flex-col md:flex-row items-center justify-center">
          <div className="lg:w-[50%] lg:pr-[40px] flex flex-col justify-center items-center md:items-start">
            <h2 className="md:text-[32px] text-[20px] font-[700] md:font-[800] font-monterrat text-[#074381] ">
              Transforming B2B Operations for Your Success
            </h2>
            <h3 className="text-[18px] text-[#074381] mt-3 font-[700] text-center md:hidden">
              Transforming B2B Operations for Your Success
            </h3>
            <div className="rounded-[20px] mt-3 md:hidden bg-[#E3F2F5] p-[10px] lg:p-[20px]">
              <img src={image} alt="#" />
            </div>
            <h3 className="text-[24px] hidden md:block capitalize font-[800] text-[#074381] mt-[12px]">
              Lorem missing Ipsum.
            </h3>
            <h4 className="md:text-[18px] text-[14px] mt-3 text-[#747474D6] text-center md:text-left font-[700] md:mt-[40px]">
              At Pankh, our mission is your success. We empower you to simplify and optimize B2B operations, boosting efficiency and collaboration across your network.
              <br/><br/>
              With seamless integration into your accounting systems, our digital solution drives growth and agility. Join us to unlock the full potential of your business in the digital age.
            </h4>
          </div>
          <div className="hidden md:block rounded-[20px] bg-[#E3F2F5] lg:p-[20px]">
            <img src={image} alt="#" />
          </div>
        </div>
        <div className="md:min-h-[1200px] bg-[#062E4E] py-[28px] md:py-0">
          <div className="flex flex-col justify-center items-center ">
            <h2 className="text-[20px] md:hidden font-[700] text-[#FFFFFF]">
              Updates On Businesses
            </h2>
            <h3 className="text-[16px]  md:hidden font-[600] text-[#FFFFFF]">
              Welcome to the future of B2B operations.{" "}
            </h3>
            <h2 className="text-[#FFFFFFD1] hidden md:block capitalize text-[32px] font-[800] font-monterrat mt-[50px]">
              Stay updated with your business at any time from anywhere{" "}
            </h2>
            <h4 className="text-[#FFFFFF] hidden md:block text-[24px] font-[800] mt-[5px]  ">
              Pankh isn't just a solution; it's a competitive advantage that propels your business to new heights.<br/> Welcome to the future of B2B operations.
            </h4>
          </div>
          <div className=" h-auto ml-5 md:ml-0 mr-1 md:mr-0 flex md:block overflow-x-scroll scrollbar-hide  md:w-full mt-6  md:mt-[100px] ">
            <div class="flex flex-nowrap gap-[20px] md:grid md:grid-cols-3 md:gap-x-[10px] md:place-items-center ">
              <div className="md:min-h-[400px] md:w-[400px] p-8 md:pb-10 md:p-0 w-[310px] min-h-[350px] rounded-lg bg-[#FFFFFF] md:rounded-[20px] md:mb-[20px]">
                <svg
                  className="ml-[44px] mt-[44px] hidden md:block"
                  width="80"
                  height="80"
                  viewBox="0 0 80 80"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="40" cy="40" r="40" fill="#D8F5F1" />
                  <g clip-path="url(#clip0_2869_66359)">
                    <path
                      d="M47.1807 34.3203L41.2707 40.1303C40.6331 39.8232 39.9076 39.7514 39.2222 39.9275C38.5368 40.1036 37.9358 40.5163 37.5252 41.0926C37.1147 41.669 36.9211 42.372 36.9787 43.0772C37.0363 43.7825 37.3413 44.4448 37.84 44.9469C38.3386 45.449 38.9986 45.7588 39.7035 45.8213C40.4083 45.8839 41.1126 45.6953 41.6919 45.2889C42.2712 44.8824 42.6881 44.2843 42.869 43.6002C43.05 42.916 42.9832 42.19 42.6807 41.5503L48.6007 35.7403L47.1807 34.3203Z"
                      fill="#0D0D0D"
                    />
                    <path
                      d="M40.0011 26.25C36.8538 26.2464 33.7712 27.1435 31.1174 28.8355C28.4636 30.5274 26.3494 32.9435 25.0245 35.7983C23.6996 38.6531 23.2194 41.8275 23.6405 44.9465C24.0616 48.0655 25.3665 50.9988 27.4011 53.4L27.7011 53.75H52.3011L52.6011 53.4C54.6357 50.9988 55.9406 48.0655 56.3617 44.9465C56.7828 41.8275 56.3026 38.6531 54.9777 35.7983C53.6528 32.9435 51.5386 30.5274 48.8848 28.8355C46.231 27.1435 43.1484 26.2464 40.0011 26.25ZM51.3411 51.75H28.6611C26.8668 49.5047 25.7838 46.7748 25.5511 43.91H29.0011V41.91H25.5511C25.7186 38.7347 26.9315 35.704 29.0011 33.29L31.4511 35.74L32.8611 34.33L30.4311 31.87C32.8144 29.7635 35.8276 28.5048 39.0011 28.29V31.79H41.0011V28.3C44.5066 28.5542 47.7997 30.0756 50.2655 32.5801C52.7314 35.0846 54.2014 38.401 54.4011 41.91H50.9211V43.91H54.4511C54.2183 46.7748 53.1354 49.5047 51.3411 51.75Z"
                      fill="#0D0D0D"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2869_66359">
                      <rect
                        width="36"
                        height="36"
                        fill="white"
                        transform="translate(22 22)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <svg
                  className="md:hidden"
                  width="44"
                  height="44"
                  viewBox="0 0 44 44"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="22" cy="22" r="22" fill="#D8F5F1" />
                  <g clip-path="url(#clip0_4693_91418)">
                    <path
                      d="M25.9486 18.8789L22.6981 22.0744C22.3474 21.9055 21.9484 21.866 21.5714 21.9628C21.1945 22.0597 20.8639 22.2867 20.6381 22.6037C20.4123 22.9207 20.3058 23.3073 20.3375 23.6952C20.3692 24.0831 20.537 24.4474 20.8112 24.7235C21.0854 24.9997 21.4485 25.1701 21.8361 25.2045C22.2238 25.2389 22.6112 25.1352 22.9298 24.9116C23.2484 24.6881 23.4777 24.3591 23.5772 23.9828C23.6767 23.6066 23.64 23.2072 23.4736 22.8554L26.7296 19.6599L25.9486 18.8789Z"
                      fill="#0D0D0D"
                    />
                    <path
                      d="M21.9998 14.4375C20.2688 14.4355 18.5734 14.929 17.1138 15.8595C15.6542 16.7901 14.4914 18.1189 13.7627 19.6891C13.034 21.2592 12.7699 23.0051 13.0015 24.7206C13.2331 26.436 13.9508 28.0493 15.0698 29.37L15.2348 29.5625H28.7648L28.9298 29.37C30.0488 28.0493 30.7665 26.436 30.9982 24.7206C31.2298 23.0051 30.9656 21.2592 30.2369 19.6891C29.5083 18.1189 28.3454 16.7901 26.8858 15.8595C25.4262 14.929 23.7308 14.4355 21.9998 14.4375ZM28.2368 28.4625H15.7628C14.7759 27.2276 14.1803 25.7261 14.0523 24.1505H15.9498V23.0505H14.0523C14.1444 21.3041 14.8116 19.6372 15.9498 18.3095L17.2973 19.657L18.0728 18.8815L16.7363 17.5285C18.0471 16.37 19.7044 15.6776 21.4498 15.5595V17.4845H22.5498V15.565C24.4778 15.7048 26.289 16.5416 27.6453 17.9191C29.0015 19.2965 29.81 21.1206 29.9198 23.0505H28.0058V24.1505H29.9473C29.8193 25.7261 29.2237 27.2276 28.2368 28.4625Z"
                      fill="#0D0D0D"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_4693_91418">
                      <rect
                        width="19.8"
                        height="19.8"
                        fill="white"
                        transform="translate(12.0996 12.1016)"
                      />
                    </clipPath>
                  </defs>
                </svg>

                <h2 className="md:text-[22px] mt-3 text-[18px] md:ml-[44px] md:mt-[8px] font-[700]">
                  Dashboard
                </h2>
                <h3 className="md:text-[18px] mt-4 text-left md:ml-[44px] text-[#747474D1] text-[16px] md:mt-[18px] md:w-[320px]">
                  Elevate Your Business with Dashboard's Leading Indicators: Drive Proactive Decisions, Boost Performance, and Stay Ahead of the Curve!
                </h3>
              </div>
              <div className="md:min-h-[400px] md:w-[400px] p-8 md:pb-10 md:p-0 w-[310px] min-h-[350px] rounded-lg  bg-[#FFFFFF] md:rounded-[20px] md:mb-[20px]">
                <div className="md:w-[80px] md:h-[80px] w-[44px] h-[44px]  rounded-[50%] bg-[#D8F5F1] flex justify-center items-center md:ml-[44px] md:mt-[44px]">
                  <svg
                    className="w-[20px] h-[20px] md:h-9 md:w-9"
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 24.75L4.5 29.16V16.5H9M16.5 21.99L14.145 19.98L12 21.96V10.5H16.5M24 19.5L19.5 24V4.5H24M28.215 19.215L25.5 16.5H33V24L30.315 21.315L19.5 32.04L14.295 27.51L8.625 33H4.5L14.205 23.49L19.5 27.96"
                      fill="#0D0D0D"
                    />
                  </svg>
                </div>

                <h2 className="md:text-[22px] text-[18px] mt-3 md:ml-[44px] md:mt-[8px] font-[700]">
                  Customer portal
                </h2>
                <h3 className="md:text-[18px] mt-4 text-left md:ml-[44px] text-[#747474D1] text-[16px] md:mt-[18px] md:w-[320px]">
                  External operations: A powerful tool that serves as a valuable tool for businesses to better serve their customers, optimize their operations, and ultimately drive growth and success.
                </h3>
              </div>
              <div className="md:min-h-[400px] md:w-[400px] p-8 md:pb-10 md:p-0 w-[310px] min-h-[350px] rounded-lg  bg-[#FFFFFF] md:rounded-[20px] md:mb-[20px]">
                <div className="md:w-[80px] md:h-[80px] w-[44px] h-[44px] rounded-[50%] bg-[#D8F5F1] flex justify-center items-center md:ml-[44px] md:mt-[44px]">
                  <svg
                    className="w-[18px] h-[18px] md:h-[26px] md:w-[26px]"
                    width="26"
                    height="26"
                    viewBox="0 0 26 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.85714 19.7457V6.14286M13 19.8314V13M18.1429 19.8571V9.57143M4.42857 1H21.5714C22.4807 1 23.3528 1.36122 23.9958 2.00421C24.6388 2.64719 25 3.51926 25 4.42857V21.5714C25 22.4807 24.6388 23.3528 23.9958 23.9958C23.3528 24.6388 22.4807 25 21.5714 25H4.42857C3.51926 25 2.64719 24.6388 2.00421 23.9958C1.36122 23.3528 1 22.4807 1 21.5714V4.42857C1 3.51926 1.36122 2.64719 2.00421 2.00421C2.64719 1.36122 3.51926 1 4.42857 1V1Z"
                      stroke="#0D0D0D"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <h2 className="md:text-[22px] mt-3 text-[18px] md:ml-[44px] md:mt-[8px] font-[700]">
                  Employee portal
                </h2>
                <h3 className="md:text-[18px] mt-4 text-left md:ml-[44px] text-[#747474D1] text-[16px] md:mt-[18px] md:w-[320px]">
                  Internal operations: A tool to enhance daily operations & employee productivity, a more organized & efficient work environment, ultimately leading to improved business outcomes.
                </h3>
              </div>
              <div className="md:min-h-[400px] md:w-[400px] p-8 md:pb-10 md:p-0 w-[310px] min-h-[350px] rounded-lg  bg-[#FFFFFF] md:rounded-[20px]">
                <div className="md:w-[80px] md:h-[80px] w-[44px] h-[44px] rounded-[50%] bg-[#D8F5F1] flex justify-center items-center md:ml-[44px] md:mt-[44px]">
                  <svg
                    className="w-[20px] h-[18px] md:h-[34px] md:w-[28px]"
                    width="34"
                    height="28"
                    viewBox="0 0 34 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5 0.5C3.80653 0.5 2.66193 0.974106 1.81802 1.81802C0.974106 2.66193 0.5 3.80653 0.5 5V23C0.5 24.1935 0.974106 25.3381 1.81802 26.182C2.66193 27.0259 3.80653 27.5 5 27.5H29C30.1935 27.5 31.3381 27.0259 32.182 26.182C33.0259 25.3381 33.5 24.1935 33.5 23V5C33.5 3.80653 33.0259 2.66193 32.182 1.81802C31.3381 0.974106 30.1935 0.5 29 0.5H5ZM29 3.5H5C4.60218 3.5 4.22064 3.65804 3.93934 3.93934C3.65804 4.22064 3.5 4.60218 3.5 5V23C3.5 23.3978 3.65804 23.7794 3.93934 24.0607C4.22064 24.342 4.60218 24.5 5 24.5H29C29.3978 24.5 29.7794 24.342 30.0607 24.0607C30.342 23.7794 30.5 23.3978 30.5 23V5C30.5 4.60218 30.342 4.22064 30.0607 3.93934C29.7794 3.65804 29.3978 3.5 29 3.5Z"
                      fill="#0D0D0D"
                    />
                  </svg>
                </div>
                <h2 className="md:text-[22px] mt-3 text-[18px] md:ml-[44px] md:mt-[8px] font-[700]">
                  B2B E-Commerce
                </h2>
                <h3 className="md:text-[18px] mt-4 text-left md:ml-[44px] text-[#747474D1] text-[16px] md:mt-[18px] md:w-[320px]">
                  Market your business: A no-code feature allows you to set up your personalized e-commerce site within minutes. Build your custom site, share pricing selectively, and expand your market reach effortlessly
                </h3>
              </div>
              <div className="md:min-h-[400px] md:w-[400px] p-8 md:pb-10 md:p-0 w-[310px] min-h-[350px] rounded-lg  bg-[#FFFFFF] md:rounded-[20px]">
                <div className="md:w-[80px] md:h-[80px] w-[44px] h-[44px] rounded-[50%] bg-[#D8F5F1] flex justify-center items-center md:ml-[44px] md:mt-[44px]">
                  <svg
                    className="w-[20px] h-[17px] md:h-[34px] md:w-[28px]"
                    width="36"
                    height="24"
                    viewBox="0 0 36 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 3C0 2.40326 0.237053 1.83097 0.65901 1.40901C1.08097 0.987053 1.65326 0.75 2.25 0.75H33.75C34.3467 0.75 34.919 0.987053 35.341 1.40901C35.7629 1.83097 36 2.40326 36 3V21C36 21.5967 35.7629 22.169 35.341 22.591C34.919 23.0129 34.3467 23.25 33.75 23.25H2.25C1.65326 23.25 1.08097 23.0129 0.65901 22.591C0.237053 22.169 0 21.5967 0 21V3ZM6.75 3C6.75 4.19347 6.27589 5.33807 5.43198 6.18198C4.58807 7.02589 3.44347 7.5 2.25 7.5V16.5C3.44347 16.5 4.58807 16.9741 5.43198 17.818C6.27589 18.6619 6.75 19.8065 6.75 21H29.25C29.25 19.8065 29.7241 18.6619 30.568 17.818C31.4119 16.9741 32.5565 16.5 33.75 16.5V7.5C32.5565 7.5 31.4119 7.02589 30.568 6.18198C29.7241 5.33807 29.25 4.19347 29.25 3H6.75Z"
                      fill="#0D0D0D"
                    />
                  </svg>
                </div>
                <h2 className="md:text-[22px] mt-3 text-[18px] md:ml-[44px] md:mt-[8px] font-[700]">
                  Pankh directory
                </h2>
                <h3 className="md:text-[18px] mt-4 text-left md:ml-[44px] text-[#747474D1] text-[16px] md:mt-[18px] md:w-[320px]">
                  Fueling B2B synergy: Pankh Directory fosters supply chain connections, expansion, and revenue growth.
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-[#FFFFFF] py-7 md:py-0 ">
          <div className="flex flex-col justify-center items-center px-5 md:px-0">
            <h2 className="md:text-[32px] text-[20px] font-[700] text-center mx-[20px] md:mx-0  text-[#615F5F] font-monterrat capitalize md:font-[800] md:mt-[60px]">
              Customer Portal
            </h2>
            <h3 className="text-[#747474B8] text-[16px] font-[600] text-center mt-[5px] capitalize md:text-[24px] md:font-[800] md:mt-[5px]">
              Empower your customers with a seamless hub, enabling seamless order placement, payment reminders, online payments, order tracking, and interactive experiences.
            </h3>
          </div>
          <div className="flex md:pb-[100px] pl-5 md:pl-0 md:mt-[80px] mt-[20px] ml-[20px] mr-[20px] overflow-x-auto scrollbar-hide">
          <div class="flex flex-nowrap gap-[20px]">

            <div className="bg-[#E3F2F5] p-8 md:p-0 rounded-lg md:w-[410px] md:min-h-[305px] w-[310px] min-h-[245px] md:rounded-[20px]">
              <div className="md:w-[80px] md:h-[80px] w-[44px] h-[44px]  bg-[#FFFFFF] rounded-[50%] flex justify-center items-center md:mt-[44px] md:ml-[44px]">
                <svg
                className="w-[14.5px] h-[15.3px] md:h-[32px] md:w-[29px]"
                  width="29"
                  height="32"
                  viewBox="0 0 29 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 17.5V8.5C22 8.10218 21.842 7.72064 21.5607 7.43934C21.2794 7.15804 20.8978 7 20.5 7H2.5C2.10218 7 1.72064 7.15804 1.43934 7.43934C1.15804 7.72064 1 8.10218 1 8.5V26.5C1 26.8978 1.15804 27.2794 1.43934 27.5607C1.72064 27.842 2.10218 28 2.5 28H15.25M7 7V28M16 7V19.75M16 7V2.5C16 2.10218 15.842 1.72064 15.5607 1.43934C15.2794 1.15804 14.8978 1 14.5 1H8.5C8.10218 1 7.72064 1.15804 7.43934 1.43934C7.15804 1.72064 7 2.10218 7 2.5V7"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21.25 31C22.1364 31 23.0142 30.8254 23.8331 30.4862C24.6521 30.147 25.3962 29.6498 26.023 29.023C26.6498 28.3962 27.147 27.6521 27.4862 26.8331C27.8254 26.0142 28 25.1364 28 24.25C28 23.3636 27.8254 22.4858 27.4862 21.6669C27.147 20.8479 26.6498 20.1038 26.023 19.477C25.3962 18.8502 24.6521 18.353 23.8331 18.0138C23.0142 17.6746 22.1364 17.5 21.25 17.5C19.4598 17.5 17.7429 18.2112 16.477 19.477C15.2112 20.7429 14.5 22.4598 14.5 24.25C14.5 26.0402 15.2112 27.7571 16.477 29.023C17.7429 30.2888 19.4598 31 21.25 31V31Z"
                    stroke="#0D0D0D"
                    stroke-width="2"
                  />
                  <path
                    d="M20.5 22V25H23.5"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.75 28V31"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              <h2 className="md:text-[22px] mt-3 md:mt-0 text-[#0D0D0D] font-[700] md:ml-[44px]">
                Customer Logins
              </h2>
              <h3 className="text-[#747474D6] mt-4 mb-4 md:mt-0 md:text-[18px] font-[600] md:ml-[44px] md:w-[320px]">
                Customers can access the portal using their accounts, providing a personalized experience.
              </h3>
            </div>
            <div className="bg-[#E3F2F5] p-8 md:p-0 rounded-lg md:w-[410px] md:min-h-[305px] w-[310px] min-h-[245px] md:rounded-[20px] ">
              <div className="md:w-[80px] md:h-[80px] w-[44px] h-[44px]  bg-[#FFFFFF] rounded-[50%] flex justify-center items-center md:mt-[44px] md:ml-[44px]">
                <svg
                className="w-[14.5px] h-[15.3px] md:h-[32px] md:w-[29px]"
                  width="29"
                  height="32"
                  viewBox="0 0 29 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 17.5V8.5C22 8.10218 21.842 7.72064 21.5607 7.43934C21.2794 7.15804 20.8978 7 20.5 7H2.5C2.10218 7 1.72064 7.15804 1.43934 7.43934C1.15804 7.72064 1 8.10218 1 8.5V26.5C1 26.8978 1.15804 27.2794 1.43934 27.5607C1.72064 27.842 2.10218 28 2.5 28H15.25M7 7V28M16 7V19.75M16 7V2.5C16 2.10218 15.842 1.72064 15.5607 1.43934C15.2794 1.15804 14.8978 1 14.5 1H8.5C8.10218 1 7.72064 1.15804 7.43934 1.43934C7.15804 1.72064 7 2.10218 7 2.5V7"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21.25 31C22.1364 31 23.0142 30.8254 23.8331 30.4862C24.6521 30.147 25.3962 29.6498 26.023 29.023C26.6498 28.3962 27.147 27.6521 27.4862 26.8331C27.8254 26.0142 28 25.1364 28 24.25C28 23.3636 27.8254 22.4858 27.4862 21.6669C27.147 20.8479 26.6498 20.1038 26.023 19.477C25.3962 18.8502 24.6521 18.353 23.8331 18.0138C23.0142 17.6746 22.1364 17.5 21.25 17.5C19.4598 17.5 17.7429 18.2112 16.477 19.477C15.2112 20.7429 14.5 22.4598 14.5 24.25C14.5 26.0402 15.2112 27.7571 16.477 29.023C17.7429 30.2888 19.4598 31 21.25 31V31Z"
                    stroke="#0D0D0D"
                    stroke-width="2"
                  />
                  <path
                    d="M20.5 22V25H23.5"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.75 28V31"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              <h2 className="md:text-[22px] mt-3 md:mt-0 text-[#0D0D0D] font-[700] md:ml-[44px]">
                Order Placement
              </h2>
              <h3 className="text-[#747474D6] mt-4 mb-4 md:mt-0 md:text-[18px] font-[600] md:ml-[44px] md:w-[320px]">
                Customers can easily place orders through the portal, streamlining the order process.
              </h3>
            </div>
            <div className="bg-[#E3F2F5] p-8 md:p-0 rounded-lg md:w-[410px] md:min-h-[305px] w-[310px] min-h-[245px] md:rounded-[20px] ">
              <div className="md:w-[80px] md:h-[80px] w-[44px] h-[44px]  bg-[#FFFFFF] rounded-[50%] flex justify-center items-center md:mt-[44px] md:ml-[44px]">
                <svg
                className="w-[14.5px] h-[15.3px] md:h-[32px] md:w-[29px]"
                  width="29"
                  height="32"
                  viewBox="0 0 29 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 17.5V8.5C22 8.10218 21.842 7.72064 21.5607 7.43934C21.2794 7.15804 20.8978 7 20.5 7H2.5C2.10218 7 1.72064 7.15804 1.43934 7.43934C1.15804 7.72064 1 8.10218 1 8.5V26.5C1 26.8978 1.15804 27.2794 1.43934 27.5607C1.72064 27.842 2.10218 28 2.5 28H15.25M7 7V28M16 7V19.75M16 7V2.5C16 2.10218 15.842 1.72064 15.5607 1.43934C15.2794 1.15804 14.8978 1 14.5 1H8.5C8.10218 1 7.72064 1.15804 7.43934 1.43934C7.15804 1.72064 7 2.10218 7 2.5V7"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21.25 31C22.1364 31 23.0142 30.8254 23.8331 30.4862C24.6521 30.147 25.3962 29.6498 26.023 29.023C26.6498 28.3962 27.147 27.6521 27.4862 26.8331C27.8254 26.0142 28 25.1364 28 24.25C28 23.3636 27.8254 22.4858 27.4862 21.6669C27.147 20.8479 26.6498 20.1038 26.023 19.477C25.3962 18.8502 24.6521 18.353 23.8331 18.0138C23.0142 17.6746 22.1364 17.5 21.25 17.5C19.4598 17.5 17.7429 18.2112 16.477 19.477C15.2112 20.7429 14.5 22.4598 14.5 24.25C14.5 26.0402 15.2112 27.7571 16.477 29.023C17.7429 30.2888 19.4598 31 21.25 31V31Z"
                    stroke="#0D0D0D"
                    stroke-width="2"
                  />
                  <path
                    d="M20.5 22V25H23.5"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.75 28V31"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              <h2 className="md:text-[22px] mt-3 md:mt-0 text-[#0D0D0D] font-[700] md:ml-[44px]">
                Delivery Setup and Tracking
              </h2>
              <h3 className="text-[#747474D6] mt-4 mb-4 md:mt-0 md:text-[18px] font-[600] md:ml-[44px] md:w-[320px]">
                You can set up packing lists and Customers track deliveries, ensuring a smooth and transparent order fulfillment process.
              </h3>
            </div>
            <div className="bg-[#E3F2F5] p-8 md:p-0 rounded-lg md:w-[410px] md:min-h-[305px] w-[310px] min-h-[245px] md:rounded-[20px] ">
              <div className="md:w-[80px] md:h-[80px] w-[44px] h-[44px]  bg-[#FFFFFF] rounded-[50%] flex justify-center items-center md:mt-[44px] md:ml-[44px]">
                <svg
                className="w-[14.5px] h-[15.3px] md:h-[32px] md:w-[29px]"
                  width="29"
                  height="32"
                  viewBox="0 0 29 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 17.5V8.5C22 8.10218 21.842 7.72064 21.5607 7.43934C21.2794 7.15804 20.8978 7 20.5 7H2.5C2.10218 7 1.72064 7.15804 1.43934 7.43934C1.15804 7.72064 1 8.10218 1 8.5V26.5C1 26.8978 1.15804 27.2794 1.43934 27.5607C1.72064 27.842 2.10218 28 2.5 28H15.25M7 7V28M16 7V19.75M16 7V2.5C16 2.10218 15.842 1.72064 15.5607 1.43934C15.2794 1.15804 14.8978 1 14.5 1H8.5C8.10218 1 7.72064 1.15804 7.43934 1.43934C7.15804 1.72064 7 2.10218 7 2.5V7"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21.25 31C22.1364 31 23.0142 30.8254 23.8331 30.4862C24.6521 30.147 25.3962 29.6498 26.023 29.023C26.6498 28.3962 27.147 27.6521 27.4862 26.8331C27.8254 26.0142 28 25.1364 28 24.25C28 23.3636 27.8254 22.4858 27.4862 21.6669C27.147 20.8479 26.6498 20.1038 26.023 19.477C25.3962 18.8502 24.6521 18.353 23.8331 18.0138C23.0142 17.6746 22.1364 17.5 21.25 17.5C19.4598 17.5 17.7429 18.2112 16.477 19.477C15.2112 20.7429 14.5 22.4598 14.5 24.25C14.5 26.0402 15.2112 27.7571 16.477 29.023C17.7429 30.2888 19.4598 31 21.25 31V31Z"
                    stroke="#0D0D0D"
                    stroke-width="2"
                  />
                  <path
                    d="M20.5 22V25H23.5"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.75 28V31"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              <h2 className="md:text-[22px] mt-3 md:mt-0 text-[#0D0D0D] font-[700] md:ml-[44px]">
                Invoice Creation
              </h2>
              <h3 className="text-[#747474D6] mt-4 mb-4 md:mt-0 md:text-[18px] font-[600] md:ml-[44px] md:w-[320px]">
                The portal allows for the creation of invoices, simplifying billing and accounting.
              </h3>
            </div>
            <div className="bg-[#E3F2F5] p-8 md:p-0 rounded-lg md:w-[410px] md:min-h-[305px] w-[310px] min-h-[245px] md:rounded-[20px] ">
              <div className="md:w-[80px] md:h-[80px] w-[44px] h-[44px]  bg-[#FFFFFF] rounded-[50%] flex justify-center items-center md:mt-[44px] md:ml-[44px]">
                <svg
                className="w-[14.5px] h-[15.3px] md:h-[32px] md:w-[29px]"
                  width="29"
                  height="32"
                  viewBox="0 0 29 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 17.5V8.5C22 8.10218 21.842 7.72064 21.5607 7.43934C21.2794 7.15804 20.8978 7 20.5 7H2.5C2.10218 7 1.72064 7.15804 1.43934 7.43934C1.15804 7.72064 1 8.10218 1 8.5V26.5C1 26.8978 1.15804 27.2794 1.43934 27.5607C1.72064 27.842 2.10218 28 2.5 28H15.25M7 7V28M16 7V19.75M16 7V2.5C16 2.10218 15.842 1.72064 15.5607 1.43934C15.2794 1.15804 14.8978 1 14.5 1H8.5C8.10218 1 7.72064 1.15804 7.43934 1.43934C7.15804 1.72064 7 2.10218 7 2.5V7"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21.25 31C22.1364 31 23.0142 30.8254 23.8331 30.4862C24.6521 30.147 25.3962 29.6498 26.023 29.023C26.6498 28.3962 27.147 27.6521 27.4862 26.8331C27.8254 26.0142 28 25.1364 28 24.25C28 23.3636 27.8254 22.4858 27.4862 21.6669C27.147 20.8479 26.6498 20.1038 26.023 19.477C25.3962 18.8502 24.6521 18.353 23.8331 18.0138C23.0142 17.6746 22.1364 17.5 21.25 17.5C19.4598 17.5 17.7429 18.2112 16.477 19.477C15.2112 20.7429 14.5 22.4598 14.5 24.25C14.5 26.0402 15.2112 27.7571 16.477 29.023C17.7429 30.2888 19.4598 31 21.25 31V31Z"
                    stroke="#0D0D0D"
                    stroke-width="2"
                  />
                  <path
                    d="M20.5 22V25H23.5"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.75 28V31"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              <h2 className="md:text-[22px] mt-3 md:mt-0 text-[#0D0D0D] font-[700] md:ml-[44px]">
                Credit Payment Reminders
              </h2>
              <h3 className="text-[#747474D6] mt-4 mb-4 md:mt-0 md:text-[18px] font-[600] md:ml-[44px] md:w-[320px]">
                Customers receive reminders for credit payments, helping maintain healthy financial relationships.
              </h3>
            </div>
            <div className="bg-[#E3F2F5] p-8 md:p-0 rounded-lg md:w-[410px] md:min-h-[305px] w-[310px] min-h-[245px] md:rounded-[20px] ">
              <div className="md:w-[80px] md:h-[80px] w-[44px] h-[44px]  bg-[#FFFFFF] rounded-[50%] flex justify-center items-center md:mt-[44px] md:ml-[44px]">
                <svg
                className="w-[14.5px] h-[15.3px] md:h-[32px] md:w-[29px]"
                  width="29"
                  height="32"
                  viewBox="0 0 29 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 17.5V8.5C22 8.10218 21.842 7.72064 21.5607 7.43934C21.2794 7.15804 20.8978 7 20.5 7H2.5C2.10218 7 1.72064 7.15804 1.43934 7.43934C1.15804 7.72064 1 8.10218 1 8.5V26.5C1 26.8978 1.15804 27.2794 1.43934 27.5607C1.72064 27.842 2.10218 28 2.5 28H15.25M7 7V28M16 7V19.75M16 7V2.5C16 2.10218 15.842 1.72064 15.5607 1.43934C15.2794 1.15804 14.8978 1 14.5 1H8.5C8.10218 1 7.72064 1.15804 7.43934 1.43934C7.15804 1.72064 7 2.10218 7 2.5V7"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21.25 31C22.1364 31 23.0142 30.8254 23.8331 30.4862C24.6521 30.147 25.3962 29.6498 26.023 29.023C26.6498 28.3962 27.147 27.6521 27.4862 26.8331C27.8254 26.0142 28 25.1364 28 24.25C28 23.3636 27.8254 22.4858 27.4862 21.6669C27.147 20.8479 26.6498 20.1038 26.023 19.477C25.3962 18.8502 24.6521 18.353 23.8331 18.0138C23.0142 17.6746 22.1364 17.5 21.25 17.5C19.4598 17.5 17.7429 18.2112 16.477 19.477C15.2112 20.7429 14.5 22.4598 14.5 24.25C14.5 26.0402 15.2112 27.7571 16.477 29.023C17.7429 30.2888 19.4598 31 21.25 31V31Z"
                    stroke="#0D0D0D"
                    stroke-width="2"
                  />
                  <path
                    d="M20.5 22V25H23.5"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.75 28V31"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              <h2 className="md:text-[22px] mt-3 md:mt-0 text-[#0D0D0D] font-[700] md:ml-[44px]">
                Online Payment Acceptance
              </h2>
              <h3 className="text-[#747474D6] mt-4 mb-4 md:mt-0 md:text-[18px] font-[600] md:ml-[44px] md:w-[320px]">
                Customers can make payments conveniently online, improving cash flow for the business.
              </h3>
            </div>
            <div className="bg-[#E3F2F5] p-8 md:p-0 rounded-lg md:w-[410px] md:min-h-[305px] w-[310px] min-h-[245px] md:rounded-[20px] ">
              <div className="md:w-[80px] md:h-[80px] w-[44px] h-[44px]  bg-[#FFFFFF] rounded-[50%] flex justify-center items-center md:mt-[44px] md:ml-[44px]">
                <svg
                className="w-[14.5px] h-[15.3px] md:h-[32px] md:w-[29px]"
                  width="29"
                  height="32"
                  viewBox="0 0 29 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 17.5V8.5C22 8.10218 21.842 7.72064 21.5607 7.43934C21.2794 7.15804 20.8978 7 20.5 7H2.5C2.10218 7 1.72064 7.15804 1.43934 7.43934C1.15804 7.72064 1 8.10218 1 8.5V26.5C1 26.8978 1.15804 27.2794 1.43934 27.5607C1.72064 27.842 2.10218 28 2.5 28H15.25M7 7V28M16 7V19.75M16 7V2.5C16 2.10218 15.842 1.72064 15.5607 1.43934C15.2794 1.15804 14.8978 1 14.5 1H8.5C8.10218 1 7.72064 1.15804 7.43934 1.43934C7.15804 1.72064 7 2.10218 7 2.5V7"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21.25 31C22.1364 31 23.0142 30.8254 23.8331 30.4862C24.6521 30.147 25.3962 29.6498 26.023 29.023C26.6498 28.3962 27.147 27.6521 27.4862 26.8331C27.8254 26.0142 28 25.1364 28 24.25C28 23.3636 27.8254 22.4858 27.4862 21.6669C27.147 20.8479 26.6498 20.1038 26.023 19.477C25.3962 18.8502 24.6521 18.353 23.8331 18.0138C23.0142 17.6746 22.1364 17.5 21.25 17.5C19.4598 17.5 17.7429 18.2112 16.477 19.477C15.2112 20.7429 14.5 22.4598 14.5 24.25C14.5 26.0402 15.2112 27.7571 16.477 29.023C17.7429 30.2888 19.4598 31 21.25 31V31Z"
                    stroke="#0D0D0D"
                    stroke-width="2"
                  />
                  <path
                    d="M20.5 22V25H23.5"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.75 28V31"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              <h2 className="md:text-[22px] mt-3 md:mt-0 text-[#0D0D0D] font-[700] md:ml-[44px]">
                Efficient Sales Order Processes
              </h2>
              <h3 className="text-[#747474D6] mt-4 mb-4 md:mt-0 md:text-[18px] font-[600] md:ml-[44px] md:w-[320px]">
                From order confirmation to payment, the portal streamlines the entire sales process, improving efficiency.
              </h3>
            </div>
            <div className="bg-[#E3F2F5] p-8 md:p-0 rounded-lg md:w-[410px] md:min-h-[305px] w-[310px] min-h-[245px] md:rounded-[20px] ">
              <div className="md:w-[80px] md:h-[80px] w-[44px] h-[44px]  bg-[#FFFFFF] rounded-[50%] flex justify-center items-center md:mt-[44px] md:ml-[44px]">
                <svg
                className="w-[14.5px] h-[15.3px] md:h-[32px] md:w-[29px]"
                  width="29"
                  height="32"
                  viewBox="0 0 29 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 17.5V8.5C22 8.10218 21.842 7.72064 21.5607 7.43934C21.2794 7.15804 20.8978 7 20.5 7H2.5C2.10218 7 1.72064 7.15804 1.43934 7.43934C1.15804 7.72064 1 8.10218 1 8.5V26.5C1 26.8978 1.15804 27.2794 1.43934 27.5607C1.72064 27.842 2.10218 28 2.5 28H15.25M7 7V28M16 7V19.75M16 7V2.5C16 2.10218 15.842 1.72064 15.5607 1.43934C15.2794 1.15804 14.8978 1 14.5 1H8.5C8.10218 1 7.72064 1.15804 7.43934 1.43934C7.15804 1.72064 7 2.10218 7 2.5V7"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21.25 31C22.1364 31 23.0142 30.8254 23.8331 30.4862C24.6521 30.147 25.3962 29.6498 26.023 29.023C26.6498 28.3962 27.147 27.6521 27.4862 26.8331C27.8254 26.0142 28 25.1364 28 24.25C28 23.3636 27.8254 22.4858 27.4862 21.6669C27.147 20.8479 26.6498 20.1038 26.023 19.477C25.3962 18.8502 24.6521 18.353 23.8331 18.0138C23.0142 17.6746 22.1364 17.5 21.25 17.5C19.4598 17.5 17.7429 18.2112 16.477 19.477C15.2112 20.7429 14.5 22.4598 14.5 24.25C14.5 26.0402 15.2112 27.7571 16.477 29.023C17.7429 30.2888 19.4598 31 21.25 31V31Z"
                    stroke="#0D0D0D"
                    stroke-width="2"
                  />
                  <path
                    d="M20.5 22V25H23.5"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.75 28V31"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              <h2 className="md:text-[22px] mt-3 md:mt-0 text-[#0D0D0D] font-[700] md:ml-[44px]">
                Two-way sync with your accounts
              </h2>
              <h3 className="text-[#747474D6] mt-4 mb-4 md:mt-0 md:text-[18px] font-[600] md:ml-[44px] md:w-[320px]">
                Effortlessly sync with accounts with real-time updates, ensuring streamlined operations and enhanced control.
              </h3>
            </div>
            
            </div>
          </div>
        </div>
        <div className="bg-[#062E4E] py-7 md:py-0  md:pb-4">
          <div className="flex flex-col justify-center items-center px-5 md:px-0">
            <h2 className="md:text-[32px] text-[20px] font-[700] text-center mx-[20px] md:mx-0  text-[#FFFFFF] font-monterrat capitalize md:font-[800] md:mt-[60px]">
              Employee Portal
            </h2>
            <h3 className="text-[#FFFFFFD1] text-[16px] font-[600] text-center mt-[5px] capitalize md:text-[24px] md:font-[800] md:mt-[5px]">
            A Digital Workspace & centralized hub - that empowers employees with tools, and information for streamlining daily tasks.
            </h3>
          </div>
          <div className="flex ml-[20px] mr-[20px] md:mb-[100px] pl-5 md:pl-0 md:mt-[80px] mt-[20px] overflow-x-scroll scrollbar-hide">
          <div class="flex flex-nowrap gap-[20px]">

            <div className="bg-[#FFFFFF] p-8 md:p-0 rounded-lg md:w-[410px] md:h-[305px] w-[310px] h-[245px] md:rounded-[20px] ">
              <div className="md:w-[80px] md:h-[80px] w-[44px] h-[44px]  bg-[#D8F5F1] rounded-[50%] flex justify-center items-center md:mt-[44px] md:ml-[44px]">
                <svg
                className="w-[14.5px] h-[15.3px] md:h-[32px] md:w-[29px]"
                  width="29"
                  height="32"
                  viewBox="0 0 29 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 17.5V8.5C22 8.10218 21.842 7.72064 21.5607 7.43934C21.2794 7.15804 20.8978 7 20.5 7H2.5C2.10218 7 1.72064 7.15804 1.43934 7.43934C1.15804 7.72064 1 8.10218 1 8.5V26.5C1 26.8978 1.15804 27.2794 1.43934 27.5607C1.72064 27.842 2.10218 28 2.5 28H15.25M7 7V28M16 7V19.75M16 7V2.5C16 2.10218 15.842 1.72064 15.5607 1.43934C15.2794 1.15804 14.8978 1 14.5 1H8.5C8.10218 1 7.72064 1.15804 7.43934 1.43934C7.15804 1.72064 7 2.10218 7 2.5V7"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21.25 31C22.1364 31 23.0142 30.8254 23.8331 30.4862C24.6521 30.147 25.3962 29.6498 26.023 29.023C26.6498 28.3962 27.147 27.6521 27.4862 26.8331C27.8254 26.0142 28 25.1364 28 24.25C28 23.3636 27.8254 22.4858 27.4862 21.6669C27.147 20.8479 26.6498 20.1038 26.023 19.477C25.3962 18.8502 24.6521 18.353 23.8331 18.0138C23.0142 17.6746 22.1364 17.5 21.25 17.5C19.4598 17.5 17.7429 18.2112 16.477 19.477C15.2112 20.7429 14.5 22.4598 14.5 24.25C14.5 26.0402 15.2112 27.7571 16.477 29.023C17.7429 30.2888 19.4598 31 21.25 31V31Z"
                    stroke="#0D0D0D"
                    stroke-width="2"
                  />
                  <path
                    d="M20.5 22V25H23.5"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.75 28V31"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              <h2 className="md:text-[22px] mt-3 md:mt-0 text-[#0D0D0D] font-[700] md:ml-[44px]">
                Revolutionizing access
              </h2>
              <h3 className="text-[#747474D6] mt-4 md:mt-0 md:text-[18px] font-[600] md:ml-[44px] md:w-[320px]">
                Empowering all employees with seamless login to your business in driving daily business activities. 
              </h3>
            </div>
            <div className="bg-[#FFFFFF] p-8 md:p-0 rounded-lg md:w-[410px] md:h-[305px] w-[310px] h-[245px] md:rounded-[20px] ">
              <div className="md:w-[80px] md:h-[80px] w-[44px] h-[44px]  bg-[#D8F5F1] rounded-[50%] flex justify-center items-center md:mt-[44px] md:ml-[44px]">
                <svg
                                className="w-[14.5px] h-[15.3px] md:h-[32px] md:w-[29px]"
                  width="29"
                  height="32"
                  viewBox="0 0 29 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 17.5V8.5C22 8.10218 21.842 7.72064 21.5607 7.43934C21.2794 7.15804 20.8978 7 20.5 7H2.5C2.10218 7 1.72064 7.15804 1.43934 7.43934C1.15804 7.72064 1 8.10218 1 8.5V26.5C1 26.8978 1.15804 27.2794 1.43934 27.5607C1.72064 27.842 2.10218 28 2.5 28H15.25M7 7V28M16 7V19.75M16 7V2.5C16 2.10218 15.842 1.72064 15.5607 1.43934C15.2794 1.15804 14.8978 1 14.5 1H8.5C8.10218 1 7.72064 1.15804 7.43934 1.43934C7.15804 1.72064 7 2.10218 7 2.5V7"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21.25 31C22.1364 31 23.0142 30.8254 23.8331 30.4862C24.6521 30.147 25.3962 29.6498 26.023 29.023C26.6498 28.3962 27.147 27.6521 27.4862 26.8331C27.8254 26.0142 28 25.1364 28 24.25C28 23.3636 27.8254 22.4858 27.4862 21.6669C27.147 20.8479 26.6498 20.1038 26.023 19.477C25.3962 18.8502 24.6521 18.353 23.8331 18.0138C23.0142 17.6746 22.1364 17.5 21.25 17.5C19.4598 17.5 17.7429 18.2112 16.477 19.477C15.2112 20.7429 14.5 22.4598 14.5 24.25C14.5 26.0402 15.2112 27.7571 16.477 29.023C17.7429 30.2888 19.4598 31 21.25 31V31Z"
                    stroke="#0D0D0D"
                    stroke-width="2"
                  />
                  <path
                    d="M20.5 22V25H23.5"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.75 28V31"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              <h2 className="md:text-[22px] mt-3 md:mt-0 text-[#0D0D0D] font-[700] md:ml-[44px]">
                Enhanced Productivity
              </h2>
              <h3 className="text-[#747474D6] mt-4 md:mt-0 md:text-[18px] font-[600] md:ml-[44px] md:w-[320px]">
                Enhanced sales and purchase operations, tailored custom, task management, streamlined tracking, and reporting.
              </h3>
            </div>
            <div className="bg-[#FFFFFF] p-8 md:p-0 rounded-lg md:w-[410px] md:h-[305px] w-[310px] h-[245px] md:rounded-[20px] ">
              <div className="md:w-[80px] md:h-[80px] w-[44px] h-[44px]  bg-[#D8F5F1] rounded-[50%] flex justify-center items-center md:mt-[44px] md:ml-[44px]">
                <svg
                                className="w-[14.5px] h-[15.3px] md:h-[32px] md:w-[29px]"

                  width="29"
                  height="32"
                  viewBox="0 0 29 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 17.5V8.5C22 8.10218 21.842 7.72064 21.5607 7.43934C21.2794 7.15804 20.8978 7 20.5 7H2.5C2.10218 7 1.72064 7.15804 1.43934 7.43934C1.15804 7.72064 1 8.10218 1 8.5V26.5C1 26.8978 1.15804 27.2794 1.43934 27.5607C1.72064 27.842 2.10218 28 2.5 28H15.25M7 7V28M16 7V19.75M16 7V2.5C16 2.10218 15.842 1.72064 15.5607 1.43934C15.2794 1.15804 14.8978 1 14.5 1H8.5C8.10218 1 7.72064 1.15804 7.43934 1.43934C7.15804 1.72064 7 2.10218 7 2.5V7"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21.25 31C22.1364 31 23.0142 30.8254 23.8331 30.4862C24.6521 30.147 25.3962 29.6498 26.023 29.023C26.6498 28.3962 27.147 27.6521 27.4862 26.8331C27.8254 26.0142 28 25.1364 28 24.25C28 23.3636 27.8254 22.4858 27.4862 21.6669C27.147 20.8479 26.6498 20.1038 26.023 19.477C25.3962 18.8502 24.6521 18.353 23.8331 18.0138C23.0142 17.6746 22.1364 17.5 21.25 17.5C19.4598 17.5 17.7429 18.2112 16.477 19.477C15.2112 20.7429 14.5 22.4598 14.5 24.25C14.5 26.0402 15.2112 27.7571 16.477 29.023C17.7429 30.2888 19.4598 31 21.25 31V31Z"
                    stroke="#0D0D0D"
                    stroke-width="2"
                  />
                  <path
                    d="M20.5 22V25H23.5"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.75 28V31"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              <h2 className="md:text-[22px] mt-3 md:mt-0 text-[#0D0D0D] font-[700] md:ml-[44px]">
                Optimal Routes
              </h2>
              <h3 className="text-[#747474D6] mt-4 md:mt-0 md:text-[18px] font-[600] md:ml-[44px] md:w-[320px]">
                Optimize sales and payment collection with our GPS-driven routing, featuring convenient check-ins, secure OTPs, and more.
              </h3>
            </div>
            <div className="bg-[#FFFFFF] p-8 md:p-0 rounded-lg md:w-[410px] md:h-[305px] w-[310px] h-[245px] md:rounded-[20px] ">
              <div className="md:w-[80px] md:h-[80px] w-[44px] h-[44px]  bg-[#D8F5F1] rounded-[50%] flex justify-center items-center md:mt-[44px] md:ml-[44px]">
                <svg
                                className="w-[14.5px] h-[15.3px] md:h-[32px] md:w-[29px]"

                  width="29"
                  height="32"
                  viewBox="0 0 29 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 17.5V8.5C22 8.10218 21.842 7.72064 21.5607 7.43934C21.2794 7.15804 20.8978 7 20.5 7H2.5C2.10218 7 1.72064 7.15804 1.43934 7.43934C1.15804 7.72064 1 8.10218 1 8.5V26.5C1 26.8978 1.15804 27.2794 1.43934 27.5607C1.72064 27.842 2.10218 28 2.5 28H15.25M7 7V28M16 7V19.75M16 7V2.5C16 2.10218 15.842 1.72064 15.5607 1.43934C15.2794 1.15804 14.8978 1 14.5 1H8.5C8.10218 1 7.72064 1.15804 7.43934 1.43934C7.15804 1.72064 7 2.10218 7 2.5V7"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21.25 31C22.1364 31 23.0142 30.8254 23.8331 30.4862C24.6521 30.147 25.3962 29.6498 26.023 29.023C26.6498 28.3962 27.147 27.6521 27.4862 26.8331C27.8254 26.0142 28 25.1364 28 24.25C28 23.3636 27.8254 22.4858 27.4862 21.6669C27.147 20.8479 26.6498 20.1038 26.023 19.477C25.3962 18.8502 24.6521 18.353 23.8331 18.0138C23.0142 17.6746 22.1364 17.5 21.25 17.5C19.4598 17.5 17.7429 18.2112 16.477 19.477C15.2112 20.7429 14.5 22.4598 14.5 24.25C14.5 26.0402 15.2112 27.7571 16.477 29.023C17.7429 30.2888 19.4598 31 21.25 31V31Z"
                    stroke="#0D0D0D"
                    stroke-width="2"
                  />
                  <path
                    d="M20.5 22V25H23.5"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.75 28V31"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              <h2 className="md:text-[22px] mt-3 md:mt-0 text-[#0D0D0D] font-[700] md:ml-[44px]">
                Improved Attendance Tracking
              </h2>
              <h3 className="text-[#747474D6] mt-4 md:mt-0 md:text-[18px] font-[600] md:ml-[44px] md:w-[320px]">
                Facilitates monitoring and management of employee attendance.
              </h3>
            </div>
            <div className="bg-[#FFFFFF] p-8 md:p-0 rounded-lg md:w-[410px] md:h-[305px] w-[310px] h-[245px] md:rounded-[20px] ">
              <div className="md:w-[80px] md:h-[80px] w-[44px] h-[44px]  bg-[#D8F5F1] rounded-[50%] flex justify-center items-center md:mt-[44px] md:ml-[44px]">
                <svg
                                className="w-[14.5px] h-[15.3px] md:h-[32px] md:w-[29px]"

                  width="29"
                  height="32"
                  viewBox="0 0 29 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 17.5V8.5C22 8.10218 21.842 7.72064 21.5607 7.43934C21.2794 7.15804 20.8978 7 20.5 7H2.5C2.10218 7 1.72064 7.15804 1.43934 7.43934C1.15804 7.72064 1 8.10218 1 8.5V26.5C1 26.8978 1.15804 27.2794 1.43934 27.5607C1.72064 27.842 2.10218 28 2.5 28H15.25M7 7V28M16 7V19.75M16 7V2.5C16 2.10218 15.842 1.72064 15.5607 1.43934C15.2794 1.15804 14.8978 1 14.5 1H8.5C8.10218 1 7.72064 1.15804 7.43934 1.43934C7.15804 1.72064 7 2.10218 7 2.5V7"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21.25 31C22.1364 31 23.0142 30.8254 23.8331 30.4862C24.6521 30.147 25.3962 29.6498 26.023 29.023C26.6498 28.3962 27.147 27.6521 27.4862 26.8331C27.8254 26.0142 28 25.1364 28 24.25C28 23.3636 27.8254 22.4858 27.4862 21.6669C27.147 20.8479 26.6498 20.1038 26.023 19.477C25.3962 18.8502 24.6521 18.353 23.8331 18.0138C23.0142 17.6746 22.1364 17.5 21.25 17.5C19.4598 17.5 17.7429 18.2112 16.477 19.477C15.2112 20.7429 14.5 22.4598 14.5 24.25C14.5 26.0402 15.2112 27.7571 16.477 29.023C17.7429 30.2888 19.4598 31 21.25 31V31Z"
                    stroke="#0D0D0D"
                    stroke-width="2"
                  />
                  <path
                    d="M20.5 22V25H23.5"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.75 28V31"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              <h2 className="md:text-[22px] mt-3 md:mt-0 text-[#0D0D0D] font-[700] md:ml-[44px]">
                Seamless Deliveries
              </h2>
              <h3 className="text-[#747474D6] mt-4 md:mt-0 md:text-[18px] font-[600] md:ml-[44px] md:w-[320px]">
                Simplifies picking & packing for smoother delivery processes.
              </h3>
            </div>
            <div className="bg-[#FFFFFF] p-8 md:p-0 rounded-lg md:w-[410px] md:h-[305px] w-[310px] h-[245px] md:rounded-[20px] ">
              <div className="md:w-[80px] md:h-[80px] w-[44px] h-[44px]  bg-[#D8F5F1] rounded-[50%] flex justify-center items-center md:mt-[44px] md:ml-[44px]">
                <svg
                                className="w-[14.5px] h-[15.3px] md:h-[32px] md:w-[29px]"

                  width="29"
                  height="32"
                  viewBox="0 0 29 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 17.5V8.5C22 8.10218 21.842 7.72064 21.5607 7.43934C21.2794 7.15804 20.8978 7 20.5 7H2.5C2.10218 7 1.72064 7.15804 1.43934 7.43934C1.15804 7.72064 1 8.10218 1 8.5V26.5C1 26.8978 1.15804 27.2794 1.43934 27.5607C1.72064 27.842 2.10218 28 2.5 28H15.25M7 7V28M16 7V19.75M16 7V2.5C16 2.10218 15.842 1.72064 15.5607 1.43934C15.2794 1.15804 14.8978 1 14.5 1H8.5C8.10218 1 7.72064 1.15804 7.43934 1.43934C7.15804 1.72064 7 2.10218 7 2.5V7"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21.25 31C22.1364 31 23.0142 30.8254 23.8331 30.4862C24.6521 30.147 25.3962 29.6498 26.023 29.023C26.6498 28.3962 27.147 27.6521 27.4862 26.8331C27.8254 26.0142 28 25.1364 28 24.25C28 23.3636 27.8254 22.4858 27.4862 21.6669C27.147 20.8479 26.6498 20.1038 26.023 19.477C25.3962 18.8502 24.6521 18.353 23.8331 18.0138C23.0142 17.6746 22.1364 17.5 21.25 17.5C19.4598 17.5 17.7429 18.2112 16.477 19.477C15.2112 20.7429 14.5 22.4598 14.5 24.25C14.5 26.0402 15.2112 27.7571 16.477 29.023C17.7429 30.2888 19.4598 31 21.25 31V31Z"
                    stroke="#0D0D0D"
                    stroke-width="2"
                  />
                  <path
                    d="M20.5 22V25H23.5"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.75 28V31"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              <h2 className="md:text-[22px] mt-3 md:mt-0 text-[#0D0D0D] font-[700] md:ml-[44px]">
                Enhanced Communication
              </h2>
              <h3 className="text-[#747474D6] mt-4 md:mt-0 md:text-[18px] font-[600] md:ml-[44px] md:w-[320px]">
                Facilitates easier interaction and collaboration for day-to-day tasks.
              </h3>
            </div>
            <div className="bg-[#FFFFFF] p-8 md:p-0 rounded-lg md:w-[410px] md:h-[305px] w-[310px] h-[245px] md:rounded-[20px] ">
              <div className="md:w-[80px] md:h-[80px] w-[44px] h-[44px]  bg-[#D8F5F1] rounded-[50%] flex justify-center items-center md:mt-[44px] md:ml-[44px]">
                <svg
                                className="w-[14.5px] h-[15.3px] md:h-[32px] md:w-[29px]"

                  width="29"
                  height="32"
                  viewBox="0 0 29 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 17.5V8.5C22 8.10218 21.842 7.72064 21.5607 7.43934C21.2794 7.15804 20.8978 7 20.5 7H2.5C2.10218 7 1.72064 7.15804 1.43934 7.43934C1.15804 7.72064 1 8.10218 1 8.5V26.5C1 26.8978 1.15804 27.2794 1.43934 27.5607C1.72064 27.842 2.10218 28 2.5 28H15.25M7 7V28M16 7V19.75M16 7V2.5C16 2.10218 15.842 1.72064 15.5607 1.43934C15.2794 1.15804 14.8978 1 14.5 1H8.5C8.10218 1 7.72064 1.15804 7.43934 1.43934C7.15804 1.72064 7 2.10218 7 2.5V7"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21.25 31C22.1364 31 23.0142 30.8254 23.8331 30.4862C24.6521 30.147 25.3962 29.6498 26.023 29.023C26.6498 28.3962 27.147 27.6521 27.4862 26.8331C27.8254 26.0142 28 25.1364 28 24.25C28 23.3636 27.8254 22.4858 27.4862 21.6669C27.147 20.8479 26.6498 20.1038 26.023 19.477C25.3962 18.8502 24.6521 18.353 23.8331 18.0138C23.0142 17.6746 22.1364 17.5 21.25 17.5C19.4598 17.5 17.7429 18.2112 16.477 19.477C15.2112 20.7429 14.5 22.4598 14.5 24.25C14.5 26.0402 15.2112 27.7571 16.477 29.023C17.7429 30.2888 19.4598 31 21.25 31V31Z"
                    stroke="#0D0D0D"
                    stroke-width="2"
                  />
                  <path
                    d="M20.5 22V25H23.5"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.75 28V31"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              <h2 className="md:text-[22px] mt-3 md:mt-0 text-[#0D0D0D] font-[700] md:ml-[44px]">
                Two-way sync with your accounts
              </h2>
              <h3 className="text-[#747474D6] mt-4 md:mt-0 md:text-[18px] font-[600] md:ml-[44px] md:w-[320px]">
                Effortlessly sync with accounts with real-time updates, ensuring streamlined operations and enhanced control.
              </h3>
            </div>
            </div>
          </div>
        </div>
       
        <div className="bg-[#FFFFFF] py-7 md:py-0">
        <div className="flex flex-col justify-center items-center px-5 md:px-0">
            <h2 className="md:text-[32px] text-[20px] font-[700] text-center mx-[20px] md:mx-0  text-[#615F5F] font-monterrat capitalize md:font-[800] md:mt-[60px]">
            Private B2B Customizable E-Commerce 
            </h2>
            <h3 className="text-[#747474B8] text-[16px] font-[600] text-center mt-[5px] capitalize md:text-[24px] md:font-[800] md:mt-[5px]">
              B2B E-Commerce Made Easy: Build your custom site, share pricing selectively, and expand your market reach effortlessly
            </h3>
          </div>
          <div className="flex mr-[20px] ml-[20px] md:pb-[100px] pl-5 md:pl-0 md:mt-[80px] mt-[20px] overflow-x-scroll scrollbar-hide">
          <div class="flex flex-nowrap gap-[20px]">

            <div className="bg-[#E3F2F5] p-8 md:p-0 rounded-lg md:w-[410px] md:h-[305px] w-[310px] h-[245px] md:rounded-[20px] ">
              <div className="md:w-[80px] md:h-[80px] w-[44px] h-[44px]  bg-[#FFFFFF] rounded-[50%] flex justify-center items-center md:mt-[44px] md:ml-[44px]">
                <svg
                className="w-[14.5px] h-[15.3px] md:h-[32px] md:w-[29px]"
                  width="29"
                  height="32"
                  viewBox="0 0 29 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 17.5V8.5C22 8.10218 21.842 7.72064 21.5607 7.43934C21.2794 7.15804 20.8978 7 20.5 7H2.5C2.10218 7 1.72064 7.15804 1.43934 7.43934C1.15804 7.72064 1 8.10218 1 8.5V26.5C1 26.8978 1.15804 27.2794 1.43934 27.5607C1.72064 27.842 2.10218 28 2.5 28H15.25M7 7V28M16 7V19.75M16 7V2.5C16 2.10218 15.842 1.72064 15.5607 1.43934C15.2794 1.15804 14.8978 1 14.5 1H8.5C8.10218 1 7.72064 1.15804 7.43934 1.43934C7.15804 1.72064 7 2.10218 7 2.5V7"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21.25 31C22.1364 31 23.0142 30.8254 23.8331 30.4862C24.6521 30.147 25.3962 29.6498 26.023 29.023C26.6498 28.3962 27.147 27.6521 27.4862 26.8331C27.8254 26.0142 28 25.1364 28 24.25C28 23.3636 27.8254 22.4858 27.4862 21.6669C27.147 20.8479 26.6498 20.1038 26.023 19.477C25.3962 18.8502 24.6521 18.353 23.8331 18.0138C23.0142 17.6746 22.1364 17.5 21.25 17.5C19.4598 17.5 17.7429 18.2112 16.477 19.477C15.2112 20.7429 14.5 22.4598 14.5 24.25C14.5 26.0402 15.2112 27.7571 16.477 29.023C17.7429 30.2888 19.4598 31 21.25 31V31Z"
                    stroke="#0D0D0D"
                    stroke-width="2"
                  />
                  <path
                    d="M20.5 22V25H23.5"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.75 28V31"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              <h2 className="md:text-[22px] mt-3 md:mt-0 text-[#0D0D0D] font-[700] md:ml-[44px]">
                No-Code Site Setup
              </h2>
              <h3 className="text-[#747474D6] mt-4 md:mt-0 md:text-[18px] font-[600] md:ml-[44px] md:w-[320px]">
                Quickly create a personalized e-commerce site without the need for coding skills, saving you time and resources.
              </h3>
            </div>
            <div className="bg-[#E3F2F5] p-8 md:p-0 rounded-lg md:w-[410px] md:h-[305px] w-[310px] h-[245px] md:rounded-[20px] ">
              <div className="md:w-[80px] md:h-[80px] w-[44px] h-[44px]  bg-[#FFFFFF] rounded-[50%] flex justify-center items-center md:mt-[44px] md:ml-[44px]">
                <svg
                                className="w-[14.5px] h-[15.3px] md:h-[32px] md:w-[29px]"
                  width="29"
                  height="32"
                  viewBox="0 0 29 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 17.5V8.5C22 8.10218 21.842 7.72064 21.5607 7.43934C21.2794 7.15804 20.8978 7 20.5 7H2.5C2.10218 7 1.72064 7.15804 1.43934 7.43934C1.15804 7.72064 1 8.10218 1 8.5V26.5C1 26.8978 1.15804 27.2794 1.43934 27.5607C1.72064 27.842 2.10218 28 2.5 28H15.25M7 7V28M16 7V19.75M16 7V2.5C16 2.10218 15.842 1.72064 15.5607 1.43934C15.2794 1.15804 14.8978 1 14.5 1H8.5C8.10218 1 7.72064 1.15804 7.43934 1.43934C7.15804 1.72064 7 2.10218 7 2.5V7"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21.25 31C22.1364 31 23.0142 30.8254 23.8331 30.4862C24.6521 30.147 25.3962 29.6498 26.023 29.023C26.6498 28.3962 27.147 27.6521 27.4862 26.8331C27.8254 26.0142 28 25.1364 28 24.25C28 23.3636 27.8254 22.4858 27.4862 21.6669C27.147 20.8479 26.6498 20.1038 26.023 19.477C25.3962 18.8502 24.6521 18.353 23.8331 18.0138C23.0142 17.6746 22.1364 17.5 21.25 17.5C19.4598 17.5 17.7429 18.2112 16.477 19.477C15.2112 20.7429 14.5 22.4598 14.5 24.25C14.5 26.0402 15.2112 27.7571 16.477 29.023C17.7429 30.2888 19.4598 31 21.25 31V31Z"
                    stroke="#0D0D0D"
                    stroke-width="2"
                  />
                  <path
                    d="M20.5 22V25H23.5"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.75 28V31"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              <h2 className="md:text-[22px] mt-3 md:mt-0 text-[#0D0D0D] font-[700] md:ml-[44px]">
                Selective Pricing Visibility
              </h2>
              <h3 className="text-[#747474D6] mt-4 md:mt-0 md:text-[18px] font-[600] md:ml-[44px] md:w-[320px]">
                Choose to display pricing and accept orders exclusively from your established customer base, maintaining confidentiality and loyalty.
              </h3>
            </div>
            <div className="bg-[#E3F2F5] p-8 md:p-0 rounded-lg md:w-[410px] md:h-[305px] w-[310px] h-[245px] md:rounded-[20px] ">
              <div className="md:w-[80px] md:h-[80px] w-[44px] h-[44px]  bg-[#FFFFFF] rounded-[50%] flex justify-center items-center md:mt-[44px] md:ml-[44px]">
                <svg
                                className="w-[14.5px] h-[15.3px] md:h-[32px] md:w-[29px]"

                  width="29"
                  height="32"
                  viewBox="0 0 29 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 17.5V8.5C22 8.10218 21.842 7.72064 21.5607 7.43934C21.2794 7.15804 20.8978 7 20.5 7H2.5C2.10218 7 1.72064 7.15804 1.43934 7.43934C1.15804 7.72064 1 8.10218 1 8.5V26.5C1 26.8978 1.15804 27.2794 1.43934 27.5607C1.72064 27.842 2.10218 28 2.5 28H15.25M7 7V28M16 7V19.75M16 7V2.5C16 2.10218 15.842 1.72064 15.5607 1.43934C15.2794 1.15804 14.8978 1 14.5 1H8.5C8.10218 1 7.72064 1.15804 7.43934 1.43934C7.15804 1.72064 7 2.10218 7 2.5V7"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21.25 31C22.1364 31 23.0142 30.8254 23.8331 30.4862C24.6521 30.147 25.3962 29.6498 26.023 29.023C26.6498 28.3962 27.147 27.6521 27.4862 26.8331C27.8254 26.0142 28 25.1364 28 24.25C28 23.3636 27.8254 22.4858 27.4862 21.6669C27.147 20.8479 26.6498 20.1038 26.023 19.477C25.3962 18.8502 24.6521 18.353 23.8331 18.0138C23.0142 17.6746 22.1364 17.5 21.25 17.5C19.4598 17.5 17.7429 18.2112 16.477 19.477C15.2112 20.7429 14.5 22.4598 14.5 24.25C14.5 26.0402 15.2112 27.7571 16.477 29.023C17.7429 30.2888 19.4598 31 21.25 31V31Z"
                    stroke="#0D0D0D"
                    stroke-width="2"
                  />
                  <path
                    d="M20.5 22V25H23.5"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.75 28V31"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              <h2 className="md:text-[22px] mt-3 md:mt-0 text-[#0D0D0D] font-[700] md:ml-[44px]">
                Market-Wide Quotation Requests
              </h2>
              <h3 className="text-[#747474D6] mt-4 md:mt-0 md:text-[18px] font-[600] md:ml-[44px] md:w-[320px]">
                Showcase your products, portfolio, and business offerings to the broader market, inviting quotation requests to expand your customer portfolio.
              </h3>
            </div>
            <div className="bg-[#E3F2F5] p-8 md:p-0 rounded-lg md:w-[410px] md:h-[305px] w-[310px] h-[245px] md:rounded-[20px] ">
              <div className="md:w-[80px] md:h-[80px] w-[44px] h-[44px]  bg-[#FFFFFF] rounded-[50%] flex justify-center items-center md:mt-[44px] md:ml-[44px]">
                <svg
                                className="w-[14.5px] h-[15.3px] md:h-[32px] md:w-[29px]"

                  width="29"
                  height="32"
                  viewBox="0 0 29 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 17.5V8.5C22 8.10218 21.842 7.72064 21.5607 7.43934C21.2794 7.15804 20.8978 7 20.5 7H2.5C2.10218 7 1.72064 7.15804 1.43934 7.43934C1.15804 7.72064 1 8.10218 1 8.5V26.5C1 26.8978 1.15804 27.2794 1.43934 27.5607C1.72064 27.842 2.10218 28 2.5 28H15.25M7 7V28M16 7V19.75M16 7V2.5C16 2.10218 15.842 1.72064 15.5607 1.43934C15.2794 1.15804 14.8978 1 14.5 1H8.5C8.10218 1 7.72064 1.15804 7.43934 1.43934C7.15804 1.72064 7 2.10218 7 2.5V7"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21.25 31C22.1364 31 23.0142 30.8254 23.8331 30.4862C24.6521 30.147 25.3962 29.6498 26.023 29.023C26.6498 28.3962 27.147 27.6521 27.4862 26.8331C27.8254 26.0142 28 25.1364 28 24.25C28 23.3636 27.8254 22.4858 27.4862 21.6669C27.147 20.8479 26.6498 20.1038 26.023 19.477C25.3962 18.8502 24.6521 18.353 23.8331 18.0138C23.0142 17.6746 22.1364 17.5 21.25 17.5C19.4598 17.5 17.7429 18.2112 16.477 19.477C15.2112 20.7429 14.5 22.4598 14.5 24.25C14.5 26.0402 15.2112 27.7571 16.477 29.023C17.7429 30.2888 19.4598 31 21.25 31V31Z"
                    stroke="#0D0D0D"
                    stroke-width="2"
                  />
                  <path
                    d="M20.5 22V25H23.5"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.75 28V31"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              <h2 className="md:text-[22px] mt-3 md:mt-0 text-[#0D0D0D] font-[700] md:ml-[44px]">
                Custom Domain Integration
              </h2>
              <h3 className="text-[#747474D6] mt-4 md:mt-0 md:text-[18px] font-[600] md:ml-[44px] md:w-[320px]">
                Establish a professional online presence with a custom domain that reflects your brand identity and credibility.
              </h3>
            </div>
            <div className="bg-[#E3F2F5] p-8 md:p-0 rounded-lg md:w-[410px] md:h-[305px] w-[310px] h-[245px] md:rounded-[20px] ">
              <div className="md:w-[80px] md:h-[80px] w-[44px] h-[44px]  bg-[#FFFFFF] rounded-[50%] flex justify-center items-center md:mt-[44px] md:ml-[44px]">
                <svg
                                className="w-[14.5px] h-[15.3px] md:h-[32px] md:w-[29px]"

                  width="29"
                  height="32"
                  viewBox="0 0 29 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 17.5V8.5C22 8.10218 21.842 7.72064 21.5607 7.43934C21.2794 7.15804 20.8978 7 20.5 7H2.5C2.10218 7 1.72064 7.15804 1.43934 7.43934C1.15804 7.72064 1 8.10218 1 8.5V26.5C1 26.8978 1.15804 27.2794 1.43934 27.5607C1.72064 27.842 2.10218 28 2.5 28H15.25M7 7V28M16 7V19.75M16 7V2.5C16 2.10218 15.842 1.72064 15.5607 1.43934C15.2794 1.15804 14.8978 1 14.5 1H8.5C8.10218 1 7.72064 1.15804 7.43934 1.43934C7.15804 1.72064 7 2.10218 7 2.5V7"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21.25 31C22.1364 31 23.0142 30.8254 23.8331 30.4862C24.6521 30.147 25.3962 29.6498 26.023 29.023C26.6498 28.3962 27.147 27.6521 27.4862 26.8331C27.8254 26.0142 28 25.1364 28 24.25C28 23.3636 27.8254 22.4858 27.4862 21.6669C27.147 20.8479 26.6498 20.1038 26.023 19.477C25.3962 18.8502 24.6521 18.353 23.8331 18.0138C23.0142 17.6746 22.1364 17.5 21.25 17.5C19.4598 17.5 17.7429 18.2112 16.477 19.477C15.2112 20.7429 14.5 22.4598 14.5 24.25C14.5 26.0402 15.2112 27.7571 16.477 29.023C17.7429 30.2888 19.4598 31 21.25 31V31Z"
                    stroke="#0D0D0D"
                    stroke-width="2"
                  />
                  <path
                    d="M20.5 22V25H23.5"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.75 28V31"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              <h2 className="md:text-[22px] mt-3 md:mt-0 text-[#0D0D0D] font-[700] md:ml-[44px]">
                Two-Way Inventory Sync
              </h2>
              <h3 className="text-[#747474D6] mt-4 md:mt-0 md:text-[18px] font-[600] md:ml-[44px] md:w-[320px]">
                Ensure real-time inventory updates, reducing the risk of overselling and improving order accuracy.
              </h3>
            </div>
            <div className="bg-[#E3F2F5] p-8 md:p-0 rounded-lg md:w-[410px] md:h-[305px] w-[310px] h-[245px] md:rounded-[20px] ">
              <div className="md:w-[80px] md:h-[80px] w-[44px] h-[44px]  bg-[#FFFFFF] rounded-[50%] flex justify-center items-center md:mt-[44px] md:ml-[44px]">
                <svg
                                className="w-[14.5px] h-[15.3px] md:h-[32px] md:w-[29px]"

                  width="29"
                  height="32"
                  viewBox="0 0 29 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 17.5V8.5C22 8.10218 21.842 7.72064 21.5607 7.43934C21.2794 7.15804 20.8978 7 20.5 7H2.5C2.10218 7 1.72064 7.15804 1.43934 7.43934C1.15804 7.72064 1 8.10218 1 8.5V26.5C1 26.8978 1.15804 27.2794 1.43934 27.5607C1.72064 27.842 2.10218 28 2.5 28H15.25M7 7V28M16 7V19.75M16 7V2.5C16 2.10218 15.842 1.72064 15.5607 1.43934C15.2794 1.15804 14.8978 1 14.5 1H8.5C8.10218 1 7.72064 1.15804 7.43934 1.43934C7.15804 1.72064 7 2.10218 7 2.5V7"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21.25 31C22.1364 31 23.0142 30.8254 23.8331 30.4862C24.6521 30.147 25.3962 29.6498 26.023 29.023C26.6498 28.3962 27.147 27.6521 27.4862 26.8331C27.8254 26.0142 28 25.1364 28 24.25C28 23.3636 27.8254 22.4858 27.4862 21.6669C27.147 20.8479 26.6498 20.1038 26.023 19.477C25.3962 18.8502 24.6521 18.353 23.8331 18.0138C23.0142 17.6746 22.1364 17.5 21.25 17.5C19.4598 17.5 17.7429 18.2112 16.477 19.477C15.2112 20.7429 14.5 22.4598 14.5 24.25C14.5 26.0402 15.2112 27.7571 16.477 29.023C17.7429 30.2888 19.4598 31 21.25 31V31Z"
                    stroke="#0D0D0D"
                    stroke-width="2"
                  />
                  <path
                    d="M20.5 22V25H23.5"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.75 28V31"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              <h2 className="md:text-[22px] mt-3 md:mt-0 text-[#0D0D0D] font-[700] md:ml-[44px]">
                Digital Marketing and Order Collection
              </h2>
              <h3 className="text-[#747474D6] mt-4 md:mt-0 md:text-[18px] font-[600] md:ml-[44px] md:w-[320px]">
                Utilize digital means to efficiently market your products and collect orders, enhancing your market reach and sales potential.
              </h3>
            </div>
            </div>
          </div>
          {/* <div className="flex flex-col justify-center items-center px-5 md:px-0 mt-12 md:mt-0">
            <div className=" md:flex w-full md:w-fit">
              <button
                style={{
                  background:
                    "conic-gradient(from 300deg at 105.92% -41.84%, rgb(255, 165, 2) 192deg, rgb(212, 99, 9) 343deg)",
                }}
                className="flex md:text-[18px] tracking-widest rounded items-center font-[500] justify-center text-center border-[0.4px] capitalize border-solid md:rounded-[10px] md:w-[293px] w-full h-[40px] md:h-[58px] text-[#FFFFFF]  `}"
              >
                See Bundle Offerings
              </button>
              <button className="flex mt-3 md:mt-0 md:ml-[10px] md:mb-[40px] rounded  md:text-[18px] tracking-widest items-center font-[500] justify-center text-center border-[0.4px] capitalize border-solid md:rounded-[10px] md:w-[293px] w-full md:h-[58px] h-[40px]  text-[#FFFFFF] bg-[#124870] `}">
                Pick Individual Offerings
              </button>
            </div>
          </div> */}
        </div>
        <div className="bg-[#062E4E] py-7 md:py-0  md:pb-4">
          <div className="flex flex-col justify-center items-center px-5 md:px-0">
            <h2 className="md:text-[32px] text-[20px] font-[700] text-center mx-[20px] md:mx-0  text-[#FFFFFF] font-monterrat capitalize md:font-[800] md:mt-[60px]">
            Pankh Directory  
            </h2>
            <h3 className="text-[#FFFFFFD1] text-[16px] font-[600] text-center mt-[5px] capitalize md:text-[24px] md:font-[800] md:mt-[5px]">
            Boosting B2B relationships: Your gateway to supply chain collaboration, customer expansion, and supplier diversification via interconnections.
            </h3>
          </div>
          <div className="flex ml-[20px] mr-[20px] md:mb-[100px] pl-5 md:pl-0 md:mt-[80px] mt-[20px] overflow-x-scroll scrollbar-hide">
          <div class="flex flex-nowrap gap-[20px]">

            <div className="bg-[#FFFFFF] p-8 md:p-0 rounded-lg md:w-[410px] md:h-[305px] w-[310px] h-[245px] md:rounded-[20px] ">
              <div className="md:w-[80px] md:h-[80px] w-[44px] h-[44px]  bg-[#D8F5F1] rounded-[50%] flex justify-center items-center md:mt-[44px] md:ml-[44px]">
                <svg
                className="w-[14.5px] h-[15.3px] md:h-[32px] md:w-[29px]"
                  width="29"
                  height="32"
                  viewBox="0 0 29 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 17.5V8.5C22 8.10218 21.842 7.72064 21.5607 7.43934C21.2794 7.15804 20.8978 7 20.5 7H2.5C2.10218 7 1.72064 7.15804 1.43934 7.43934C1.15804 7.72064 1 8.10218 1 8.5V26.5C1 26.8978 1.15804 27.2794 1.43934 27.5607C1.72064 27.842 2.10218 28 2.5 28H15.25M7 7V28M16 7V19.75M16 7V2.5C16 2.10218 15.842 1.72064 15.5607 1.43934C15.2794 1.15804 14.8978 1 14.5 1H8.5C8.10218 1 7.72064 1.15804 7.43934 1.43934C7.15804 1.72064 7 2.10218 7 2.5V7"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21.25 31C22.1364 31 23.0142 30.8254 23.8331 30.4862C24.6521 30.147 25.3962 29.6498 26.023 29.023C26.6498 28.3962 27.147 27.6521 27.4862 26.8331C27.8254 26.0142 28 25.1364 28 24.25C28 23.3636 27.8254 22.4858 27.4862 21.6669C27.147 20.8479 26.6498 20.1038 26.023 19.477C25.3962 18.8502 24.6521 18.353 23.8331 18.0138C23.0142 17.6746 22.1364 17.5 21.25 17.5C19.4598 17.5 17.7429 18.2112 16.477 19.477C15.2112 20.7429 14.5 22.4598 14.5 24.25C14.5 26.0402 15.2112 27.7571 16.477 29.023C17.7429 30.2888 19.4598 31 21.25 31V31Z"
                    stroke="#0D0D0D"
                    stroke-width="2"
                  />
                  <path
                    d="M20.5 22V25H23.5"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.75 28V31"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              <h2 className="md:text-[22px] mt-3 md:mt-0 text-[#0D0D0D] font-[700] md:ml-[44px]">
                Forge Stronger Connections
              </h2>
              <h3 className="text-[#747474D6] mt-4 md:mt-0 md:text-[18px] font-[600] md:ml-[44px] md:w-[320px]">
                Unlock endless business opportunities! Explore and connect with a diverse network of supply chain partners, opening doors to lucrative collaborations.
              </h3>
            </div>
            <div className="bg-[#FFFFFF] p-8 md:p-0 rounded-lg md:w-[410px] md:h-[305px] w-[310px] h-[245px] md:rounded-[20px] ">
              <div className="md:w-[80px] md:h-[80px] w-[44px] h-[44px]  bg-[#D8F5F1] rounded-[50%] flex justify-center items-center md:mt-[44px] md:ml-[44px]">
                <svg
                                className="w-[14.5px] h-[15.3px] md:h-[32px] md:w-[29px]"
                  width="29"
                  height="32"
                  viewBox="0 0 29 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 17.5V8.5C22 8.10218 21.842 7.72064 21.5607 7.43934C21.2794 7.15804 20.8978 7 20.5 7H2.5C2.10218 7 1.72064 7.15804 1.43934 7.43934C1.15804 7.72064 1 8.10218 1 8.5V26.5C1 26.8978 1.15804 27.2794 1.43934 27.5607C1.72064 27.842 2.10218 28 2.5 28H15.25M7 7V28M16 7V19.75M16 7V2.5C16 2.10218 15.842 1.72064 15.5607 1.43934C15.2794 1.15804 14.8978 1 14.5 1H8.5C8.10218 1 7.72064 1.15804 7.43934 1.43934C7.15804 1.72064 7 2.10218 7 2.5V7"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21.25 31C22.1364 31 23.0142 30.8254 23.8331 30.4862C24.6521 30.147 25.3962 29.6498 26.023 29.023C26.6498 28.3962 27.147 27.6521 27.4862 26.8331C27.8254 26.0142 28 25.1364 28 24.25C28 23.3636 27.8254 22.4858 27.4862 21.6669C27.147 20.8479 26.6498 20.1038 26.023 19.477C25.3962 18.8502 24.6521 18.353 23.8331 18.0138C23.0142 17.6746 22.1364 17.5 21.25 17.5C19.4598 17.5 17.7429 18.2112 16.477 19.477C15.2112 20.7429 14.5 22.4598 14.5 24.25C14.5 26.0402 15.2112 27.7571 16.477 29.023C17.7429 30.2888 19.4598 31 21.25 31V31Z"
                    stroke="#0D0D0D"
                    stroke-width="2"
                  />
                  <path
                    d="M20.5 22V25H23.5"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.75 28V31"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              <h2 className="md:text-[22px] mt-3 md:mt-0 text-[#0D0D0D] font-[700] md:ml-[44px]">
                Customer Base Expansion
              </h2>
              <h3 className="text-[#747474D6] mt-4 md:mt-0 md:text-[18px] font-[600] md:ml-[44px] md:w-[320px]">
                Skyrocket your sales! Connect with potential customers, expanding your clientele and unlocking new avenues for revenue growth.
              </h3>
            </div>
            <div className="bg-[#FFFFFF] p-8 md:p-0 rounded-lg md:w-[410px] md:h-[305px] w-[310px] h-[245px] md:rounded-[20px] ">
              <div className="md:w-[80px] md:h-[80px] w-[44px] h-[44px]  bg-[#D8F5F1] rounded-[50%] flex justify-center items-center md:mt-[44px] md:ml-[44px]">
                <svg
                                className="w-[14.5px] h-[15.3px] md:h-[32px] md:w-[29px]"

                  width="29"
                  height="32"
                  viewBox="0 0 29 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 17.5V8.5C22 8.10218 21.842 7.72064 21.5607 7.43934C21.2794 7.15804 20.8978 7 20.5 7H2.5C2.10218 7 1.72064 7.15804 1.43934 7.43934C1.15804 7.72064 1 8.10218 1 8.5V26.5C1 26.8978 1.15804 27.2794 1.43934 27.5607C1.72064 27.842 2.10218 28 2.5 28H15.25M7 7V28M16 7V19.75M16 7V2.5C16 2.10218 15.842 1.72064 15.5607 1.43934C15.2794 1.15804 14.8978 1 14.5 1H8.5C8.10218 1 7.72064 1.15804 7.43934 1.43934C7.15804 1.72064 7 2.10218 7 2.5V7"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21.25 31C22.1364 31 23.0142 30.8254 23.8331 30.4862C24.6521 30.147 25.3962 29.6498 26.023 29.023C26.6498 28.3962 27.147 27.6521 27.4862 26.8331C27.8254 26.0142 28 25.1364 28 24.25C28 23.3636 27.8254 22.4858 27.4862 21.6669C27.147 20.8479 26.6498 20.1038 26.023 19.477C25.3962 18.8502 24.6521 18.353 23.8331 18.0138C23.0142 17.6746 22.1364 17.5 21.25 17.5C19.4598 17.5 17.7429 18.2112 16.477 19.477C15.2112 20.7429 14.5 22.4598 14.5 24.25C14.5 26.0402 15.2112 27.7571 16.477 29.023C17.7429 30.2888 19.4598 31 21.25 31V31Z"
                    stroke="#0D0D0D"
                    stroke-width="2"
                  />
                  <path
                    d="M20.5 22V25H23.5"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.75 28V31"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              <h2 className="md:text-[22px] mt-3 md:mt-0 text-[#0D0D0D] font-[700] md:ml-[44px]">
                Supplier Network Growth
              </h2>
              <h3 className="text-[#747474D6] mt-4 md:mt-0 md:text-[18px] font-[600] md:ml-[44px] md:w-[320px]">
                Diversify your offerings, enhance your value! Expand your supplier network to access a world of products and services, elevating your business to new heights.
              </h3>
            </div>
            <div className="bg-[#FFFFFF] p-8 md:p-0 rounded-lg md:w-[410px] md:h-[305px] w-[310px] h-[245px] md:rounded-[20px] ">
              <div className="md:w-[80px] md:h-[80px] w-[44px] h-[44px]  bg-[#D8F5F1] rounded-[50%] flex justify-center items-center md:mt-[44px] md:ml-[44px]">
                <svg
                                className="w-[14.5px] h-[15.3px] md:h-[32px] md:w-[29px]"

                  width="29"
                  height="32"
                  viewBox="0 0 29 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 17.5V8.5C22 8.10218 21.842 7.72064 21.5607 7.43934C21.2794 7.15804 20.8978 7 20.5 7H2.5C2.10218 7 1.72064 7.15804 1.43934 7.43934C1.15804 7.72064 1 8.10218 1 8.5V26.5C1 26.8978 1.15804 27.2794 1.43934 27.5607C1.72064 27.842 2.10218 28 2.5 28H15.25M7 7V28M16 7V19.75M16 7V2.5C16 2.10218 15.842 1.72064 15.5607 1.43934C15.2794 1.15804 14.8978 1 14.5 1H8.5C8.10218 1 7.72064 1.15804 7.43934 1.43934C7.15804 1.72064 7 2.10218 7 2.5V7"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21.25 31C22.1364 31 23.0142 30.8254 23.8331 30.4862C24.6521 30.147 25.3962 29.6498 26.023 29.023C26.6498 28.3962 27.147 27.6521 27.4862 26.8331C27.8254 26.0142 28 25.1364 28 24.25C28 23.3636 27.8254 22.4858 27.4862 21.6669C27.147 20.8479 26.6498 20.1038 26.023 19.477C25.3962 18.8502 24.6521 18.353 23.8331 18.0138C23.0142 17.6746 22.1364 17.5 21.25 17.5C19.4598 17.5 17.7429 18.2112 16.477 19.477C15.2112 20.7429 14.5 22.4598 14.5 24.25C14.5 26.0402 15.2112 27.7571 16.477 29.023C17.7429 30.2888 19.4598 31 21.25 31V31Z"
                    stroke="#0D0D0D"
                    stroke-width="2"
                  />
                  <path
                    d="M20.5 22V25H23.5"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.75 28V31"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              <h2 className="md:text-[22px] mt-3 md:mt-0 text-[#0D0D0D] font-[700] md:ml-[44px]">
                Make Informed Decisions
              </h2>
              <h3 className="text-[#747474D6] mt-4 md:mt-0 md:text-[18px] font-[600] md:ml-[44px] md:w-[320px]">
                Utilize ratings and reviews for reliable B2B partnerships.
              </h3>
            </div>
            <div className="bg-[#FFFFFF] p-8 md:p-0 rounded-lg md:w-[410px] md:h-[305px] w-[310px] h-[245px] md:rounded-[20px] ">
              <div className="md:w-[80px] md:h-[80px] w-[44px] h-[44px]  bg-[#D8F5F1] rounded-[50%] flex justify-center items-center md:mt-[44px] md:ml-[44px]">
                <svg
                                className="w-[14.5px] h-[15.3px] md:h-[32px] md:w-[29px]"

                  width="29"
                  height="32"
                  viewBox="0 0 29 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 17.5V8.5C22 8.10218 21.842 7.72064 21.5607 7.43934C21.2794 7.15804 20.8978 7 20.5 7H2.5C2.10218 7 1.72064 7.15804 1.43934 7.43934C1.15804 7.72064 1 8.10218 1 8.5V26.5C1 26.8978 1.15804 27.2794 1.43934 27.5607C1.72064 27.842 2.10218 28 2.5 28H15.25M7 7V28M16 7V19.75M16 7V2.5C16 2.10218 15.842 1.72064 15.5607 1.43934C15.2794 1.15804 14.8978 1 14.5 1H8.5C8.10218 1 7.72064 1.15804 7.43934 1.43934C7.15804 1.72064 7 2.10218 7 2.5V7"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21.25 31C22.1364 31 23.0142 30.8254 23.8331 30.4862C24.6521 30.147 25.3962 29.6498 26.023 29.023C26.6498 28.3962 27.147 27.6521 27.4862 26.8331C27.8254 26.0142 28 25.1364 28 24.25C28 23.3636 27.8254 22.4858 27.4862 21.6669C27.147 20.8479 26.6498 20.1038 26.023 19.477C25.3962 18.8502 24.6521 18.353 23.8331 18.0138C23.0142 17.6746 22.1364 17.5 21.25 17.5C19.4598 17.5 17.7429 18.2112 16.477 19.477C15.2112 20.7429 14.5 22.4598 14.5 24.25C14.5 26.0402 15.2112 27.7571 16.477 29.023C17.7429 30.2888 19.4598 31 21.25 31V31Z"
                    stroke="#0D0D0D"
                    stroke-width="2"
                  />
                  <path
                    d="M20.5 22V25H23.5"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.75 28V31"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              <h2 className="md:text-[22px] mt-3 md:mt-0 text-[#0D0D0D] font-[700] md:ml-[44px]">
                Industry Relevance
              </h2>
              <h3 className="text-[#747474D6] mt-4 md:mt-0 md:text-[18px] font-[600] md:ml-[44px] md:w-[320px]">
                Hit the bullseye with your market strategy! Easily pinpoint relevant industries and niches to target, ensuring effective market penetration.
              </h3>
            </div>
            <div className="bg-[#FFFFFF] p-8 md:p-0 rounded-lg md:w-[410px] md:h-[305px] w-[310px] h-[245px] md:rounded-[20px] ">
              <div className="md:w-[80px] md:h-[80px] w-[44px] h-[44px]  bg-[#D8F5F1] rounded-[50%] flex justify-center items-center md:mt-[44px] md:ml-[44px]">
                <svg
                                className="w-[14.5px] h-[15.3px] md:h-[32px] md:w-[29px]"

                  width="29"
                  height="32"
                  viewBox="0 0 29 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 17.5V8.5C22 8.10218 21.842 7.72064 21.5607 7.43934C21.2794 7.15804 20.8978 7 20.5 7H2.5C2.10218 7 1.72064 7.15804 1.43934 7.43934C1.15804 7.72064 1 8.10218 1 8.5V26.5C1 26.8978 1.15804 27.2794 1.43934 27.5607C1.72064 27.842 2.10218 28 2.5 28H15.25M7 7V28M16 7V19.75M16 7V2.5C16 2.10218 15.842 1.72064 15.5607 1.43934C15.2794 1.15804 14.8978 1 14.5 1H8.5C8.10218 1 7.72064 1.15804 7.43934 1.43934C7.15804 1.72064 7 2.10218 7 2.5V7"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21.25 31C22.1364 31 23.0142 30.8254 23.8331 30.4862C24.6521 30.147 25.3962 29.6498 26.023 29.023C26.6498 28.3962 27.147 27.6521 27.4862 26.8331C27.8254 26.0142 28 25.1364 28 24.25C28 23.3636 27.8254 22.4858 27.4862 21.6669C27.147 20.8479 26.6498 20.1038 26.023 19.477C25.3962 18.8502 24.6521 18.353 23.8331 18.0138C23.0142 17.6746 22.1364 17.5 21.25 17.5C19.4598 17.5 17.7429 18.2112 16.477 19.477C15.2112 20.7429 14.5 22.4598 14.5 24.25C14.5 26.0402 15.2112 27.7571 16.477 29.023C17.7429 30.2888 19.4598 31 21.25 31V31Z"
                    stroke="#0D0D0D"
                    stroke-width="2"
                  />
                  <path
                    d="M20.5 22V25H23.5"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.75 28V31"
                    stroke="#0D0D0D"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              <h2 className="md:text-[22px] mt-3 md:mt-0 text-[#0D0D0D] font-[700] md:ml-[44px]">
                Product Discovery
              </h2>
              <h3 className="text-[#747474D6] mt-4 md:mt-0 md:text-[18px] font-[600] md:ml-[44px] md:w-[320px]">
                Discover what you need, when you need it! Effortlessly find the products you seek, revolutionizing procurement efficiency and saving precious time.
              </h3>
            </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center px-5 md:px-0 mt-12 md:mt-12">
            <div className=" md:flex w-full md:w-fit">
              <button
                style={{
                  background:
                    "conic-gradient(from 300deg at 105.92% -41.84%, rgb(255, 165, 2) 192deg, rgb(212, 99, 9) 343deg)",
                }}
                className="flex md:text-[18px] tracking-widest rounded items-center font-[500] justify-center text-center border-[0.4px] capitalize border-solid md:rounded-[10px] md:w-[293px] w-full h-[40px] md:h-[58px] text-[#FFFFFF]  `}"
              >
                See Bundle Offerings
              </button>
              <button className="flex mt-3 md:mt-0 md:ml-[10px] md:mb-[40px] rounded  md:text-[18px] tracking-widest items-center font-[500] justify-center text-center border-[0.4px] capitalize border-solid md:rounded-[10px] md:w-[293px] w-full md:h-[58px] h-[40px]  text-[#FFFFFF] bg-[#124870] `}">
                Pick Individual Offerings
              </button>
            </div>
          </div>
      </div>
      <Footer />
    </div>
  );
}
