import React from 'react';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';

const RefundCancellation = () => {
    return (
    <div className="font-nunito">
            <Navbar
                heading={"Refund and Cancellation Policy"}
                headingSm={'Refund and Cancellation Policy'}
                subheading={"Hey there, Pankh fam! 🚀 We're all about delivering software that's as awesome as your favorite playlist."}
                buttonText={"Let's Get Started"}
                description={' '}
            />
            <div className="container mx-auto mt-[50px] mb-[50px] pl-6 pr-6">
                <div className="max-w-3xl mx-auto">
                    {/* <h1 className="text-4xl font-bold mb-6">
                        Refund and cancellation policy - Pankh.io
                    </h1> */}
                    {/* <h3 className="font-semibold mb-6 text-gray-700">
                        Hey there, Pankh fam! 🚀 We're all about delivering software that's as awesome as your
                        favorite playlist.
                    </h3> */}
                    <section className="mb-8">
                        <h2 className="text-3xl font-semibold mb-4">
                            1. Introduction
                        </h2>
                        <ul className="list-disc pl-6 mb-4">
                            <li className="text-gray-700">
                                This Refund Policy ("Policy") outlines the terms and conditions under which refunds
                                will be processed for subscriptions to Pankh.io, a product of Pankh Global Solutions
                                Private Limited ("Pankh.io").
                            </li>
                            <li className="text-gray-700">
                                It is strongly advised to read our comprehensive terms and conditions, along with the
                                refund and cancellation policy. These documents serve as the foundation of our
                                commitment to transparency and your understanding of the services we provide.
                            </li>
                            <li className="text-gray-700">
                                By utilizing our website and web application, you implicitly agree to be bound by the
                                terms stipulated in our policies, including the terms and conditions. If, for any reason,
                                you disagree with any part of these policies, including the terms and conditions, we
                                kindly request you refrain from transacting on our website.
                            </li>
                        </ul>
                    </section>
                    <section className="mb-8">
                        <h2 className="text-3xl font-semibold mb-4">
                            2. Definitions
                        </h2>
                        <ul className="list-disc pl-6 mb-4">
                            <li className="text-gray-700">
                                In this Policy, "we," "us," or "our" refers to Pankh.io, and "you" or "your" refers to the
                                user or subscriber.
                            </li>
                            <li className="text-gray-700">
                                “Business Days ”- means a day that is not a Saturday, Sunday, public holiday, or
                                bank holiday in India or in the state where our office is located.
                            </li>
                            <li className="text-gray-700">
                                "Customer" means a person who buys or avails services for consideration but does
                                not include a person who purchases or avails services to resell them.
                            </li>
                            <li className="text-gray-700">
                                "Date of Transaction" means the date of purchase of any service, which includes the
                                date of any renewal processed in accordance with the terms and conditions of the
                                applicable product or service agreement.
                            </li>
                            <li className="text-gray-700">
                                "Website" - means this website with the URL https://www.pankh.io/
                            </li>
                            <li className="text-gray-700">
                                Please note that our billing cycles are structured for your convenience. The monthly
                                billing cycle spans 30 days, while the annual billing cycle encompasses 365 days.
                            </li>
                        </ul>
                    </section>
                    <section className="mb-8">
                        <h2 className="text-3xl font-semibold mb-4">
                            3. Scope
                        </h2>
                        <li className="text-gray-700">
                            This Policy applies to all subscriptions to Pankh.io and governs the refund process.
                        </li>
                    </section>
                    <section className="mb-8">
                        <h2 className="text-3xl font-semibold mb-4">
                            4. Refund Rules
                        </h2>
                        <p className="text-gray-700">
                            At Pankh, our commitment is to deliver top-tier software solutions tailored to elevate your
                            business or professional endeavors. We sincerely appreciate your decision to choose Pankh
                            as your preferred service provider. Your trust in us drives our dedication to providing
                            exceptional service. In the event that we fall short of your expectations, we've formulated this
                            policy to underscore our unwavering commitment to your satisfaction.
                        </p>
                        <ul className="list-disc pl-6 mb-4 mt-4">
                            <li className="text-gray-700 text-2xl font-semibold mb-4">
                                Monthly Subscriptions:
                            </li>
                            <p className="text-gray-700">
                                At Pankh, our commitment is to deliver top-tier software solutions tailored to elevate your
                                business or professional endeavors. We sincerely appreciate your decision to choose Pankh
                                as your preferred service provider. Your trust in us drives our dedication to providing
                                exceptional service. In the event that we fall short of your expectations, we've formulated this
                                policy to underscore our unwavering commitment to your satisfaction.
                            </p>
                            <li className="text-gray-700 text-2xl font-semibold mb-4 mt-4">
                                Annual Subscriptions:
                            </li>
                            <p className="text-gray-700">
                                At Pankh, we champion flexibility over mandatory annual commitments. Your freedom to
                                choose is essential to us. For those opting for an annual up-front commitment, we provide a
                                generous discount on our already competitive monthly subscription rates. Should you
                                encounter any dissatisfaction within the initial 30 days of using our service, please get in
                                touch with us. We are dedicated to addressing your concerns comprehensively, whether
                                through effective solutions, viable workarounds, or transparent timelines for resolution. If,
                                despite our best efforts, your expectations aren't met, we are pleased to extend a FULL
                                REFUND for your purchase. Additionally, we will gracefully downgrade your account to the
                                free plan.
                            </p>
                            <p className="text-gray-700 mt-2">
                                Your satisfaction is our ongoing commitment, extending beyond the initial 30 days for
                                monthly subscriptions and 30 days for annual subscriptions. We strive to ensure your
                                experience remains exceptional throughout your entire contract. Should you encounter any
                                issues where functionality is removed, compromised, or discontinued from the time of your
                                initial subscription, please inform us promptly.
                            </p>
                            <li className="text-gray-700 text-2xl font-semibold mb-4 mt-4">
                                Functionality Assurance:
                            </li>
                            <p className="text-gray-700">
                                In the event of such occurrences, our dedicated team is here to address your concerns. If,
                                for any reason, we fail to rectify the situation within the stipulated timelines or provide a
                                satisfactory workaround for the affected functionality, we are prepared to offer you a
                                PRO-RATED REFUND for the remainder of your contract.
                            </p>
                            <p className="text-gray-700 mt-2">
                                Your satisfaction is paramount, and we appreciate your trust in Pankh.
                            </p>
                            <li className="text-gray-700 text-2xl font-semibold mb-4 mt-4">
                                Auto-Renewal:
                            </li>
                            <p className="text-gray-700">
                                Your convenience is our priority. Your subscriptions will auto-renew, and just before each
                                renewal, we'll shoot you an email specifying the upcoming charge to your credit card.
                                Following each renewal, you'll receive a receipt detailing the deducted amount, the next
                                renewal date, and the subsequent renewal amount.
                            </p>
                            <li className="text-gray-700 text-2xl font-semibold mb-4 mt-4">
                                Cancellation Flexibility:
                            </li>
                            <p className="text-gray-700">
                                We get it – sometimes plans change. You can cancel your monthly or annual subscription up
                                to 5 business days after your renewal date, and we'll still process your cancellation promptly.
                                What's more, you'll be eligible for a FULL REFUND.
                            </p>
                            <p className="text-gray-700">
                                Your satisfaction is crucial, and we aim to make every aspect of your subscription
                                hassle-free.
                            </p>
                            <li className="text-gray-700 text-2xl font-semibold mb-4 mt-4">
                                Tier Downgrade:
                            </li>
                            <p className="text-gray-700">
                                Customers who choose to downgrade from a higher-tier subscription to a lower one within 5
                                days of their subscription will be eligible for a refund equivalent to the price difference. This
                                adjustment will be applicable from the next billing cycle, ensuring a seamless transition to
                                the modified subscription tier.
                            </p>
                            <li className="text-gray-700 text-2xl font-semibold mb-4 mt-4">
                                Upgrade Refunds:
                            </li>
                            <p className="text-gray-700">
                                When customers opt to upgrade to a higher-tier subscription mid-billing cycle, they will be
                                required to pay the remaining amount for the higher tier immediately. It is imperative to make
                                the payment promptly to gain immediate access to the enhanced features of the higher tier.
                                The new pricing will take effect from the next billing cycle, and this payment is
                                non-refundable. The upgrade will be processed once the payment is successfully made.
                            </p>
                            <li className="text-gray-700 text-2xl font-semibold mb-4 mt-4">
                                Cancellation within the Trial Period:
                            </li>
                            <p className="text-gray-700">
                                Customers who cancel their subscription within the trial period are eligible for a full refund.
                                The trial period is 30 days, starting from the date of subscription.
                            </p>
                            <li className="text-gray-700 text-2xl font-semibold mb-4 mt-4">
                                Refund Requests:
                            </li>
                            <p className="text-gray-700">
                                All refund requests must be submitted by writing to {" "}
                                <a href="mailto:support@pankh.io" className="text-blue-500 hover:underline">
                                    support@pankh.io
                                </a>
                                {' '} Requests should include the reason for the refund and any supporting documentation.
                            </p>
                            <li className="text-gray-700 text-2xl font-semibold mb-4 mt-4">
                                Billing Errors:
                            </li>
                            <p className="text-gray-700">
                                Billing errors resulting in overcharged amounts will be promptly rectified, and the excess
                                amount will be refunded to the customer.
                            </p>
                            <li className="text-gray-700 text-2xl font-semibold mb-4 mt-4">
                                Service Downtime Compensation:
                            </li>
                            <p className="text-gray-700">
                                In the event of extended service downtime or disruptions significantly impacting the user
                                experience, customers may be eligible for compensation or a partial refund, adhering to the
                                principles of fairness and transparency under the Consumer Protection Act, 2019.
                            </p>
                            <li className="text-gray-700 text-2xl font-semibold mb-4 mt-4">
                                Module or Feature Issues:
                            </li>
                            <p className="text-gray-700">
                                Refunds will be provided for specific modules or features that encounter persistent issues or
                                malfunctions, subject to verification by our technical support team, aligning with the
                                Consumer Protection Act, 2019.
                            </p>
                            <li className="text-gray-700 text-2xl font-semibold mb-4 mt-4">
                                No Refund for Partial Billing Cycle:
                            </li>
                            <p className="text-gray-700">
                                If customers choose to cancel their subscription before the billing cycle ends, refunds will not
                                be processed for the remaining time in the billing cycle. The cancellation will take effect at
                                the end of the current billing cycle or on the date of the cancelation request (if mandated by
                                the customer), and no refunds will be issued for the unused portion of that cycle.
                            </p>
                            <li className="text-gray-700 text-2xl font-semibold mb-4 mt-4">
                                Promotional Pricing Discrepancy:
                            </li>
                            <p className="text-gray-700">
                                Discrepancies related to promotional pricing, especially when discount codes are applied,
                                will be promptly addressed. Refunds will be provided if customers are overcharged due to
                                system errors, ensuring compliance with the Consumer Protection Act, 2019. To initiate the
                                refund process, proper documentation of system errors must be presented. The
                                documentation will undergo a thorough audit and review before any refund can or will be
                                processed. Our commitment is to rectify any discrepancies swiftly and guarantee a fair and
                                accurate billing process.
                            </p>
                            <li className="text-gray-700 text-2xl font-semibold mb-4 mt-4">
                                Double Billing:
                            </li>
                            <p className="text-gray-700">
                                In the rare event that customers are mistakenly double-billed for the same subscription
                                period, we understand the importance of promptly addressing this situation. Customers who
                                mistakenly double-billed for the same subscription period will be eligible for a full refund for
                                the duplicate charge. To facilitate the refund process, customers must provide proof of
                                payments showcasing both transactions made. This proof will be reviewed to ensure
                                accuracy and transparency before the refund is processed.
                            </p>
                            <li className="text-gray-700 text-2xl font-semibold mb-4 mt-4">
                                Renewal Errors and Price Changes:
                            </li>
                            <p className="text-gray-700">
                                Errors in subscription renewals leading to overcharges will be promptly corrected, and the
                                excess amount will be refunded to the customer, adhering to the Consumer Protection Act,
                                2019.
                            </p>
                            <p className="text-gray-700">
                                Customers are advised to take into consideration that the pricing of the subscribed service is
                                subject to change in the next billing cycle. It is essential to be aware of any potential price
                                surges. In the event of a price surge that the customer was unaware of, the customer is granted five days post the transaction for the renewal to cancel his or her subscription. The
                                customer can continue using the subscription only if the new price is already paid for.
                            </p>
                            <p className="text-gray-700">
                                Please note that the new pricing will be displayed on the website, providing customers with
                                the necessary awareness. Additionally, customers may be notified about the price change to
                                ensure transparency and informed decision-making.
                            </p>
                            <li className="text-gray-700 text-2xl font-semibold mb-4 mt-4">
                                Data Loss Compensation:
                            </li>
                            <p className="text-gray-700">
                                Refunding customers in cases where the platform causes data loss or corruption, subject to
                                verification by our technical support team, as required by the Consumer Protection Act,
                                2019.
                            </p>
                            <li className="text-gray-700 text-2xl font-semibold mb-4 mt-4">
                                Subscription Overlaps:
                            </li>
                            <p className="text-gray-700">
                                Refunding customers for overlapping subscriptions due to system errors or delays in
                                cancelation processing, in compliance with the Consumer Protection Act, 2019.
                            </p>
                            <li className="text-gray-700 text-2xl font-semibold mb-4 mt-4">
                                Invoicing Discrepancies:
                            </li>
                            <p className="text-gray-700">
                                Rectifying any discrepancies between invoiced amounts and the agreed-upon subscription
                                terms, with refunds issued if necessary, in adherence to the Consumer Protection Act, 2019.
                            </p>
                            <li className="text-gray-700 text-2xl font-semibold mb-4 mt-4">
                                No Refund in Case of Account Termination:
                            </li>
                            <p className="text-gray-700">
                                There will be strictly no refund (FULL OR PRO-RATED) in cases where your account is
                                terminated or suspended or blocked by Pankh.io for activities that are in violation of our
                                Terms of Service, including, but not limited to phishing, impersonation, fraud, and violation of
                                any applicable laws.
                            </p>
                        </ul>
                    </section>
                    <section className="mb-8">
                        <h2 className="text-3xl font-semibold mb-4">
                            5. Cancellation Rules
                        </h2>
                        <ul className="list-disc pl-6 mb-4 mt-4">
                            <li className="text-gray-700 text-2xl font-semibold mb-4">
                                Contract Term:
                            </li>
                            <p className="text-gray-700">
                                Subscription cancellations can be made at the end of the contract term without any penalty.
                                Additionally, customers have the flexibility to cancel their subscription anytime without
                                incurring any charges.
                            </p>
                            <li className="text-gray-700 text-2xl font-semibold mb-4 mt-4">
                                Cancellation Process:
                            </li>
                            <p className="text-gray-700">
                                All cancellation requests must be submitted through our customer support portal or in
                                writing, adhering to the provisions of the Indian Contract Act, 1872. We do not accept
                                cancellations over the phone. Customers can also independently cancel their subscription
                                directly on the website or the web portal without the need to send a cancellation request to
                                us. This multi-channel approach provides customers with the flexibility to choose the method
                                most convenient for them.
                            </p>
                            <li className="text-gray-700 text-2xl font-semibold mb-4 mt-4">
                                Effective Date of Cancellation:
                            </li>
                            <p className="text-gray-700">
                                Cancellations will be effective at the end of the current billing cycle, as stipulated by the
                                Indian Contract Act, 1872. Users will retain access to the platform and its features until that
                                date, ensuring a seamless transition in the cancellation process. Please note that if a customer requests immediate cancellation under specific circumstances, access to the
                                current subscription may be lost, as the cancellation will be processed immediately per the
                                user's request.
                            </p>
                            <li className="text-gray-700 text-2xl font-semibold mb-4 mt-4">
                                Communication of Cancellation:
                            </li>
                            <p className="text-gray-700">
                                Customers will receive confirmation of their cancellation via email, including the effective
                                date of cancellation. This email serves as acknowledgment and documentation of the
                                cancellation process.
                            </p>
                            <li className="text-gray-700 text-2xl font-semibold mb-4 mt-4">
                                Cancellation Due to Violation of Terms of Service:
                            </li>
                            <p className="text-gray-700">
                                Violations of the platform's terms of service may result in the termination of the subscription
                                without a refund. In such cases, the cancellation will be effective immediately upon
                                identification of the violation, and no refund will be processed.
                            </p>
                            <li className="text-gray-700 text-2xl font-semibold mb-4 mt-4">
                                Inactivity Period:
                            </li>
                            <p className="text-gray-700">
                                If a customer's account remains inactive for an extended period, you may consider a policy
                                where subscriptions are automatically canceled after a defined period of inactivity as
                                mentioned in the terms and conditions.
                            </p>
                            <li className="text-gray-700 text-2xl font-semibold mb-4 mt-4">
                                Grace Period for Payment:
                            </li>
                            <p className="text-gray-700">
                                If payment is not made within 5 days after the end of a billing cycle, the subscription will be
                                canceled. This grace period allows customers additional time to complete the payment and
                                ensures a smooth transition in case of any delays.
                            </p>
                            <li className="text-gray-700 text-2xl font-semibold mb-4 mt-4">
                                Cancellation of Modules or Tiers:
                            </li>
                            <p className="text-gray-700">
                                Certain modules or tiers can be canceled upon the request of the user. To initiate this
                                process, the user or customer should contact our support team. This contact can be made
                                through email, in writing, or, if instructed by the user, via phone call. In the case of phone
                                calls, the support executive will send email confirmation to ensure documentation and clarity.
                            </p>
                            <li className="text-gray-700 text-2xl font-semibold mb-4 mt-4">
                                Changes in Laws or Regulations:
                            </li>
                            <p className="text-gray-700">
                                If there are changes in laws or regulations that significantly impact the service provided,
                                customers may be allowed to cancel without incurring any penalties. This ensures that
                                customers have the flexibility to adapt to unforeseen legal changes.
                            </p>
                        </ul>
                    </section>
                    <section className="mb-8">
                        <h2 className="text-3xl font-semibold mb-4">
                            6. Terms to note
                        </h2>
                        <ul className="list-disc pl-6 mb-4 mt-4">
                            <li className="text-gray-700 text-2xl font-semibold mb-4">
                                Refund Method:
                            </li>
                            <p className="text-gray-700">
                                Refunds will be processed using the original payment method, unless otherwise specified or
                                agreed upon, as outlined in the Consumer Protection Act, 2019.
                            </p>
                            <li className="text-gray-700 text-2xl font-semibold mb-4 mt-4">
                                Refund Processing Time:
                            </li>
                            <p className="text-gray-700">
                                The time taken for refunds to be credited depends on the payment mode used and is as
                                follows:
                                <ul>
                                    - UPI 3 to 4 working days
                                </ul>
                                <ul>
                                    - Debit/credit card: 5 to 7 working days
                                </ul>
                                <ul>
                                    - Wallet/Gift card 3 to 5 working days
                                </ul>
                            </p>
                            <li className="text-gray-700 text-2xl font-semibold mb-4 mt-4">
                                Policy Compliance:
                            </li>
                            <p className="text-gray-700">
                                This refund policy is meticulously designed to adhere to and comply with the provisions set
                                forth in the Consumer Protection Act of 2019. At Pankh Global Solutions Private Limited, we
                                are unwavering in our commitment to ensuring transparency, fairness, and the protection of
                                consumer rights in all our refund processes. We strongly encourage not only the review but
                                also the full adherence to this policy, along with the terms and conditions. Understanding and
                                adhering to these guidelines are crucial in aligning with the legal standards established to
                                safeguard your interests as a consumer.
                            </p>
                            <li className="text-gray-700 text-2xl font-semibold mb-4 mt-4">
                                Policy Updates:
                            </li>
                            <p className="text-gray-700">
                                Pankh.io reserves the right to update this Policy. Customers may be informed of any
                                changes via email or through our website. It is imperative to note that all policy changes are
                                consistently visible on our website. It is advisable to stay informed about any modifications to
                                ensure a clear understanding of our refund processes.
                            </p>
                            <li className="text-gray-700 text-2xl font-semibold mb-4 mt-4">
                                Contact Information:
                            </li>
                            <p className="text-gray-700">
                                For assistance with refunds or questions related to this policy, you can contact our customer
                                support team at {" "}
                                <a href="mailto:support@pankh.io" className="text-blue-500 hover:underline">
                                    support@pankh.io
                                </a>
                                .
                            </p>
                        </ul>
                    </section>
                    <section className="mb-8">
                        <h2 className="text-3xl font-semibold mb-4">
                            7. Conclusion
                        </h2>
                        <p className="text-gray-700">
                            By subscribing to Pankh.io, you agree to the terms outlined in this Policy. We appreciate
                            your understanding and cooperation in adhering to these guidelines. Should you have any
                            queries or require further clarification, feel free to contact our customer support team at {" "}
                            <a href="mailto:support@pankh.io" className="text-blue-500 hover:underline">
                                support@pankh.io
                            </a>
                            .
                        </p>
                    </section>
                </div>
            </div>
            <Footer />
    </div>
        
    );
};

export default RefundCancellation;
