import { React, useState } from "react";
import tally from "../../assets/image/Tally.png";
import { Link, useNavigate } from "react-router-dom";
import ResKnowMoreCard from "./resKnowmore";

export default function HoverCard({ details, setModal }) {
  const navigate = useNavigate();

  const [over, setOver] = useState(false);
  const [out, setOut] = useState(true);

  const mouseEnterHandler = () => {
    setOver(true);
    setOut(false);
  };

  const mouseOutHandler = () => {
    setOut(true);
    setOver(false);
  };
  return (
    <div className="font-nunito">
      {out && (
        <div
          className="bg-[#FFFFFF] shadow-lg md:w-[295px] mt-5 md:mt-0 md:h-[316px] w-[320px] h-[260px] rounded-[3px] border-[#CACACA7D] border-[1px] p-[32px] md:p-0 md:pt-[38px] md:pl-[34px]"
          onMouseOver={mouseEnterHandler}
        >
          <div className="md:w-[167px] flex justify-center items-center  md:-ml-[27px] md:h-[87px] w-[69px] h-[48px]">
            <img src={details.img} alt="" className=""/>
          </div>
          <h2 className="text-[#124870] md:text-[22px] text-[18px] font-monterrat font-[600] md:mt-[52px] mt-[30px]">
            LoremIp
          </h2>
          <h3 className="text-[#615F5F] md:text-[18px] font-monterrat text-[16px] font-[600] md:mt-[5px] mt-[2px]">
            Accounting tools
          </h3>
          <h3 className="text-[#797979] md:text-[16px] text-[14px] font-[600] md:mt-[14x] w-[240px] mt-[10px]">
            Turpis velit neque diam.Dictum orci non metus, semper leo
          </h3>
        </div>
      )}

      {over && (
        <div
          onMouseLeave={mouseOutHandler}
          className="md:w-[295px] shadow-lg md:h-[316px] mt-5 md:mt-0 w-[325px] h-[265px] bg-[#124870] flex flex-col md:px-[32.7px] py-6 px-[54px] md:py-4 border-[#F0F0F07D] border-[1px]"
        >
          <h2 className="text-[#FFFFFF] md:text-[22px] text-[18px] font-[600] md:mt-[px]">
            LoremIp
          </h2>
          <h2 className="text-[#F0F0F0] md:text-[18px] text-[16px] font-[600] mt-1 md:mt-[6px]">
            By SolutionsPvt.
          </h2>
          <p className="text-[#D4D4D4] md:text-[16px] text-[14px] font-[600] mt-[10px]  md:mt-[18.5px]">
            Turpis velit neque diam. Dictum ie er orci non metus, semper leo
          </p>
          <button
            onClick={() => setModal((prev) =>({...prev, knowMore: true}) ) }
            className="border-[#F0F0F07D] hidden md:block border-[1px] md:mt-[33px] mt-[25px] py-[6.5px] text-[#FFFFFF] text-[14px] md:text-[16px] font-[600]"
          >
            Know more &gt;&gt;
          </button>
          <button
            onClick={() => navigate("/resKnowmore")}
            className="border-[#F0F0F07D] md:hidden border-[1px] md:mt-[33px] mt-[25px] py-[6.5px] text-[#FFFFFF] text-[14px] md:text-[16px] font-[600]"
          >
            Know more &gt;&gt;
          </button>
          <button
            onClick={() => navigate("/productDetail")}
            className="bg-[#FFA502] text-[#FFFFFF] mt-[12px] py-[6.5px] text-[16px] font-[600]"
          >
            Buy
          </button>
        </div>
      )}
    </div>
  );
}
