import React from "react";

const Modal = ({ toggleModal, modal, data }) => {
  const { img, Name, Post, Text } = data;
  if (modal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }
  return (
    <div>
      {modal && (
        <div className="modal" style={{ zIndex: 1000 }}>
          <div onClick={toggleModal} className="overlay"></div>
          <div className="modal-content">
            <div className="lg:ml-[20px] lg:mr-[20px] lg:mt-[10px] flex mt-[17px]">
              <div className="bg-[#ffffff] lg:w-[70px] lg:h-[70px] w-[25.87px] h-[24px] rounded-[50%]">
                <img src={img} alt="" />
              </div>
              <div className="lg:ml-[16px]">
                <h2 className="text-[#074381] lg:text-[23px] text-[16px] font-[700] mt-[-8px] ml-[8px] lg:mt-[0] lg:ml-[5px]">
                  {Name}
                </h2>
                <h2 className="lg:text-[19px] text-[#3A4047D1] text-[12px] font-[0] lg:mt-[0px] ml-[8px] mt-[-1px] lg:ml-[4px]">
                  {Post}
                </h2>
              </div>
            </div>

            <div className="lg:m-[20px] lg:mt-[20px] flex mt-[17px]">
              {/* <div className="lg:ml-[0px]"> */}
              <h2 className="lg:text-[16px] text-[#3A4047D1] text-[12px] font-[0] lg:mt-[-3px] mb-[0px] mt-[-4px] ">
                🙶{Text}🙷
              </h2>
              {/* </div> */}
            </div>

            <button className="close-modal" onClick={toggleModal}>
              ✖
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Modal;
