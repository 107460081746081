import React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/image/logo.png";
// import background from "../assets/image/navbackground.png";

export default function Navbar({ heading, headingSm, subheading, description, buttonText }) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [modals, setModals] = useState({
    open1: false,
  });

  const openHandler = () => {
    setOpen(true);
  };

  return (
    <div className="font-nunito ">
      <div
        // style={{
        //   background:
        //     "linear-gradient(160deg, rgba(12, 78, 126, 0.711) 24.17%, rgba(4, 37, 61, 0.9) 90.04%, rgba(11, 50, 96, 0.9) 90.04%)",
        // }}
        className="lg:h-[665px] sm:h-full sm:w-fit lg:w-full bg-center bg-cover bg-no-repeat bg-[url('./assets/image/navbackground.png')] "
      >
        <div className="lg:h-[120px] pt-[21px] px-[15px] lg:px-0 lg:pt-0">
          <div className="lg:px-2  flex justify-between items-center w-full h-full">
            <div className="flex items-center">
              <div className="flex lg:gap-[12px] gap-2">
                <img
                  src={logo}
                  alt="logo"
                  className="lg:ml-[90px] w-[24.73px] h-[24px]  cursor-pointer lg:w-[34px] lg:h-[33px]"
                  onClick={() => navigate("/")}
                />

                <h1
                  className="lg:text-[26px] text-[16px] text-[#FFFFFF] font-[700] tracking-widest cursor-pointer"
                  onClick={() => navigate("/")}
                >
                  PANKH
                </h1>
              </div>
              <ul className=" lg:ml-[50px] font-[400] hidden md:flex">
                <li className="lg:p-4 text-[20px] text-[#808080]  tracking-wide">
                  {/* <Link to="/store"> Store</Link> */} Store
                </li>
                <li className="lg:p-4 text-[20px] text-[#808080] ml-[15px]  tracking-wide relative">
                  {/* <Link to="/resources"> Resources </Link> */} Resources
                </li>
                <li className="lg:p-4 text-[20px] text-[#FFFFFF] ml-[15px]  tracking-wide">
                  <Link to="/aboutus">About</Link>
                </li>
                <li className="lg:p-4 text-[20px] text-[#FFFFFF] ml-[15px]  tracking-wide">
                  <Link to="/features"> Features </Link>
                </li>
                <li className="lg:p-4 text-[20px] text-[#FFFFFF] ml-[15px]  tracking-wide">
                  <Link to="/contact"> Contact Us </Link>
                </li>
              </ul>
            </div>
            <div className="md:hidden  ">
              <div className="flex gap-[13px] ">
                <span>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 0C9.06087 0 10.0783 0.421427 10.8284 1.17157C11.5786 1.92172 12 2.93913 12 4C12 5.06087 11.5786 6.07828 10.8284 6.82843C10.0783 7.57857 9.06087 8 8 8C6.93913 8 5.92172 7.57857 5.17157 6.82843C4.42143 6.07828 4 5.06087 4 4C4 2.93913 4.42143 1.92172 5.17157 1.17157C5.92172 0.421427 6.93913 0 8 0ZM8 2C7.46957 2 6.96086 2.21071 6.58579 2.58579C6.21071 2.96086 6 3.46957 6 4C6 4.53043 6.21071 5.03914 6.58579 5.41421C6.96086 5.78929 7.46957 6 8 6C8.53043 6 9.03914 5.78929 9.41421 5.41421C9.78929 5.03914 10 4.53043 10 4C10 3.46957 9.78929 2.96086 9.41421 2.58579C9.03914 2.21071 8.53043 2 8 2ZM8 9C10.67 9 16 10.33 16 13V16H0V13C0 10.33 5.33 9 8 9ZM8 10.9C5.03 10.9 1.9 12.36 1.9 13V14.1H14.1V13C14.1 12.36 10.97 10.9 8 10.9Z"
                      fill="#D8D8D8"
                    />
                  </svg>
                </span>
                <span className="mt-[-1px]">
                  <svg
                    width="18"
                    height="19"
                    viewBox="0 0 18 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.9082 13.3281L17.0009 17.0019"
                      stroke="#D8D8D8"
                      stroke-width="2"
                      stroke-linecap="round"
                    />
                    <path
                      d="M8.11154 15.2212C12.0391 15.2212 15.2231 12.0377 15.2231 8.11061C15.2231 4.18353 12.0391 1 8.11154 1C4.18394 1 1 4.18353 1 8.11061C1 12.0377 4.18394 15.2212 8.11154 15.2212Z"
                      stroke="#D8D8D8"
                      stroke-width="2"
                    />
                  </svg>
                </span>
                <span
                  className="relative mt-[-4px]"
                  onClick={() =>
                    setModals((prev) => ({ ...prev, open1: !modals.open1 }))
                  }
                >
                  {modals.open1 ? (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.2181 17.8165C16.948 18.0865 16.5817 18.2382 16.1999 18.2382C15.818 18.2382 15.4517 18.0865 15.1817 17.8165L12.0005 14.1805L8.81925 17.8153C8.68594 17.9508 8.52712 18.0586 8.35194 18.1324C8.17677 18.2062 7.98871 18.2446 7.79863 18.2454C7.60854 18.2461 7.42018 18.2093 7.24441 18.1369C7.06864 18.0645 6.90894 17.958 6.77453 17.8236C6.64011 17.6892 6.53364 17.5295 6.46126 17.3537C6.38887 17.178 6.352 16.9896 6.35277 16.7995C6.35355 16.6094 6.39195 16.4214 6.46577 16.2462C6.53958 16.071 6.64735 15.9122 6.78285 15.7789L10.0925 11.9989L6.78165 8.21649C6.64615 8.08318 6.53838 7.92435 6.46457 7.74918C6.39075 7.57401 6.35235 7.38595 6.35157 7.19586C6.3508 7.00578 6.38767 6.81741 6.46006 6.64165C6.53244 6.46588 6.63891 6.30618 6.77333 6.17177C6.90774 6.03735 7.06744 5.93088 7.24321 5.85849C7.41898 5.78611 7.60734 5.74924 7.79743 5.75001C7.98751 5.75079 8.17557 5.78919 8.35074 5.863C8.52592 5.93682 8.68474 6.04459 8.81806 6.18009L12.0005 9.81729L15.1817 6.18009C15.315 6.04459 15.4738 5.93682 15.649 5.863C15.8241 5.78919 16.0122 5.75079 16.2023 5.75001C16.3924 5.74924 16.5807 5.78611 16.7565 5.85849C16.9323 5.93088 17.092 6.03735 17.2264 6.17177C17.3608 6.30618 17.4673 6.46588 17.5397 6.64165C17.612 6.81741 17.6489 7.00578 17.6481 7.19586C17.6474 7.38595 17.609 7.57401 17.5351 7.74918C17.4613 7.92435 17.3536 8.08318 17.2181 8.21649L13.9085 11.9989L17.2181 15.7789C17.3519 15.9126 17.4582 16.0714 17.5306 16.2463C17.6031 16.4211 17.6404 16.6085 17.6404 16.7977C17.6404 16.9869 17.6031 17.1743 17.5306 17.3491C17.4582 17.5239 17.3519 17.6828 17.2181 17.8165Z"
                        fill="#D8D8D8"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="27"
                      height="24"
                      viewBox="0 0 27 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22.9688 6V8H3.28125V6H22.9688ZM3.28125 18H22.9688V16H3.28125V18ZM3.28125 13H22.9688V11H3.28125V13Z"
                        fill="white"
                      />
                    </svg>
                  )}
                </span>
                {modals.open1 && (
                  <div>
                    <svg
                      className="absolute right-5 mt-[20px]"
                      width="32"
                      height="27"
                      viewBox="0 0 32 27"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M16 0L31.5885 27H0.411543L16 0Z" fill="white" />
                    </svg>

                    <div className="w-[300px] p-[39px] right-4 mt-[30px] absolute h-[260px] rounded-[12px] bg-[#FFFFFF]">
                      <ul className="text-[#062E4E] text-[20px] font-[]">
                        {/* <li onClick={() => navigate("/store")}>Store</li>  */}
                        <li>Store</li> 
                        {/* <li
                          className="mt-[15px]"
                          onClick={() => navigate("/resources")}
                        >
                          Resources
                        </li> */}
                        <li
                          className="mt-[15px]"
                        >
                          Resources
                        </li>
                        <li
                          className="mt-[15px]"
                          onClick={() => navigate("/aboutus")}
                        >
                          About
                        </li>
                        <li
                          className="mt-[15px]"
                          onClick={() => navigate("/features")}
                        >
                          Features
                        </li>
                        <li
                          className="mt-[15px]"
                          onClick={() => navigate("/contact")}
                        >
                          Contact Us
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="hidden md:contents lg:contents">
              <div
                className="flex relative "
                onClick={() => navigate("/waitlist")}
              >
                <span
                  className="absolute -ml-[68px]"
                  onClick={() => navigate("/waitlist")}
                >
                  <svg
                    width="96"
                    height="65"
                    viewBox="0 0 96 65"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className=" "
                  >
                    <g clip-path="url(#clip0_3055_64766)">
                      <path
                        d="M78.8164 10.0469L91.8941 20.8338L79.0131 29.6426"
                        stroke="#FFFFFF"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <path
                      d="M92 20.2263C42.7679 9.71354 12.4256 26.6061 2 64.1523"
                      stroke="#FFFFFF"
                      stroke-width="3"
                    />
                    <defs>
                      <clipPath id="clip0_3055_64766">
                        <rect
                          width="39.1935"
                          height="19.5255"
                          fill="white"
                          transform="matrix(0.0100383 0.99995 0.997109 0.0759842 75.4727 0)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <Link to="/waitlist">
                    <p className="w-[220px] italic text-center text-[22px] text-[#808080] -ml-[120px]">
                      Join the Waitlist instead
                    </p>
                  </Link>
                </span>

                <button
                  onClick={() => navigate("/waitlist")}
                  style={{
                    border: "1px solid #808080",
                    marginRight: "90px"
                    // background:
                    //   "conic-gradient(from 300deg at 105.92% -41.84%, rgb(255, 165, 2) 192deg, rgb(212, 99, 9) 343deg)",
                  }}
                  className={`flex items-center font-[700] justify-center text-center tracking-widest capitalize border-solid rounded-[10px] w-[168px] h-[51px]  text-[#808080]`}
                >
                  Join Waitlist
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="ml-[50px] text-[#FFFFFF] text-[18px]">
          <Link to="/"> Home </Link>
        </div> */}
        <div className="flex flex-col justify-center items-center">
          <div className="lg:text-[55px] lg:font-[0] hidden md:block tracking-wider text-[#FFFFFF] mt-[65px] font-monterrat">
            {heading}
          </div>
          <div>
            <p className="md:hidden text-[28px] text-[#FFFFFF] font-[0] mt-[30px] pl-[20px]">
              {headingSm}
              {/* Welcome to Pankh */}
            </p>
          </div>
          <div className="text-[#FFFFFF] mt-[10px] text-center text-[14px] font-[] lg:text-[24px] lg:font-[] ">
            {/* Take a digital approach to how you run your business. */}
            {subheading}
          </div>
          <button
            className="border-b-[1px] md:hidden  mt-[30px] mb-[40px] border-[#DE8350] text-[#DE8350] text-[20px] font-[700] tracking-wider"
            onClick={() => navigate("/waitlist")}
          >
            {/* Let’s get started */}
            {buttonText}
          </button>

          <p className="text-[#FFFFFF] lg:w-[974px] lg:text-[18px] lg:font-[] hidden lg:block mt-[15px] text-center text-nunito">
            {/* View, monitor, and run your business from anywhere at any point in
            time. Manage & Collaborate with your customers & employees. Own your
            very own B2B e-commerce website. */}
            {description}
          </p>
          <div className="hidden md:block lg:block">
            <div className="flex relative ">
              <button
                className={`mt-[65px] text-[18px] flex items-center font-[700] tracking-widest justify-center text-center border-[1px] capitalize border-[#FFFFFF7A] rounded-[10px] mr-3 w-[320px] h-[69px] text-[#FFFFFF7A]`}
                onClick={() => navigate("/waitlist")}
              >
                {/* Let’s get started */}
                {buttonText}
              </button>
              <span className="absolute mt-[73px] ml-[286px] ">
                <svg
                  width="114"
                  height="57"
                  viewBox="0 0 114 57"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_3055_64772)">
                    <path
                      d="M18.9375 10.0469L3.94511 20.8338L18.712 29.6426"
                      stroke="#FFFFFF"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <path
                    d="M4 20.8252C57 20.8237 93 11.4388 112 56"
                    stroke="#FFFFFF"
                    stroke-width="3"
                  />
                  <defs>
                    <clipPath id="clip0_3055_64772">
                      <rect
                        width="39.1941"
                        height="22.3687"
                        fill="white"
                        transform="matrix(-0.0115078 0.999934 -0.997798 0.0663261 22.7695 0)"
                      />
                    </clipPath>
                  </defs>
                </svg>

                <Link to="/waitlist">
                  <p className="w-[220px] italic text-center text-[22px] text-[#808080]">
                    Join the Waitlist instead
                  </p>
                </Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
