import React from 'react';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';

const PrivacyPolicy = () => {
    return (
        <div className='font-nunito'>
            <Navbar
                heading={"Pankh Privacy Policy"}
                headingSm={'Pankh Privacy Policy'}
                subheading={" "}
                buttonText={"Let's Get Started"}
                description={' '}
            />
            <div>
                <div className="container mx-auto mt-[50px] mb-[50px] pl-6 pr-6">
                    <div className="max-w-3xl mx-auto">
                        <section className="mb-8">
                            <h2 className="text-3xl font-semibold mb-4">
                                Definitions
                            </h2>
                            <p className="text-gray-700">
                                THIS IS AN AGREEMENT BETWEEN YOU OR THE ENTITY THAT YOU REPRESENT (HEREINAFTER “YOU” or “YOUR”) AND PANKH.IO (HEREINAFTER “PANKH') GOVERNING YOUR USE OF PANKH.IO SOFTWARE.
                            </p>
                            <ul className="list-disc pl-6 mb-4">
                                <li className="text-gray-700">
                                    In this Policy, "we," "us," or "our" refers to Pankh.io or Pankh, and "you" or "your" refers to the user or subscriber.
                                </li>
                                <li className="text-gray-700">
                                    “Business Days ”- means a day that is not a Saturday, Sunday, public holiday, or bank holiday in India or in the state where our office is located.
                                </li>
                                <li className="text-gray-700">
                                    "Customer" means a person who buys or avails services for consideration but does not include a person who purchases or avails services to resell them.
                                </li>
                                <li className="text-gray-700">
                                    "Website" - means this website with the URL <a href="https://www.pankh.io/" className="text-blue-500 hover:underline">
                                        https://www.pankh.io/
                                    </a>
                                </li>
                            </ul>
                        </section>
                        <section className="mb-8">
                            <h2 className="text-3xl font-semibold mb-4">
                                Pankh Privacy Commitment:
                            </h2>
                            <p className="text-gray-700 pb-[8px]">
                                At Pankh, we are dedicated to safeguarding the privacy and confidentiality of our users. Our commitment to user privacy is at the core of our values. We recognize the trust you place in us when you choose to use our platform, and we strive to ensure that your personal information is handled with the utmost care and responsibility.
                            </p>
                            <p className="text-gray-700 pb-[8px]">
                                The purpose of this privacy policy is to provide explicit details about the information we collect, how we use it, and the practices we employ to protect your privacy. While we aim to keep the language simple and direct, we recognize the importance of addressing all relevant cases to ensure clarity and compliance with privacy standards.
                            </p>
                            <p className="text-gray-700">
                                We appreciate your trust in Pankh, and we are committed to maintaining the highest standards of privacy and data protection.
                            </p>
                        </section>
                        <section className="mb-8">
                            <h2 className="text-3xl font-semibold mb-4">
                                Scope of this Privacy Policy:
                            </h2>
                            <p className="text-gray-700 pb-[8px]">
                                This Privacy Policy applies to all Pankh.io websites that link to it. It also extends to the products and services provided by Pankh.io through these websites, our mobile applications, and applications posted by Pankh.io on Pankh.io’s online marketplace and in other third-party online marketplaces. This Privacy Policy does not apply to any of our websites, products, or services that have a separate privacy policy.
                            </p>
                            <p className="text-gray-700 pb-[8px]">
                                This Privacy Policy is divided into three parts:
                            </p>
                            <ul className="list-disc pl-6 mb-4">
                                <li className="text-gray-700 font-bold">
                                    Part I – Information Pankh.io collects and controls:
                                </li>
                                <p className="text-gray-700 pb-[8px]">
                                    This part addresses how Pankh.io collects and uses information about website visitors, potential customers, users of Pankh.io's products and services, and others who contact Pankh.io through forms or email addresses published on or linked to our websites.
                                </p>
                                <li className="text-gray-700 font-bold">
                                    Part II – Information that Pankh.io processes on your behalf:
                                </li>
                                <p className="text-gray-700 pb-[8px]">
                                    This part deals with how Pankh.io handles data that you entrust to Pankh.io when you use our products and services, or when you share any personal or confidential information with us while requesting customer support.
                                </p>
                                <li className="text-gray-700 font-bold">
                                    Part III – General:
                                </li>
                                <p className="text-gray-700">
                                    This part covers topics that are relevant to both Parts I and II, and other general topics such as Pankh.io's security commitments and how we will inform you when we change this Privacy Policy.
                                </p>
                            </ul>
                        </section>
                        <section className="mb-8">
                            <h2 className="text-3xl text-center font-semibold mb-4 mt-4">
                                Part I – Information Zoho collects and controls
                            </h2>
                            <h2 className="text-2xl font-semibold mb-4">
                                What information Pankh collects:
                            </h2>
                            <p className="text-gray-700 mb-4">
                                We collect information about you only if we need the information for some legitimate purpose. Pankh will have information about you only if (a) you have provided the information yourself, (b) Pankh has automatically collected the information, or (c) Pankh has obtained the information from a third party. Below, we describe the various scenarios that fall under each of those three categories and the information collected in each one.
                            </p>
                            <h2 className="text-2xl font-bold underline mb-4">
                                Information that you provide us
                            </h2>
                            <ol className="list-decimal-roman list-outside pl-6 mb-4">
                                <li className="text-gray-700 font-semibold pb-2">
                                    i. Account Signup:
                                </li>
                                <p className="text-gray-700 mb-4">
                                    When you sign up for an account to access one or more of our services, we ask for information like your name, contact number, email address, company name, and country to complete the account signup process. You'll also be required to choose a unique username and password for accessing the created account. You may also provide us with more information such as business name, business supply node, business size, GSTIN, Business Address, and industry type as a part of the registration process.
                                </p>
                                <li className="text-gray-700 font-semibold pb-2">
                                    ii. Payment Processing:
                                </li>
                                <p className="text-gray-700 mb-4">
                                    When you buy something from us, we ask you to provide your name, contact information, and credit card information or other payment account information. When you submit your card information, we store the name and address of the cardholder, the expiry date, and the last four digits of the credit card number. We do not store the actual credit card number. For quick processing of future payments, if you have given us your approval, we may store your credit card information or other payment information in an encrypted format in the secured servers of our Payment Gateway Service Providers.
                                </p>
                                <li className="text-gray-700 font-semibold pb-2">
                                    iii. Testimonials:
                                </li>
                                <p className="text-gray-700 mb-4">
                                    When you authorize us to post testimonials about our products and services on websites, we may include your name and other personal information in the testimonial. You will be given an opportunity to review and approve the testimonial before we post it. If you wish to update or delete your testimonial, you can contact us at {" "} <a href="mailto:support@pankh.io" className="text-blue-500 hover:underline">
                                        support@pankh.io
                                    </a>
                                </p>
                                <li className="text-gray-700 font-semibold pb-2">
                                    iv. Interactions with Pankh:
                                </li>
                                <p className="text-gray-700 mb-4">
                                    We may record, analyze, and use your interactions with us, including email, telephone, and chat conversations with our sales and customer support professionals, for improving our interactions with you and other customers.
                                </p>
                            </ol>
                            <h2 className="text-2xl font-bold underline mb-4">
                                Information that we collect automatically
                            </h2>
                            <ol className="list-decimal-roman list-outside pl-6 mb-4">
                                <li className="text-gray-700 font-semibold pb-2">
                                    i. Information from Browsers, Devices, and Servers:
                                </li>
                                <p className="text-gray-700 mb-4">
                                    When you visit our websites, we collect information that web browsers, mobile devices, and servers make available, such as the internet protocol address, browser type, language preference, time zone, referring URL, date and time of access, operating system, mobile device manufacturer, and mobile network information. We include these in our log files to understand more about visitors to our websites.
                                </p>
                                <li className="text-gray-700 font-semibold pb-2">
                                    ii. Information from cookies and tracking technologies:
                                </li>
                                <p className="text-gray-700 mb-4">
                                    We use temporary and permanent cookies to identify users of our services and to enhance user experience. We embed unique identifiers in our downloadable products to track usage of the products. We also use cookies, beacons, tags, scripts, and other similar technologies to identify visitors, track website navigation, gather demographic information about visitors and users, understand email campaign effectiveness, and for targeted visitor and user engagement by tracking your activities on our websites. We mostly use first-party cookies and do not use third-party cookies or other third-party tracking technologies on our websites for non-essential or intrusive tracking.
                                </p>
                                <li className="text-gray-700 font-semibold pb-2">
                                    iii. Information from application logs and mobile analytics:
                                </li>
                                <p className="text-gray-700 mb-4">
                                    We collect information about your use of our products, services, and mobile applications from application logs and in-house usage analytics tools and use it to understand how your use and needs can improve our products. This information includes clicks, scrolls, features accessed, access time and frequency, errors generated, performance data, storage utilized, user settings and configurations, and devices used to access and their locations.
                                </p>
                            </ol>
                            <h2 className="text-2xl font-bold underline mb-4">
                                Information that we collect from third parties:
                            </h2>
                            <ol className="list-decimal-roman list-outside pl-6 mb-4">
                                <li className="text-gray-700 font-semibold pb-2">
                                    i. Signups using federated authentication service providers:
                                </li>
                                <p className="text-gray-700 mb-4">
                                    You can log in to Pankh Services using supported federated authentication service providers such as LinkedIn, Microsoft, and Google. These services will authenticate your identity and give you the option to share certain personal information with us, such as your name and email address.
                                </p>
                                <li className="text-gray-700 font-semibold pb-2">
                                    ii. Referrals:
                                </li>
                                <p className="text-gray-700 mb-4">
                                    If someone has referred any of our products or services to you through any of our referral programs, that person may have provided us your name, email address, and other personal information. You may contact us at {" "}<a href="mailto:support@pankh.io" className="text-blue-500 hover:underline">
                                        support@pankh.io
                                    </a>
                                    {" "} to request that we remove your information from our database. If you provide us information about another person, or if another person gives us your information, we will only use that information for the specific reason for which it was provided to us.
                                </p>
                                <li className="text-gray-700 font-semibold pb-2">
                                    iii. Information from our reselling partners and service providers:
                                </li>
                                <p className="text-gray-700 mb-4">
                                    If you contact any of our reselling partners, or otherwise express interest in any of our products or services to them, the reselling partner may pass your name, email address, company name, and other information to Pankh. If you register for or attend an event that is sponsored by Pankh, the event organizer may share your information with us. Pankh may also receive information about you from review sites if you comment on any review of our products and services, and from other third-party service providers that we engage for marketing our products and services.
                                </p>
                                <li className="text-gray-700 font-semibold pb-2">
                                    iv. Information from social media sites and other publicly available sources:
                                </li>
                                <p className="text-gray-700 mb-4">
                                    When you provide feedback or reviews about our products, interact, or engage with us on marketplaces, review sites, or social media sites such as Facebook, X, LinkedIn, and Instagram through posts, comments, questions, and other interactions, we may collect such publicly available information, including profile information, to allow us to connect with you, improve our products, better understand user reactions and issues or to reproduce and publish your feedback on our websites. We must tell you that once collected, this information may remain with us even if you delete it from these sites. Pankh may also add and update information about you, from other publicly available sources.
                                </p>
                            </ol>
                            <h2 className="text-2xl font-semibold mb-4">
                                Purposes for Using Information:
                            </h2>
                            <p className="text-gray-700 mb-4">
                                In addition to the purposes mentioned above, we may use your information for the following purposes:
                            </p>
                            <ol className="list-decimal-roman list-outside pl-6 mb-4">
                                <li className="text-gray-700 font-semibold pb-2">
                                    To Communicate with You:
                                </li>
                                <p className="text-gray-700 mb-4">
                                    We may reach out to you, primarily through email, to provide updates on products you've accessed, services you've signed up for, changes to this Privacy Policy, alterations to the Terms of Service, or important notices.
                                </p>
                                <li className="text-gray-700 font-semibold pb-2">
                                    To Keep You Informed:
                                </li>
                                <p className="text-gray-700 mb-4">
                                    Our goal is to keep you informed about new products, upcoming events, special offers, promotions, and other information that we believe will be of interest to you.
                                </p>
                                <li className="text-gray-700 font-semibold pb-2">
                                    To Gather Feedback:
                                </li>
                                <p className="text-gray-700 mb-4">
                                    We might invite you to participate in surveys or seek feedback on our products and services to enhance your experience.
                                </p>
                                <li className="text-gray-700 font-semibold pb-2">
                                    Understanding User Behavior:
                                </li>
                                <p className="text-gray-700 mb-4">
                                    Analyzing how users interact with our products and services helps us monitor and prevent issues, ultimately improving our offerings based on user behavior.
                                </p>
                                <li className="text-gray-700 font-semibold pb-2">
                                    Customer Support and Interaction Analysis:
                                </li>
                                <p className="text-gray-700 mb-4">
                                    Our aim is to provide exceptional customer support. We analyze and improve our interactions with customers, ensuring a seamless experience.
                                </p>
                                <li className="text-gray-700 font-semibold pb-2">
                                    Fraud Detection and Prevention:
                                </li>
                                <p className="text-gray-700 mb-4">
                                    Detecting and preventing fraudulent transactions and illegal activities, reporting spam, and safeguarding the rights and interests of Pankh, our users, third parties, and the public are crucial aspects of our data usage.
                                </p>
                                <li className="text-gray-700 font-semibold pb-2">
                                    Record Management and Customer Insights:
                                </li>
                                <p className="text-gray-700 mb-4">
                                    We continually update, expand, and analyze our records, identifying new customers and providing products and services tailored to your interests.
                                </p>
                                <li className="text-gray-700 font-semibold pb-2">
                                    Trend Analysis and Website Administration:
                                </li>
                                <p className="text-gray-700 mb-4">
                                    Analyzing trends, administering our websites, and tracking visitor navigations help us understand user needs and improve their overall experience.
                                </p>
                                <li className="text-gray-700 font-semibold pb-2">
                                    Marketing Campaign Monitoring:
                                </li>
                                <p className="text-gray-700 mb-4">
                                    We monitor and enhance marketing campaigns, making suggestions relevant to the user's preferences and interests.
                                </p>
                            </ol>
                            <h2 className="text-2xl font-semibold mb-4">
                                Legal Bases for Collecting and Using Information:
                            </h2>
                            <p className="text-gray-700 mb-4">
                                If you are an individual from the European Economic Area (EEA), our legal basis for information collection and use depends on the personal information concerned and the context in which we collect it. Most of our information collection and processing activities are typically based on (i) contractual necessity, (ii) one or more legitimate interests of Pankh or a third party that are not overridden by your data protection interests, or (iii) your consent. Sometimes, we may be legally required to collect your information or may need your personal information to protect your vital interests or those of another person.
                            </p>
                            <ol className="list-decimal-roman list-outside pl-6 mb-4">
                                <li className="text-gray-700 font-semibold pb-2">
                                    Withdrawal of Consent:
                                </li>
                                <p className="text-gray-700 mb-4">
                                    Where we rely on your consent as the legal basis, you have the right to withdraw your consent at any time, but this will not affect any processing that has already taken place.
                                </p>
                                <li className="text-gray-700 font-semibold pb-2">
                                    Legitimate Interests Notice:
                                </li>
                                <p className="text-gray-700 mb-4">
                                    Where we rely on legitimate interests as the legal basis and those legitimate interests are not specified above, we will clearly explain to you what those legitimate interests are at the time we collect your information.
                                </p>
                            </ol>
                            <h2 className="text-2xl font-semibold mb-4">
                                Your Choice in Information Use:
                            </h2>
                            <ol className="list-decimal-roman list-outside pl-6 mb-4">
                                <li className="text-gray-700 font-semibold pb-2">
                                    Opt-Out of Non-essential Electronic Communications:
                                </li>
                                <p className="text-gray-700 mb-4">
                                    You may opt out of receiving newsletters and other non-essential messages by using the 'unsubscribe' function included in all such messages. However, you will continue to receive essential notices and emails such as account notification emails (password change, renewal reminders, etc.), security incident alerts, security and privacy update notifications, and essential transactional and payment-related emails.
                                </p>
                                <li className="text-gray-700 font-semibold pb-2">
                                    Disable Cookies:
                                </li>
                                <p className="text-gray-700 mb-4">
                                    You can disable browser cookies before visiting our websites. However, if you do so, you may not be able to use certain features of the websites properly.
                                </p>
                                <li className="text-gray-700 font-semibold pb-2">
                                    Optional Information:
                                </li>
                                <p className="text-gray-700 mb-4">
                                    You can choose not to provide optional profile information such as your photo. You can also delete or change your optional profile information. You can always choose not to fill in non-mandatory fields when you submit any form linked to our websites.
                                </p>
                            </ol>
                            <h2 className="text-2xl font-semibold mb-4">
                                Who do we share your information with:
                            </h2>
                            <p className="text-gray-700 mb-4">
                                Pankh Global Solution Private Limited has access to the information covered in Part I. We do not sell any personal information. We share your information only in the ways that are described in this Privacy Policy, and only with parties who adopt appropriate confidentiality and security measures.
                            </p>
                            <ol className="list-decimal-roman list-outside pl-6 mb-4">
                                <li className="text-gray-700 font-semibold pb-2">
                                    Employees and independent contractors:
                                </li>
                                <p className="text-gray-700 mb-4">
                                    Employees and independent contractors of relevant Pankh Global Solutions Private Limited have access to the information covered in Part I on a need-to-know basis. We require all employees and independent contractors of Pankh Global Solutions Private Limited to follow this Privacy Policy for personal information that we share with them.
                                </p>
                                <li className="text-gray-700 font-semibold pb-2">
                                    Third-party service providers:
                                </li>
                                <p className="text-gray-700 mb-4">
                                    We may need to share your personal information and aggregated or de-identified information with third-party service providers that we engage, such as marketing and advertising partners, event organizers, web analytics providers, and payment processors. These service providers are authorized to use your personal information only as necessary to provide these services to us.
                                </p>
                                <li className="text-gray-700 font-semibold pb-2">
                                    Domain registrars:
                                </li>
                                <p className="text-gray-700 mb-4">
                                    When you register a domain through Pankh from domain name registrars, we share your name and contact information such as your physical address, email address, and phone number with them as per the ICANN domain registration rules.
                                </p>
                                <li className="text-gray-700 font-semibold pb-2">
                                    Reselling partners:
                                </li>
                                <p className="text-gray-700 mb-4">
                                    We may share your personal information with our authorized reselling partners in your region, solely for the purpose of contacting you about products that you have downloaded or services that you have signed up for. We will give you an option to opt out of continuing to work with that partner.
                                </p>
                                <li className="text-gray-700 font-semibold pb-2">
                                    Marketplace application developers:
                                </li>
                                <p className="text-gray-700 mb-4">
                                    When you install or purchase any application developed using Pankh's APIs that is posted on Pankh’s online marketplace, your name and email address will be shared with the developer of the application so they may engage with you directly as the provider of that application or service. Pankh does not control the use of your personal information by the developers, which will be based on their own privacy policies.
                                </p>
                                <li className="text-gray-700 font-semibold pb-2">
                                    Other cases:
                                </li>
                                <p className="text-gray-700 mb-4">
                                    Other scenarios in which we may share the same information covered under Parts I and II are described in Part III.
                                </p>
                            </ol>
                            <h2 className="text-2xl font-semibold mb-4">
                                Your Rights with Respect to Information We Hold About You as a Controller:
                            </h2>
                            <p className="text-gray-700 mb-4">
                                If you are in the European Economic Area (EEA), you have the following rights with respect to information that Pankh holds about you. Pankh undertakes to provide you the same rights no matter where you choose to live.
                            </p>
                            <ol className="list-decimal-roman list-outside pl-6 mb-4">
                                <li className="text-gray-700 font-semibold pb-2">
                                    Right to Access:
                                </li>
                                <p className="text-gray-700 mb-4">
                                    You have the right to access (and obtain a copy of, if required) the categories of personal information that we hold about you, including the information's source, purpose, and period of processing, and the persons to whom the information is shared.
                                </p>
                                <li className="text-gray-700 font-semibold pb-2">
                                    Right to Rectification:
                                </li>
                                <p className="text-gray-700 mb-4">
                                    You have the right to update the information we hold about you or to rectify any inaccuracies. Based on the purpose for which we use your information, you can instruct us to add supplemental information about you in our database.
                                </p>
                                <li className="text-gray-700 font-semibold pb-2">
                                    Right to Erasure:
                                </li>
                                <p className="text-gray-700 mb-4">
                                    You have the right to request that we delete your personal information in certain circumstances, such as when it is no longer necessary for the purpose for which it was originally collected.
                                </p>
                                <li className="text-gray-700 font-semibold pb-2">
                                    Right to Restriction of Processing:
                                </li>
                                <p className="text-gray-700 mb-4">
                                    You may also have the right to request to restrict the use of your information in certain circumstances, such as when you have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.
                                </p>
                                <li className="text-gray-700 font-semibold pb-2">
                                    Right to Data Portability:
                                </li>
                                <p className="text-gray-700 mb-4">
                                    You have the right to transfer your information to a third party in a structured, commonly used, and machine-readable format, in circumstances where the information is processed with your consent or by automated means.
                                </p>
                                <li className="text-gray-700 font-semibold pb-2">
                                    Right to Object:
                                </li>
                                <p className="text-gray-700 mb-4">
                                    You have the right to object to the use of your information in certain circumstances, such as the use of your personal information for direct marketing.
                                </p>
                                <li className="text-gray-700 font-semibold pb-2">
                                    Right to Complain:
                                </li>
                                <p className="text-gray-700 mb-4">
                                    You have the right to complain to the appropriate supervisory authority if you have any grievance against the way we collect, use, or share your information. This right may not be available to you if there is no supervisory authority dealing with data protection in your country.
                                </p>
                            </ol>
                            <h2 className="text-2xl font-semibold mb-4">
                                Retention of Information:
                            </h2>
                            <p className="text-gray-700 mb-4">
                                We retain your personal information for as long as it is required for the purposes stated in this Privacy Policy. Sometimes, we may retain your information for longer periods as permitted or required by law, such as to maintain suppression lists, prevent abuse, if required in connection with a legal claim or proceeding, to enforce our agreements, for tax, accounting, or to comply with other legal obligations. When we no longer have a legitimate need to process your information, we will delete or anonymize your information from our active databases. We will also securely store the information and isolate it from further processing on backup discs until deletion is possible.
                            </p>
                            <h2 className="text-3xl text-center font-semibold mb-4 mt-4">
                                Part II – Information that Pankh processes on your behalf
                            </h2>
                            <h2 className="text-2xl font-bold  mb-4">
                                Information Entrusted to Pankh and Purpose:
                            </h2>
                            <ol className="list-decimal-roman list-outside pl-6 mb-4">
                                <li className="text-gray-700 font-semibold pb-2">
                                    Information Provided in Connection with Services:
                                </li>
                                <p className="text-gray-700 mb-4">
                                    You may entrust information that you or your organization (“you”) control to Pankh in connection with the use of our services or for requesting technical support for our products. This includes information regarding your customers and your employees (if you are a controller) or data that you hold and use on behalf of another person for a specific purpose, such as a customer to whom you provide services (if you are a processor). The data may either be stored on our servers when you use our services or transferred or shared with us as part of a request for technical support or other services.
                                </p>
                                <li className="text-gray-700 font-semibold pb-2">
                                    Information from Mobile Devices:
                                </li>
                                <p className="text-gray-700 mb-4">
                                    When you elect to allow it, some of our mobile applications have access to the camera, microphone, call history, contact information, photo library, files, and other information stored on your mobile device. Our applications require such access to provide their services. Similarly, when you elect to provide access, location-based information is also collected for purposes, including locating nearby contacts or setting location-based reminders. This information will be exclusively shared with our mapping providers and will be used only for mapping user locations. You may disable the mobile applications' access to this information at any time by editing the settings on your mobile device. The data stored on your mobile device and the location information to which the mobile applications have access will be used in the context of the mobile application and transferred to and associated with your account in the corresponding services (in which case the data will be stored on our servers) or products (in which case the data will remain with you unless you share it with us).
                                </p>
                                <p className="text-gray-700 mb-4">
                                    (All the information entrusted to Pankh is collectively termed “service data”)
                                </p>
                                <li className="text-gray-700 font-semibold pb-2">
                                    iii. Testimonials:
                                </li>
                                <p className="text-gray-700 mb-4">
                                    When you authorize us to post testimonials about our products and services on websites, we may include your name and other personal information in the testimonial. You will be given an opportunity to review and approve the testimonial before we post it. If you wish to update or delete your testimonial, you can contact us at support@pankh.io
                                </p>
                                <li className="text-gray-700 font-semibold pb-2">
                                    iv. Interactions with Pankh:
                                </li>
                                <p className="text-gray-700 mb-4">
                                    We may record, analyze, and use your interactions with us, including email, telephone, and chat conversations with our sales and customer support professionals, for improving our interactions with you and other customers.
                                </p>
                            </ol>
                            <h2 className="text-2xl font-bold  mb-4">
                                Ownership and Control of Your Service Data:
                            </h2>
                            <p className="text-gray-700 mb-4">
                                We recognize that you own your service data. We provide you complete control of your service data by providing you the ability to (i) access your service data, (ii) share your service data through supported third-party integrations, and (iii) request export or deletion of your service data.
                            </p>
                            <h2 className="text-2xl font-bold  mb-4">
                                How We Use Service Data:
                            </h2>
                            <p className="text-gray-700 mb-4">
                                We process your service data when you provide us instructions through the various modules of our services. For example, when you generate an invoice using our invoicing service, information such as the name and address of your customer will be used to generate the invoice. When you use our campaign management service for email marketing, the email addresses of the persons on your mailing list will be used for sending the emails.
                            </p>
                            <h2 className="text-2xl font-bold  mb-4">
                                Push Notifications:
                            </h2>
                            <p className="text-gray-700 mb-4">
                                If you have enabled notifications on our desktop and mobile applications, we will push notifications through a push notification provider such as Apple Push Notification Service, Google Cloud Messaging, or Windows Push Notification Services. You can manage your push notification preferences or deactivate these notifications by turning off notifications in the application or device settings.
                            </p>
                            <h2 className="text-2xl font-bold  mb-4">
                                Who do we share service data with
                            </h2>
                            <ol className="list-decimal-roman list-outside pl-6 mb-4">
                                <li className="text-gray-700 font-semibold pb-2">
                                    Pankh and third-party sub-processors:
                                </li>
                                <p className="text-gray-700 mb-4">
                                    In order to provide services and technical support for our products, the contracting entity within Pankh Global Solutions Private Limited and third parties include {" "}
                                    <a href="https://aws.amazon.com/free/?gclid=EAIaIQobChMI7v_Qn4npggMVGSCDAx1QFw9LEAAYASAAEgITWvD_BwE&trk=14a4002d-4936-4343-8211-b5a150ca592b&sc_channel=ps&ef_id=EAIaIQobChMI7v_Qn4npggMVGSCDAx1QFw9LEAAYASAAEgITWvD_BwE:G:s&s_kwcid=AL!4422!3!453325184794!e!!g!!amazon%20web%20services!10712784856!111477279811&all-free-tier.sort-by=item.additionalFields.SortRank&all-free-tier.sort-order=asc&awsf.Free%20Tier%20Types=*all&awsf.Free%20Tier%20Categories=*all" className="text-blue-500 hover:underline">
                                        AWS by Amazon
                                    </a> {", "}
                                    <a href="https://www.plivo.com/" className="text-blue-500 hover:underline">
                                        Plivo
                                    </a> {", "}
                                    <a href="https://cloud.google.com/" className="text-blue-500 hover:underline">
                                        GCP by Google
                                    </a> {", "}
                                    <a href="https://msg91.com/in" className="text-blue-500 hover:underline">
                                        MSG91
                                    </a> {", "}
                                    <a href="https://www.deskera.com/in/" className="text-blue-500 hover:underline">
                                        Deskera.com
                                    </a> {", "}
                                    <a href="https://trueconnect.jio.com/#/" className="text-blue-500 hover:underline">
                                        TrueConnect Platform (jio.com)
                                    </a> and {" "}
                                    <a href="https://busy.in/" className="text-blue-500 hover:underline">
                                        Busy Software
                                    </a>.
                                </p>
                                <li className="text-gray-700 font-semibold pb-2">
                                    Employees and independent contractors:
                                </li>
                                <p className="text-gray-700 mb-4">
                                    We may provide access to your service data to our employees and individuals who are independent contractors of the Pankh Global Solutions Private Limited involved in providing the services (collectively our “employees”) so that they can (i) identify, analyze and resolve errors, (ii) manually verify emails reported as spam to improve spam detection, or (iii) manually verify scanned images that you submit to us to verify the accuracy of optical character recognition. We ensure that access by our employees to your service data is restricted to specific individuals, and is logged and audited. Our employees will also have access to data that you knowingly share with us for technical support or to import data into our products or services. We communicate our privacy and security guidelines to our employees and strictly enforce privacy safeguards within Pankh Global Solutions Private Limited.
                                </p>
                                <li className="text-gray-700 font-semibold pb-2">
                                    Collaborators and other users:
                                </li>
                                <p className="text-gray-700 mb-4">
                                    Some of our products or services allow you to collaborate with other users or third parties. Initiating collaboration may enable other collaborators to view some or all of your profile information. For example, when you edit a document that you have shared with other persons for collaboration, your name and profile picture will be displayed next to your edits to allow your collaborators to know that you made those edits.
                                </p>
                                <li className="text-gray-700 font-semibold pb-2">
                                    Third-party integrations you have enabled:
                                </li>
                                <p className="text-gray-700 mb-4">
                                    Most of our products and services support integrations with third-party products and services that may include payment gateways (PhonePe) and Accounting software (Tally, BUSY accounting). If you choose to enable any third-party integrations, you may be allowing the third party to access your service data and personal information about you. We encourage you to review the privacy practices of third-party services and products before you enable integrations with them.
                                </p>
                                <li className="text-gray-700 font-semibold pb-2">
                                    Other cases:
                                </li>
                                <p className="text-gray-700 mb-4">
                                    Other scenarios in which we may share information that are common to information covered under Parts I and II are described in Part III.
                                </p>
                            </ol>
                            <h2 className="text-2xl font-bold  mb-4">
                                Retention of information
                            </h2>
                            <p className="text-gray-700 mb-4">
                                We hold the data in your account as long as you choose to use Pankh Services. Once you terminate your Pankh user account, your data will eventually get deleted from active database during the next clean-up that occurs once in 6 months. The data deleted from the active database will be deleted from backups after 3 months.
                            </p>
                            <h2 className="text-2xl font-bold  mb-4">
                                Data subject requests
                            </h2>
                            <p className="text-gray-700 mb-4">
                                If you are from the European Economic Area and you believe that we store, use or process your information on behalf of one of our customers, please contact the customer if you would like to access, rectify, erase, restrict or object to processing, or export your personal data. We will extend our support to our customer in responding to your request within a reasonable timeframe.
                            </p>
                            <h2 className="text-3xl text-center font-semibold mb-4 mt-4">
                                Part III – General
                            </h2>
                            <h2 className="text-2xl font-bold mb-4">
                                Children’s Personal Information:
                            </h2>
                            <p className="text-gray-700 mb-4">
                                Our products and services are not directed to individuals under 16. Pankh does not knowingly collect personal information from children who are under 16 years of age for its own purposes. If we become aware that a child under 16 has provided us with personal information, we will take steps to delete such information. If you believe that a child under 16 years has provided personal information to us, please write to {" "}
                                <a href="mailto:support@pankh.io" className="text-blue-500 hover:underline">
                                    support@pankh.io
                                </a>
                                {" "}
                                with the details, and we will take the necessary steps to delete the information we hold about that child. However, using our products, you can collect information about individuals who may be children. If you process information relating to children, you acknowledge and agree that you will be responsible for complying with the applicable laws and regulations related to the protection of such personal information.
                            </p>
                            <h2 className="text-2xl font-bold mb-4">
                                How Secure is Your Information:
                            </h2>
                            <p className="text-gray-700 mb-4">
                                At Pankh, we take data security very seriously. We have taken steps to implement appropriate administrative, technical & physical safeguards to prevent unauthorized access, use, modification, disclosure or destruction of the information you entrust to us. If you have any concerns regarding the security of your data, we encourage you to write to us at {" "} <a href="mailto:support@pankh.io" className="text-blue-500 hover:underline">
                                    support@pankh.io
                                </a> {" "}
                                with any questions.
                            </p>
                            <h2 className="text-2xl font-bold mb-4">
                                Automation and Artificial Intelligence:
                            </h2>
                            <p className="text-gray-700 mb-4">
                                In order to provide enhanced productivity and predictive capabilities to our users, we employ a variety of technologies such as regex parsing, template matching, artificial intelligence, and machine learning. In keeping with Pankh's promise not to exploit your data in a way that is not respectful of your privacy and confidentiality expectations, we make only the following limited use of service data for these technologies: (i) using anonymized crops of service data to improve the accuracy of the algorithms; and (ii) using your organization's data for developing models specific to your organization. Our automation and artificial intelligence technologies are mostly powered by our own organization's data, such as internal communications, communications with customers, and internal documents, as well as free and paid external sources.
                            </p>
                            <h2 className="text-2xl font-bold mb-4">
                                Do Not Track (DNT) Requests:
                            </h2>
                            <p className="text-gray-700 mb-4">
                                Some internet browsers have enabled 'Do Not Track' (DNT) features, which send out a signal (called the DNT signal) to the websites that you visit indicating that you don't wish to be tracked. Currently, there is no standard that governs what websites can or should do when they receive these signals. For now, we do not take action in response to these signals.
                            </p>
                            <h2 className="text-2xl font-bold mb-4">
                                External Links on Our Websites:
                            </h2>
                            <p className="text-gray-700 mb-4">
                                Some pages of our websites may contain links to websites that are not linked to this Privacy Policy. If you submit your personal information to any of these third-party sites, your personal information is governed by their privacy policies. As a safety measure, we recommend that you not share any personal information with these third parties unless you've checked their privacy policies and assured yourself of their privacy practices.
                            </p>
                            <h2 className="text-2xl font-bold mb-4">
                                Blogs and Forums:
                            </h2>
                            <p className="text-gray-700 mb-4">
                                We offer publicly accessible blogs and forums on our websites. Please be aware that any information you provide on these blogs and forums may be used to contact you with unsolicited messages. We urge you to be cautious in disclosing personal information in our blogs and forums. Pankh is not responsible for the personal information you elect to disclose publicly. Your posts and certain profile information may remain even after you terminate your account with Pankh. To request the removal of your information from our blogs and forums, you can contact us at {" "}
                                <a href="mailto:support@pankh.io" className="text-blue-500 hover:underline">
                                    support@pankh.io
                                </a>.
                            </p>
                            <h2 className="text-2xl font-bold mb-4">
                                Social Media Widgets:
                            </h2>
                            <p className="text-gray-700 mb-4">
                                Our websites include social media widgets such as Facebook "like" buttons and Twitter "tweet" buttons that let you share articles and other information. These widgets may collect information such as your IP address and the pages you navigate on the website, and may set a cookie to enable the widgets to function properly. Your interactions with these widgets are governed by the privacy policies of the companies providing them.
                            </p>
                            <h2 className="text-2xl font-bold mb-4">
                                Disclosures in Compliance with Legal Obligations:
                            </h2>
                            <p className="text-gray-700 mb-4">
                                We may be required by law to preserve or disclose your personal information and service data to comply with any applicable law, regulation, legal process, or governmental request, including meeting national security requirements.
                            </p>
                            <h2 className="text-2xl font-bold mb-4">
                                Enforcement of Our Rights:
                            </h2>
                            <p className="text-gray-700 mb-4">
                                We may disclose personal information and service data to a third party if we believe that such disclosure is necessary for preventing fraud, spam filtering, investigating any suspected illegal activity, enforcing our agreements or policies, or protecting the safety of our users.
                            </p>
                            <h2 className="text-2xl font-bold mb-4">
                                Business Transfers:
                            </h2>
                            <p className="text-gray-700 mb-4">
                                We do not intend to sell our business. However, in the unlikely event that we sell our business or get acquired or merged, we will ensure that the acquiring entity is legally bound to honor our commitments to you. We will notify you via email or through a prominent notice on our website of any change in ownership or in the uses of your personal information and service data. We will also notify you about any choices you may have regarding your personal information and service data.
                            </p>
                            <h2 className="text-2xl font-bold mb-4">
                                Compliance with this Privacy Policy:
                            </h2>
                            <p className="text-gray-700 mb-4">
                                We make every effort, including periodic reviews, to ensure that personal information you provide is used in conformity with this Privacy Policy. If you have any concerns about our adherence to this Privacy Policy or the manner in which your personal information is used, kindly write to us at {" "}
                                <a href="mailto:support@pankh.io" className="text-blue-500 hover:underline">
                                    support@pankh.io.
                                </a>
                                {" "} We'll contact you and, if required, coordinate with the appropriate regulatory authorities to effectively address your concerns.
                            </p>
                            <h2 className="text-2xl font-bold mb-4">
                                Notification of Changes:
                            </h2>
                            <p className="text-gray-700 mb-4">
                                We may modify the Privacy Policy at any time, upon notifying you through a service announcement or by sending an email to your primary email address. If we make significant changes to the Privacy Policy that affect your rights, you will be provided with at least 30 days' advance notice of the changes by email to your primary email address. However, if you have not verified your email address, you may miss important notifications that we send through email. If you think that the updated Privacy Policy affects your rights with respect to your use of our products or services, you may terminate your use by sending us an email within 30 days. Your continued use after the effective date of changes to the Privacy Policy will be deemed to be your agreement to the modified Privacy Policy. You will not receive email notification of minor changes to the Privacy Policy. If you are concerned about how your personal information is used, you should check back at {" "}
                                <a href="https://www.pankh.io/privacyPolicy" className="text-blue-500 hover:underline">
                                    https://www.pankh.io/privacypolicy
                                </a>
                                {" "}periodically.
                            </p>
                        </section>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default PrivacyPolicy;