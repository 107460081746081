import React from "react";
import Navbar from "../../components/navbar";
import image from "../../assets/image/blogimg.png";
import image1 from "../../assets/image/bloglogo.png";
import Footer from "../../components/footer";

export default function Blogs() {
  return (
    <div className="font-nunito">
      <Navbar heading={"Blogs"} />
      <div className="md:px-[25px] px-5 ">
        <div className="md:flex md:flex-col md:justify-center md:items-center">
          <img src={image} alt="#" className="md:mt-[60px] mt-[28px] md:mb-[60px] mb-[25px]" />
        </div>
        <div>
          <p className="md:text-[24px] text-[18px] text-center text-[#747474] font-[500] md:font-[600] md:ml-[25px] md:mr-[15px]">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacus
            pretium dolor, id nulla tincidunt dolor. Dui, pharetra, tortor vel
            enim vulputate duis ac nisi. Imperdiet non feugiat lectus ipsum.
            Netus blandit nisi.
          </p>
          <div className="hidden md:flex md:mb-[50px] md:mt-[40px]  justify-between ">
            <div className="flex">
              <div className="md:w-[52px] md:h-[52px] rounded-[50%] bg-[#124870] md:ml-[25px]">
                <img src={image1} alt="" />
              </div>
              <div className="flex md:mt-[10px]">
                <h2 className="text-[#124870] md:text-[24px] font-[700]  ml-[10px]">
                  Alex Nathon,
                </h2>
                <h3 className="text-[#615F5FB8] md:text-[20px] ml-[10px] md:mt-[5px] font-[600]">
                  Fab 25, 2022
                </h3>
              </div>
            </div>
            <div className="flex md:gap-[12px]">
              <div className="md:w-[40px] md:h-[40px] rounded-[50%] bg-[#FFFFFF] border-[1px] border-[#7474742E] flex justify-center items-center">
                <svg
                  width="9"
                  height="19"
                  viewBox="0 0 9 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.46502 18.1497V10.0164H0.363281V7.08806H2.46502V4.58684C2.46502 2.62136 3.70652 0.816406 6.56721 0.816406C7.72546 0.816406 8.58193 0.930026 8.58193 0.930026L8.51445 3.66464C8.51445 3.66464 7.64098 3.65594 6.68782 3.65594C5.65621 3.65594 5.49094 4.1424 5.49094 4.9498V7.08806H8.59645L8.46132 10.0164H5.49094V18.1497H2.46502Z"
                    fill="#DA6C08"
                  />
                </svg>
              </div>
              <div className="md:w-[40px] md:h-[40px] rounded-[50%] bg-[#FFFFFF] border-[1px] border-[#7474742E] flex justify-center items-center">
                <svg
                  width="22"
                  height="18"
                  viewBox="0 0 22 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.5832 2.64199C20.8241 2.98943 20.0087 3.22419 19.1514 3.33031C20.036 2.78359 20.6977 1.92312 21.0132 0.909455C20.1822 1.41934 19.2726 1.77825 18.3241 1.97057C17.6863 1.2671 16.8415 0.800832 15.9208 0.644151C15.0001 0.48747 14.0551 0.649144 13.2325 1.10407C12.4099 1.559 11.7557 2.28174 11.3715 3.16006C10.9873 4.03839 10.8946 5.02316 11.1078 5.9615C9.42385 5.87417 7.77652 5.42207 6.2727 4.63455C4.76888 3.84702 3.44217 2.74168 2.37868 1.39024C2.01504 2.03818 1.80595 2.78942 1.80595 3.58948C1.80555 4.30972 1.97725 5.01893 2.30584 5.65418C2.63443 6.28944 3.10974 6.83109 3.68959 7.23109C3.01711 7.20898 2.35947 7.02129 1.77141 6.68362V6.73997C1.77134 7.75013 2.10962 8.72921 2.72885 9.51108C3.34807 10.2929 4.21011 10.8294 5.16868 11.0295C4.54485 11.2039 3.8908 11.2296 3.25595 11.1046C3.5264 11.9738 4.05322 12.7339 4.76265 13.2784C5.47208 13.823 6.3286 14.1248 7.21232 14.1415C5.71216 15.3579 3.85949 16.0178 1.95232 16.0149C1.61448 16.015 1.27693 15.9946 0.941406 15.9538C2.87729 17.2396 5.13081 17.9219 7.43232 17.9193C15.2232 17.9193 19.4823 11.2539 19.4823 5.4732C19.4823 5.28539 19.4778 5.0957 19.4696 4.9079C20.298 4.28904 21.0131 3.52271 21.5814 2.6448L21.5832 2.64199Z"
                    fill="#DA6C08"
                  />
                </svg>
              </div>
              <div className="md:w-[40px] md:h-[40px] rounded-[50%] bg-[#FFFFFF] border-[1px] border-[#7474742E] flex justify-center items-center">
                <svg
                  width="19"
                  height="20"
                  viewBox="0 0 19 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.91016 0.910156C2.70102 0.910156 0.910156 2.70102 0.910156 4.91016V15.092C0.910156 17.3011 2.70102 19.092 4.91016 19.092H14.6788C16.8879 19.092 18.6788 17.3011 18.6788 15.092V4.91015C18.6788 2.70102 16.8879 0.910156 14.6788 0.910156H4.91016ZM6.87542 15.3522C5.72403 15.3522 4.79064 14.4189 4.79064 13.2675V8.94765C4.79064 8.37198 5.25731 7.9053 5.83299 7.9053C6.40867 7.9053 6.87534 8.37198 6.87534 8.94766V15.3522C6.87534 15.3522 6.87538 15.3522 6.87542 15.3522ZM5.8536 7.0561C5.15433 7.0561 4.58547 6.51634 4.58547 5.85296C4.58547 5.18957 5.1544 4.64981 5.8536 4.64981C6.5528 4.64981 7.12174 5.18957 7.12174 5.85296C7.12174 6.51634 6.5528 7.0561 5.8536 7.0561ZM15.0034 14.2842C15.0034 14.8741 14.5253 15.3522 13.9354 15.3522C13.3456 15.3522 12.8674 14.8741 12.8674 14.2842V10.9357C12.8678 10.9267 12.899 10.2256 12.5105 9.80675C12.3171 9.59823 12.0508 9.49251 11.7187 9.49251C11.349 9.49251 11.0603 9.61307 10.8378 9.77978C10.3622 10.1361 10.2827 10.7892 10.2827 11.3834V14.3099C10.2827 14.8855 9.81597 15.3522 9.24027 15.3522C8.66458 15.3522 8.19788 14.8855 8.19788 14.3099V8.94765C8.19788 8.37198 8.66456 7.9053 9.24024 7.9053H10.0816C10.1926 7.9053 10.2826 7.9953 10.2826 8.10632C10.2826 8.27657 10.4837 8.37268 10.6248 8.27742C11.2166 7.87793 11.8581 7.66906 12.5038 7.66906C14.481 7.66906 15.0034 9.24946 15.0034 10.0851V14.2842Z"
                    fill="#DA6C08"
                  />
                </svg>
              </div>
              <div className="md:w-[40px] md:h-[40px] rounded-[50%] bg-[#FFFFFF] border-[1px] border-[#7474742E] flex justify-center items-center">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.99488 6.96557C8.32557 6.96557 6.96329 8.32785 6.96329 9.99716C6.96329 11.6665 8.32557 13.0288 9.99488 13.0288C11.6642 13.0288 13.0265 11.6665 13.0265 9.99716C13.0265 8.32785 11.6642 6.96557 9.99488 6.96557ZM19.0874 9.99716C19.0874 8.74177 19.0988 7.49774 19.0283 6.24462C18.9578 4.78909 18.6257 3.49731 17.5614 2.43296C16.4947 1.36633 15.2052 1.03656 13.7497 0.966057C12.4943 0.895555 11.2503 0.906926 9.99716 0.906926C8.74176 0.906926 7.49774 0.895555 6.24462 0.966057C4.78909 1.03656 3.49731 1.3686 2.43296 2.43296C1.36633 3.49959 1.03656 4.78909 0.966057 6.24462C0.895555 7.50002 0.906926 8.74404 0.906926 9.99716C0.906926 11.2503 0.895555 12.4966 0.966057 13.7497C1.03656 15.2052 1.3686 16.497 2.43296 17.5614C3.49959 18.628 4.78909 18.9578 6.24462 19.0283C7.50002 19.0988 8.74404 19.0874 9.99716 19.0874C11.2526 19.0874 12.4966 19.0988 13.7497 19.0283C15.2052 18.9578 16.497 18.6257 17.5614 17.5614C18.628 16.4947 18.9578 15.2052 19.0283 13.7497C19.101 12.4966 19.0874 11.2526 19.0874 9.99716ZM9.99488 14.6617C7.41359 14.6617 5.33037 12.5784 5.33037 9.99716C5.33037 7.41587 7.41359 5.33264 9.99488 5.33264C12.5762 5.33264 14.6594 7.41587 14.6594 9.99716C14.6594 12.5784 12.5762 14.6617 9.99488 14.6617ZM14.8504 6.23098C14.2478 6.23098 13.7611 5.74429 13.7611 5.14161C13.7611 4.53893 14.2478 4.05223 14.8504 4.05223C15.4531 4.05223 15.9398 4.53893 15.9398 5.14161C15.94 5.28471 15.9119 5.42645 15.8573 5.5587C15.8026 5.69095 15.7223 5.81112 15.6211 5.91231C15.5199 6.0135 15.3998 6.09374 15.2675 6.14842C15.1353 6.2031 14.9935 6.23116 14.8504 6.23098Z"
                    fill="#DA6C08"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center items-center">
          <h2 className="md:text-[34px] text-[20px] mt-[25px] text-[#615F5F] font-[700] md:ml-[25px] md:mt-[30px]">
            A formula to Win
          </h2>
          </div>
          <p className="md:text-[18px] text-[#747474D6] text-[16px] text-center md:text-left mt-[8px] font-[500] md:ml-[25px] md:mr-[15px] md:mt-[10px]">
            Lörem ipsum nyrar visade. Afyll diskade titrepp i klicktivism
            krosonde treling. Teofili måledes. Smartball pejyl serade
            megafaktisk ködat. Spevis osat, och talpenna. Hire posam åv vinde.
            Potevis måbesm för gasa i oviniras. Mytomatisk eusm bufabel då
            diplar i sms-livräddare dir preheten. Decisade gipåbel: vijyre.
            Dospeskade självoptimering abel reabelt i hemivis, prosast labetoll.
            Curlingförälder nening homotodade makrolagäl. Köment tisk. Nyrar
            rerad nyvis, i ultrar i defili global hektar. Edoktig säktig,
            bloggosfär, röska negigt donat processturism. Unes rött då buvulig
            om ovona. Öråd. Exogt hisk i ankarbarn. Du kan vara drabbad. Rekäse
            onävälingar, kabelt och vuvis. Polytopi denilig färösk. Sesa
            kjolprotest huruvida per i sorade, nårade och til. Pseudona dektig.
            Serar prer att pafupp. Vutosade neska jäbel i syvis.
            Frontbibliotekarie köttberg multiskap relig larat det kevär, jag
            kavarat. Igulig gigassa om pronat plaras dekagt, fastän dinde.
            Tridosm butöheten interaktiv skrivtavla berade hypor sunipp.
            Makrovesek bebunt innan traling beng. Spegt krotodade och
            agnostifas. Heterosa kesugir povemiktigt. Vara krosade i lavanat,
            respektive rövipåra ölogi. Närvaropeng pansexuell inklusive messa
            som kros. Poke katibel langen kamår, om än nyr, proktig astroderas.
            Eurade predade transponder laskapet i vavektig tempogam. Bing tede
            homosär rek nanade. Kron diajunde mansskatt spenyr att paddeltennis
            åvar utom klickfiske. Ses rens eller rutkod. Nyss semigen,
            hjärtslagslag gina i rerad. Triler nitt. Fade conversesjukan. Avoda
            exosade exolår. Vinstvarning paral. Paratism dosade tett. Sugada
            fuktiga semiaktiv dins. Bekens olingar. Mikropatologi prende tibis.
            Vipåsat spen: deciment, fast tera att monobel soguna. Unar
            multivologi. Prok autorårtad att den nya ekonomin om rev. Don
            målaning förutom visk i homosam. Tesade eck åsiktskorridor, uvis
            lådoning astrobivis: renådat. Pressade fadat autonade, multigen
            kontragt. Git tred i euktig utom ogen laligt, multiv: intrateplar.
            Reagram terack ongar: näre rök. Vålingar plasoliga bende homosoling
            heterotöd om on onde.{" "}
          </p>
          <div className="flex flex-col justify-center items-center">

          <h3 className="md:text-[32px] text-[20px] mt-[25px] text-[#615F5F] font-[700] md:ml-[25px] md:mt-[30px]">
            Real life Experiences
          </h3>
          </div>
          <p className="md:text-[18px] text-[16px] text-center md:text-left mt-[8px] text-[#747474D6] font-[500] md:ml-[25px] md:mr-[15px] md:mt-[10px]">
            Lörem ipsum nyrar visade. Afyll diskade titrepp i klicktivism
            krosonde treling. Teofili måledes. Smartball pejyl serade
            megafaktisk ködat. Spevis osat, och talpenna. Hire posam åv vinde.
            Potevis måbesm för gasa i oviniras. Mytomatisk eusm bufabel då
            diplar i sms-livräddare dir preheten. Decisade gipåbel: vijyre.
            Dospeskade självoptimering abel reabelt i hemivis, prosast labetoll.
            Curlingförälder nening homotodade makrolagäl. Köment tisk. Nyrar
            rerad nyvis, i ultrar i defili global hektar. Edoktig säktig,
            bloggosfär, röska negigt donat processturism. Unes rött då buvulig
            om ovona. Öråd. Exogt hisk i ankarbarn. Du kan vara drabbad. Rekäse
            onävälingar, kabelt och vuvis. Polytopi denilig färösk. Sesa
            kjolprotest huruvida per i sorade, nårade och til. Pseudona dektig.
            Serar prer att pafupp. Vutosade neska jäbel i syvis.
            Frontbibliotekarie köttberg multiskap relig larat det kevär, jag
            kavarat. Igulig gigassa om pronat plaras dekagt, fastän dinde.
            Tridosm butöheten interaktiv skrivtavla berade hypor sunipp.
            Makrovesek bebunt innan traling beng. Spegt krotodade och
            agnostifas. Heterosa kesugir povemiktigt. Vara krosade i lavanat,
            respektive rövipåra ölogi. Närvaropeng pansexuell inklusive messa
            som kros. Poke katibel langen kamår, om än nyr, proktig astroderas.
            Eurade predade transponder laskapet i vavektig tempogam. Bing tede
            homosär rek nanade. Kron diajunde mansskatt spenyr att paddeltennis
            åvar utom klickfiske. Ses rens eller rutkod. Nyss semigen,
            hjärtslagslag gina i rerad. Triler nitt. Fade conversesjukan. Avoda
            exosade exolår. Vinstvarning paral. Paratism dosade tett. Sugada
            fuktiga semiaktiv dins. Bekens olingar. Mikropatologi prende tibis.
            Vipåsat spen: deciment, fast tera att monobel soguna. Unar
            multivologi. Prok autorårtad att den nya ekonomin om rev. Don
            målaning förutom visk i homosam. Tesade eck åsiktskorridor, uvis
            lådoning astrobivis: renådat. Pressade fadat autonade, multigen
            kontragt. Git tred i euktig utom ogen laligt, multiv: intrateplar.
            Reagram terack ongar: näre rök. Vålingar plasoliga bende homosoling
            heterotöd om on onde.{" "}
          </p>
          <div className="md:mb-[50px] hidden md:mt-[40px] md:flex justify-between ">
            <div className="flex">
              <div className="w-[52px] h-[52px] rounded-[50%] bg-[#124870] ml-[25px]">
                <img src={image1} alt="" />
              </div>
              <div className="flex md:mt-[10px]">
                <h2 className="text-[#124870] text-[24px] font-[700]  ml-[10px]">
                  Alex Nathon,
                </h2>
                <h3 className="text-[#615F5FB8] md:text-[20px] ml-[10px] md:mt-[5px] font-[600]">
                  Fab 25, 2022
                </h3>
              </div>
            </div>
            <div className="flex gap-[12px]">
              <div className="md:w-[40px] md:h-[40px] rounded-[50%] bg-[#FFFFFF] border-[1px] border-[#7474742E] flex justify-center items-center">
                <svg
                  width="9"
                  height="19"
                  viewBox="0 0 9 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.46502 18.1497V10.0164H0.363281V7.08806H2.46502V4.58684C2.46502 2.62136 3.70652 0.816406 6.56721 0.816406C7.72546 0.816406 8.58193 0.930026 8.58193 0.930026L8.51445 3.66464C8.51445 3.66464 7.64098 3.65594 6.68782 3.65594C5.65621 3.65594 5.49094 4.1424 5.49094 4.9498V7.08806H8.59645L8.46132 10.0164H5.49094V18.1497H2.46502Z"
                    fill="#DA6C08"
                  />
                </svg>
              </div>
              <div className="md:w-[40px] md:h-[40px] rounded-[50%] bg-[#FFFFFF] border-[1px] border-[#7474742E] flex justify-center items-center">
                <svg
                  width="22"
                  height="18"
                  viewBox="0 0 22 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.5832 2.64199C20.8241 2.98943 20.0087 3.22419 19.1514 3.33031C20.036 2.78359 20.6977 1.92312 21.0132 0.909455C20.1822 1.41934 19.2726 1.77825 18.3241 1.97057C17.6863 1.2671 16.8415 0.800832 15.9208 0.644151C15.0001 0.48747 14.0551 0.649144 13.2325 1.10407C12.4099 1.559 11.7557 2.28174 11.3715 3.16006C10.9873 4.03839 10.8946 5.02316 11.1078 5.9615C9.42385 5.87417 7.77652 5.42207 6.2727 4.63455C4.76888 3.84702 3.44217 2.74168 2.37868 1.39024C2.01504 2.03818 1.80595 2.78942 1.80595 3.58948C1.80555 4.30972 1.97725 5.01893 2.30584 5.65418C2.63443 6.28944 3.10974 6.83109 3.68959 7.23109C3.01711 7.20898 2.35947 7.02129 1.77141 6.68362V6.73997C1.77134 7.75013 2.10962 8.72921 2.72885 9.51108C3.34807 10.2929 4.21011 10.8294 5.16868 11.0295C4.54485 11.2039 3.8908 11.2296 3.25595 11.1046C3.5264 11.9738 4.05322 12.7339 4.76265 13.2784C5.47208 13.823 6.3286 14.1248 7.21232 14.1415C5.71216 15.3579 3.85949 16.0178 1.95232 16.0149C1.61448 16.015 1.27693 15.9946 0.941406 15.9538C2.87729 17.2396 5.13081 17.9219 7.43232 17.9193C15.2232 17.9193 19.4823 11.2539 19.4823 5.4732C19.4823 5.28539 19.4778 5.0957 19.4696 4.9079C20.298 4.28904 21.0131 3.52271 21.5814 2.6448L21.5832 2.64199Z"
                    fill="#DA6C08"
                  />
                </svg>
              </div>
              <div className="md:w-[40px] md:h-[40px] rounded-[50%] bg-[#FFFFFF] border-[1px] border-[#7474742E] flex justify-center items-center">
                <svg
                  width="19"
                  height="20"
                  viewBox="0 0 19 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.91016 0.910156C2.70102 0.910156 0.910156 2.70102 0.910156 4.91016V15.092C0.910156 17.3011 2.70102 19.092 4.91016 19.092H14.6788C16.8879 19.092 18.6788 17.3011 18.6788 15.092V4.91015C18.6788 2.70102 16.8879 0.910156 14.6788 0.910156H4.91016ZM6.87542 15.3522C5.72403 15.3522 4.79064 14.4189 4.79064 13.2675V8.94765C4.79064 8.37198 5.25731 7.9053 5.83299 7.9053C6.40867 7.9053 6.87534 8.37198 6.87534 8.94766V15.3522C6.87534 15.3522 6.87538 15.3522 6.87542 15.3522ZM5.8536 7.0561C5.15433 7.0561 4.58547 6.51634 4.58547 5.85296C4.58547 5.18957 5.1544 4.64981 5.8536 4.64981C6.5528 4.64981 7.12174 5.18957 7.12174 5.85296C7.12174 6.51634 6.5528 7.0561 5.8536 7.0561ZM15.0034 14.2842C15.0034 14.8741 14.5253 15.3522 13.9354 15.3522C13.3456 15.3522 12.8674 14.8741 12.8674 14.2842V10.9357C12.8678 10.9267 12.899 10.2256 12.5105 9.80675C12.3171 9.59823 12.0508 9.49251 11.7187 9.49251C11.349 9.49251 11.0603 9.61307 10.8378 9.77978C10.3622 10.1361 10.2827 10.7892 10.2827 11.3834V14.3099C10.2827 14.8855 9.81597 15.3522 9.24027 15.3522C8.66458 15.3522 8.19788 14.8855 8.19788 14.3099V8.94765C8.19788 8.37198 8.66456 7.9053 9.24024 7.9053H10.0816C10.1926 7.9053 10.2826 7.9953 10.2826 8.10632C10.2826 8.27657 10.4837 8.37268 10.6248 8.27742C11.2166 7.87793 11.8581 7.66906 12.5038 7.66906C14.481 7.66906 15.0034 9.24946 15.0034 10.0851V14.2842Z"
                    fill="#DA6C08"
                  />
                </svg>
              </div>
              <div className="md:w-[40px] md:h-[40px] rounded-[50%] bg-[#FFFFFF] border-[1px] border-[#7474742E] flex justify-center items-center">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.99488 6.96557C8.32557 6.96557 6.96329 8.32785 6.96329 9.99716C6.96329 11.6665 8.32557 13.0288 9.99488 13.0288C11.6642 13.0288 13.0265 11.6665 13.0265 9.99716C13.0265 8.32785 11.6642 6.96557 9.99488 6.96557ZM19.0874 9.99716C19.0874 8.74177 19.0988 7.49774 19.0283 6.24462C18.9578 4.78909 18.6257 3.49731 17.5614 2.43296C16.4947 1.36633 15.2052 1.03656 13.7497 0.966057C12.4943 0.895555 11.2503 0.906926 9.99716 0.906926C8.74176 0.906926 7.49774 0.895555 6.24462 0.966057C4.78909 1.03656 3.49731 1.3686 2.43296 2.43296C1.36633 3.49959 1.03656 4.78909 0.966057 6.24462C0.895555 7.50002 0.906926 8.74404 0.906926 9.99716C0.906926 11.2503 0.895555 12.4966 0.966057 13.7497C1.03656 15.2052 1.3686 16.497 2.43296 17.5614C3.49959 18.628 4.78909 18.9578 6.24462 19.0283C7.50002 19.0988 8.74404 19.0874 9.99716 19.0874C11.2526 19.0874 12.4966 19.0988 13.7497 19.0283C15.2052 18.9578 16.497 18.6257 17.5614 17.5614C18.628 16.4947 18.9578 15.2052 19.0283 13.7497C19.101 12.4966 19.0874 11.2526 19.0874 9.99716ZM9.99488 14.6617C7.41359 14.6617 5.33037 12.5784 5.33037 9.99716C5.33037 7.41587 7.41359 5.33264 9.99488 5.33264C12.5762 5.33264 14.6594 7.41587 14.6594 9.99716C14.6594 12.5784 12.5762 14.6617 9.99488 14.6617ZM14.8504 6.23098C14.2478 6.23098 13.7611 5.74429 13.7611 5.14161C13.7611 4.53893 14.2478 4.05223 14.8504 4.05223C15.4531 4.05223 15.9398 4.53893 15.9398 5.14161C15.94 5.28471 15.9119 5.42645 15.8573 5.5587C15.8026 5.69095 15.7223 5.81112 15.6211 5.91231C15.5199 6.0135 15.3998 6.09374 15.2675 6.14842C15.1353 6.2031 14.9935 6.23116 14.8504 6.23098Z"
                    fill="#DA6C08"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
