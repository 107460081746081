// import Button from 'react-bootstrap/Button';
import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
// import React from 'react';
import "./popup.css";
import logo1 from "../assets/image/homelogo.png";
// import
// import Button from 'react-bootstrap/Button';
// import "./Modal.css";

const Popup = (props) => {
  let { Name, ImgSrc, Post, Text } = props.data;
  const { toggleModal, setData } = props;
  const img = ImgSrc;

  // if (modal) {
  //   document.body.classList.add("active-modal");
  // } else {
  //   document.body.classList.remove("active-modal");
  // }

  return (
    <>
      <button
        variant="primary"
        onClick={() => {
          console.log("Data");
          toggleModal();
          setData({ ...props.data, img: ImgSrc });
        }}
        className="btn-modal"
      >
        Read more
      </button>
    </>
  );
};

export default Popup;
