import React, { useState } from "react";
import logo from "../../assets/image/logo.png";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/footer";
import tally from "../../assets/image/Tally.png";
import logo1 from "../../assets/image/browselogo.png";
import logo2 from "../../assets/image/browselogo1.png";
import logo3 from "../../assets/image/browselogo2.png";
import HoverCard from "./hoverCard";
import KnowMoreCard from "./knowMoreCard";
import ResponsiveNav from "../../components/responsiveNav";

const CardData = [
  {
    img: tally,
    heading: "LoremIp",
    subhead: "Accounting tools",
    para: "Turpis velit neque diam.Dictum orci non metus, semper leo",
  },
  {
    img: logo1,
    heading: "LoremIp",
    subhead: "Accounting tools",
    para: "Turpis velit neque diam.Dictum orci non metus, semper leo",
  },
  {
    img: logo2,
    heading: "LoremIp",
    subhead: "Accounting tools",
    para: "Turpis velit neque diam.Dictum orci non metus, semper leo",
  },
  {
    img: logo3,
    heading: "LoremIp",
    subhead: "Accounting tools",
    para: "Turpis velit neque diam.Dictum orci non metus, semper leo",
  },
  {
    img: logo3,
    heading: "LoremIp",
    subhead: "Accounting tools",
    para: "Turpis velit neque diam.Dictum orci non metus, semper leo",
  },
  {
    img: logo3,
    heading: "LoremIp",
    subhead: "Accounting tools",
    para: "Turpis velit neque diam.Dictum orci non metus, semper leo",
  },
  {
    img: tally,
    heading: "LoremIp",
    subhead: "Accounting tools",
    para: "Turpis velit neque diam.Dictum orci non metus, semper leo",
  },
  {
    img: logo1,
    heading: "LoremIp",
    subhead: "Accounting tools",
    para: "Turpis velit neque diam.Dictum orci non metus, semper leo",
  },
  {
    img: logo2,
    heading: "LoremIp",
    subhead: "Accounting tools",
    para: "Turpis velit neque diam.Dictum orci non metus, semper leo",
  },
  {
    img: logo3,
    heading: "LoremIp",
    subhead: "Accounting tools",
    para: "Turpis velit neque diam.Dictum orci non metus, semper leo",
  },
  {
    img: logo3,
    heading: "LoremIp",
    subhead: "Accounting tools",
    para: "Turpis velit neque diam.Dictum orci non metus, semper leo",
  },
  {
    img: logo3,
    heading: "LoremIp",
    subhead: "Accounting tools",
    para: "Turpis velit neque diam.Dictum orci non metus, semper leo",
  },
  {
    img: tally,
    heading: "LoremIp",
    subhead: "Accounting tools",
    para: "Turpis velit neque diam.Dictum orci non metus, semper leo",
  },
  {
    img: logo1,
    heading: "LoremIp",
    subhead: "Accounting tools",
    para: "Turpis velit neque diam.Dictum orci non metus, semper leo",
  },
  {
    img: logo2,
    heading: "LoremIp",
    subhead: "Accounting tools",
    para: "Turpis velit neque diam.Dictum orci non metus, semper leo",
  },
  {
    img: logo3,
    heading: "LoremIp",
    subhead: "Accounting tools",
    para: "Turpis velit neque diam.Dictum orci non metus, semper leo",
  },
  {
    img: logo3,
    heading: "LoremIp",
    subhead: "Accounting tools",
    para: "Turpis velit neque diam.Dictum orci non metus, semper leo",
  },
  {
    img: logo3,
    heading: "LoremIp",
    subhead: "Accounting tools",
    para: "Turpis velit neque diam.Dictum orci non metus, semper leo",
  },
];

export default function StoreBrowse() {
  const navigate = useNavigate();

  const [allProducts, setAllProducts] = useState(true);
  const [accounting, setAccounting] = useState(false);
  const [modal, setModal] = useState({
    knowMore: false,
  });
  const [isActive, setIsActive] = useState(false);
  const [selected, setSelected] = useState("All Products");

  const allproductsHandler = () => {
    setAllProducts(true);
    setAccounting(false);
  };

  const accountingHandler = () => {
    setAccounting(true);
    setAllProducts(false);
  };

  return (
    <div className="font-nunito">
      <div
        // style={{
        //   background:
        //     "linear-gradient(160deg, rgba(12, 78, 126, 0.711) 24.17%, rgba(4, 37, 61, 0.9) 90.04%, rgba(11, 50, 96, 0.9) 90.04%)",
        // }}
        className="h-[665px] hidden md:block bg-center bg-cover bg-no-repeat bg-[url('./assets/image/navbackground.png')] "
      >
        <div className="w-full h-[120px]">
          <div className="px-2 flex justify-between items-center w-full h-full">
            <div className="flex items-center">
              <div className="flex gap-[12px]">
                <img
                  src={logo}
                  alt="logo"
                  className="ml-[35px] cursor-pointer w-[34px] h-[33px]"
                  onClick={() => navigate("/")}
                />
                <h1
                  className="text-[26px] text-[#FFFFFF] font-[700] tracking-widest cursor-pointer"
                  onClick={() => navigate("/")}
                >
                  PANKH
                </h1>
              </div>
              <ul className="flex ml-[50px] font-[700]">
                <li className="p-4 text-[20px] text-[#FFFFFF]  tracking-wide">
                  <Link to="/store"> Store</Link>
                </li>
                <li className="p-4 text-[20px] text-[#FFFFFF] ml-[15px]  tracking-wide relative">
                  <Link to="/resources"> Resources </Link>
                </li>
                <li className="p-4 text-[20px] text-[#FFFFFF] ml-[15px]  tracking-wide">
                  <Link to="/about">About</Link>
                </li>
                <li className="p-4 text-[20px] text-[#FFFFFF] ml-[15px]  tracking-wide">
                  <Link to="/features"> Features </Link>
                </li>
                <li className="p-4 text-[20px] text-[#FFFFFF] ml-[15px]  tracking-wide">
                  <Link to="/contact"> Contact Us </Link>
                </li>
              </ul>
            </div>
            <div className="flex m-4 relative">
              <button
                className={`mr-[20px] flex items-center font-[700] justify-center text-center border-[0.4px] capitalize border-solid rounded-[10px] w-[138px] h-[51px] text-[#FFFFFF]`}
              >
                Log In
              </button>

              <button
                style={{
                  background:
                    "conic-gradient(from 300deg at 105.92% -41.84%, rgb(255, 165, 2) 192deg, rgb(212, 99, 9) 343deg)",
                }}
                className={`flex items-center font-[700] justify-center text-center capitalize tracking-widest rounded-[10px] w-[138px] h-[51px] bg-[#FFA502] text-[#FFFFFF]`}
              >
                Sign up
              </button>
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-center items-center">
          <div className="text-[55px] font-[800] tracking-wider text-[#FFFFFF] mt-[95px]">
            Store
          </div>

          <p className="text-[#FFFFFF] w-[974px] tracking-wider mt-[15px] text-center text-[24px] font-[800]">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce risus
            eu purus odio egestas cursus. Molestie vitae quis posuere a, eu
            nunc. Consequat
          </p>
          <div className="bg-gray-100 flex items-center justify-between px-[17px] py-[4px] w-[750px] rounded-md mt-[45px]">
            <input
              class="font-bold uppercase rounded-full w-full py-4 pl-4 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline lg:text-sm text-xs"
              type="text"
              placeholder="Search"
            />
            <div className="cursor-pointer">
              <svg
                class="w-6 h-6 text-[gray]"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <ResponsiveNav Main={"Store"}/>
      <div className="pt-[30px]  bg-[#F8FDFF]">
      <button onClick={() => navigate(-1)} className="text-[#747474]  mb-4 md:hidden ml-5 mt-5 text-[14px] font-[700] gap-1 flex">
          <span className="mt-[3px] mr-1">
            <svg
              width="16"
              height="14"
              viewBox="0 0 16 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 6.00125H3.14L6.77 1.64125C6.93974 1.43704 7.0214 1.17375 6.99702 0.909329C6.97264 0.644902 6.84422 0.400991 6.64 0.231252C6.43578 0.0615137 6.1725 -0.0201482 5.90808 0.0042315C5.64365 0.0286112 5.39974 0.157036 5.23 0.361252L0.23 6.36125C0.196361 6.40898 0.166279 6.45911 0.14 6.51125C0.14 6.56125 0.14 6.59125 0.0700002 6.64125C0.0246737 6.75591 0.000941121 6.87796 0 7.00125C0.000941121 7.12454 0.0246737 7.24659 0.0700002 7.36125C0.0700002 7.41125 0.0699999 7.44125 0.14 7.49125C0.166279 7.54339 0.196361 7.59353 0.23 7.64125L5.23 13.6413C5.32402 13.7541 5.44176 13.8449 5.57485 13.9071C5.70793 13.9694 5.85309 14.0015 6 14.0013C6.23365 14.0017 6.46009 13.9203 6.64 13.7713C6.74126 13.6873 6.82496 13.5842 6.88631 13.4679C6.94766 13.3515 6.98546 13.2242 6.99754 13.0932C7.00961 12.9622 6.99573 12.8302 6.95669 12.7046C6.91764 12.579 6.8542 12.4623 6.77 12.3613L3.14 8.00125H15C15.2652 8.00125 15.5196 7.8959 15.7071 7.70836C15.8946 7.52082 16 7.26647 16 7.00125C16 6.73604 15.8946 6.48168 15.7071 6.29415C15.5196 6.10661 15.2652 6.00125 15 6.00125Z"
                fill="#747474"
              />
            </svg>
          </span>{" "}
          Back
        </button>
        <div className="flex flex-col justify-center items-center md:hidden">
          <h2 className="text-[#074381] text-[20px] font-[700]">Lorem pal - Accounting tool </h2>
          <h3 className="text-[#747474D1] text-[16px] font-[600]">Lörem ipsum multinar mivevis och. </h3>
        </div>
      <div className="md:flex pt-4 md:pt-0 px-5 md:px-6 pb-7 md:pb-0  md:py-[50px] w-full">
        <div className="bg-[#FFFFFF] hidden md:block border-[#CACACA7D] border-[1px] w-[280px] h-[900px]">
          <h2 className="text-[#0D0D0D] font-monterrat text-[24px] font-[600] mt-[27px] ml-[28px] mr-[100px]">
            Categories
          </h2>
          <ul className="cursor-pointer  ">
            <li
              className="text-[#615F5F] text-[18px] font-[400] mt-[35px] pl-[31px] hover:bg-[#124870] hover:text-[#FFFFFF] active:bg-[#6a7278] "
              onClick={allproductsHandler}
            >
              All products
            </li>
            <li
              className="text-[#615F5F] text-[18px] font-[400] pl-[31px] mt-[18px] hover:bg-[#124870] hover:text-[#FFFFFF] "
              //   onClick={accountingHandler}
            >
              Accounting tools
            </li>
            <li className="text-[#615F5F] text-[18px] font-[400] pl-[31px] mt-[18px] hover:bg-[#124870] hover:text-[#FFFFFF] ">
              CRM
            </li>
            <li className="text-[#615F5F] text-[18px] font-[400] pl-[31px] mt-[18px] hover:bg-[#124870] hover:text-[#FFFFFF] ">
              Marketing
            </li>
            <li className="text-[#615F5F] text-[18px] font-[400] pl-[31px] mt-[18px] hover:bg-[#124870] hover:text-[#FFFFFF] ">
              Sales
            </li>
            <li className="text-[#615F5F] text-[18px] font-[400] pl-[31px] mt-[18px] hover:bg-[#124870] hover:text-[#FFFFFF] ">
              Developer tools
            </li>
            <li className="text-[#615F5F] text-[18px] font-[400] pl-[31px] mt-[18px] hover:bg-[#124870] hover:text-[#FFFFFF] ">
              Blogs
            </li>
            <li className="text-[#615F5F] text-[18px] font-[400] pl-[31px] mt-[18px] hover:bg-[#124870] hover:text-[#FFFFFF] ">
              Selling
            </li>
            <li className="text-[#615F5F] text-[18px] font-[400] pl-[31px] mt-[18px] hover:bg-[#124870] hover:text-[#FFFFFF] ">
              Supports
            </li>
            <li className="text-[#615F5F] text-[18px] font-[400] pl-[31px] mt-[18px] hover:bg-[#124870] hover:text-[#FFFFFF] ">
              Blogs
            </li>
            <li className="text-[#615F5F] text-[18px] font-[400] pl-[31px] mt-[18px] hover:bg-[#124870] hover:text-[#FFFFFF] ">
              WebHosting
            </li>
            <li className="text-[#615F5F] text-[18px] font-[400] pl-[31px] mt-[18px] hover:bg-[#124870] hover:text-[#FFFFFF] ">
              Branding
            </li>
            <li className="text-[#615F5F] text-[18px] font-[400] pl-[31px] mt-[18px] hover:bg-[#124870] hover:text-[#FFFFFF] ">
              Content
            </li>
            <li className="text-[#615F5F] text-[18px] font-[400] pl-[31px] mt-[18px] hover:bg-[#124870] hover:text-[#FFFFFF] ">
              Human resource
            </li>
            <li className="text-[#615F5F] text-[18px] font-[400] pl-[31px] mt-[18px] hover:bg-[#124870] hover:text-[#FFFFFF] ">
              File sharing
            </li>
            <li className="text-[#615F5F] text-[18px] font-[400] pl-[31px] mt-[18px] hover:bg-[#124870] hover:text-[#FFFFFF] ">
              HRMS
            </li>
            <li className="text-[#615F5F] text-[18px] font-[400] pl-[31px] mt-[18px] hover:bg-[#124870] hover:text-[#FFFFFF] ">
              Networking
            </li>
            <li className="text-[#615F5F] text-[18px] font-[400] pl-[31px] mt-[18px] hover:bg-[#124870] hover:text-[#FFFFFF] ">
              Database
            </li>
          </ul>
        </div>
        <div className=" w-full md:hidden">
        <div
                onClick={(e) => setIsActive(!isActive)}
                className="flex justify-between items-center px-[16px] text-[18px] text-[#615F5F] font-[700] w-full h-[44px] border-[1px] border-[#1248703D]"
              >
                {selected}
                <span>
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.0558 6.44531H3.94484C3.5216 6.44531 3.28527 6.89219 3.54738 7.19727L10.6029 15.3785C10.8048 15.6127 11.1937 15.6127 11.3978 15.3785L18.4532 7.19727C18.7154 6.89219 18.479 6.44531 18.0558 6.44531Z"
                      fill="#124870"
                      fill-opacity="0.8"
                    />
                  </svg>
                </span>
              </div>
              {isActive && (
                <ul className=" border-[1px] border-[#1248703D] ">
                  {/* <li onClick={e =>setSelected(e.target.textContent)} className="text-[18px] text-[#615F5F] font-[700] hover:bg-[#124870]">All Products </li> */}

                  <li
                    className={`text-[#615F5F] text-[18px] font-[700] mt-[10px]   ${
                      allProducts && ""
                 } pl-[18px]  `}
                    onClick={(e) => setSelected(e.target.textContent)}
                    // onClick={allproductsHandler2}
                    
                    
                  >
                    All products
                  </li>
                  <li
                    className={`text-[#615F5F] text-[18px] font-[700] pl-[18px] mt-[18px]     ${
                      accounting && ""
                    } `}
                    // onClick={accountingHandler2}
                    onClick={(e) => setSelected(e.target.textContent)}

                  >
                    Accounting tools
                  </li>
                  <li
                    onClick={(e) => setSelected(e.target.textContent)}
                    className="text-[#615F5F] text-[18px] font-[700] pl-[18px] mt-[18px]      "
                  >
                    CRM
                  </li>
                  <li
                    onClick={(e) => setSelected(e.target.textContent)}
                    className="text-[#615F5F] text-[18px] font-[700] pl-[18px] mt-[18px]      "
                  >
                    Marketing
                  </li>
                  <li
                    onClick={(e) => setSelected(e.target.textContent)}
                    className="text-[#615F5F] text-[18px] font-[700] pl-[18px] mt-[18px]      "
                  >
                    Sales
                  </li>
                  <li
                    onClick={(e) => setSelected(e.target.textContent)}
                    className="text-[#615F5F] text-[18px] font-[700] pl-[18px] mt-[18px]      "
                  >
                    Developer tools
                  </li>
                  <li
                    onClick={(e) => setSelected(e.target.textContent)}
                    className="text-[#615F5F] text-[18px] font-[700] pl-[18px] mt-[18px]      "
                  >
                    Blogs
                  </li>
                  <li
                    onClick={(e) => setSelected(e.target.textContent)}
                    className="text-[#615F5F] text-[18px] font-[700] pl-[18px] mt-[18px]      "
                  >
                    Selling
                  </li>
                  <li
                    onClick={(e) => setSelected(e.target.textContent)}
                    className="text-[#615F5F] text-[18px] font-[700] pl-[18px] mt-[18px]      "
                  >
                    Supports
                  </li>
                  <li
                    onClick={(e) => setSelected(e.target.textContent)}
                    className="text-[#615F5F] text-[18px] font-[700] pl-[18px] mt-[18px]      "
                  >
                    Blogs
                  </li>
                  <li
                    onClick={(e) => setSelected(e.target.textContent)}
                    className="text-[#615F5F] text-[18px] font-[700] pl-[18px] mt-[18px]      "
                  >
                    WebHosting
                  </li>
                  <li
                    onClick={(e) => setSelected(e.target.textContent)}
                    className="text-[#615F5F] text-[18px] font-[700] pl-[18px] mt-[18px]      "
                  >
                    Branding
                  </li>
                  <li
                    onClick={(e) => setSelected(e.target.textContent)}
                    className="text-[#615F5F] text-[18px] font-[700] pl-[18px] mt-[18px]      "
                  >
                    Content
                  </li>
                  <li
                    onClick={(e) => setSelected(e.target.textContent)}
                    className="text-[#615F5F] text-[18px] font-[700] pl-[18px] mt-[18px]      "
                  >
                    Human resource
                  </li>
                  <li
                    onClick={(e) => setSelected(e.target.textContent)}
                    className="text-[#615F5F] text-[18px] font-[700] pl-[18px] mt-[18px]      "
                  >
                    File sharing
                  </li>
                  <li
                    onClick={(e) => setSelected(e.target.textContent)}
                    className="text-[#615F5F] text-[18px] font-[700] pl-[18px] mt-[18px]      "
                  >
                    HRMS
                  </li>
                  <li
                    onClick={(e) => setSelected(e.target.textContent)}
                    className="text-[#615F5F] text-[18px] font-[700] pl-[18px] mt-[18px]      "
                  >
                    Networking
                  </li>
                  <li
                    onClick={(e) => setSelected(e.target.textContent)}
                     className="text-[#615F5F] text-[18px] font-[700] pl-[18px] mt-[18px]     "
                  >
                    Database
                  </li>
                </ul>
              )}
        </div>
        
        <div className="">
          {allProducts && (
            <div className="md:flex gap-1 w-full">
              <div className="md:ml-[20px] md:flex md:flex-wrap grid grid-cols-1 place-items-center w-full md:gap-x-[20px] md:gap-y-[30px] md:mb-[48px]  ">
                {CardData.map((details) => (
                  <HoverCard details={details} setModal={setModal} />
                ))}
              </div>

              {modal.knowMore && <KnowMoreCard setModal={setModal} />}
            </div>
          )}
        </div>
      </div>
      </div>
      {/* <KnowMoreCard /> */}
      <Footer />
    </div>
  );
}
