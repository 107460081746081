import React, { useState, useEffect } from "react";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import image from "../../assets/image/resource.png";
import image2 from "../../assets/image/resource2.png";
import image3 from "../../assets/image/resource1.png";
import image4 from "../../assets/image/resource3.png";
import logo from "../../assets/image/logoResource.png";
import logo1 from "../../assets/image/logo.png";
import mob from "../../assets/image/resourcemob.png";

import { Link, useNavigate } from "react-router-dom";
import ResponsiveNav from "../../components/responsiveNav";

// const EmploymentType = [
//   {
//     id: "1",
//     employment: "Group A",
//   }

const CardData = [
  {
    image: { image2 },
    cardDetail:
      "A complete guide to tools and technology sales professionals should be using",
    paragraph:
      "   Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacus pretium dolor, id nulla tincidunt dolor. Dui, pharetra, tortor vel enim vulputate duis ac nisi.",
  },
  {
    image: { image2 },
    cardDetail:
      "A complete guide to tools and technology sales professionals should be using",
    paragraph:
      "   Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacus pretium dolor, id nulla tincidunt dolor. Dui, pharetra, tortor vel enim vulputate duis ac nisi.",
  },
  {
    image: { image2 },
    cardDetail:
      "A complete guide to tools and technology sales professionals should be using",
    paragraph:
      "   Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacus pretium dolor, id nulla tincidunt dolor. Dui, pharetra, tortor vel enim vulputate duis ac nisi.",
  },
];

const products = [
  {
    para: " A complete guide to tools and technology sales professionals should be using",
    paratwo:
      " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacus pretium dolor, id nulla tincidunt dolor. Dui, pharetra, tortor vel enim vulputate duis ac nisi.",
  },
  {
    para: " A complete guide to tools and technology sales professionals should be using",
    paratwo:
      " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacus pretium dolor, id nulla tincidunt dolor. Dui, pharetra, tortor vel enim vulputate duis ac nisi.",
  },
  {
    para: " A complete guide to tools and technology sales professionals should be using",
    paratwo:
      " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacus pretium dolor, id nulla tincidunt dolor. Dui, pharetra, tortor vel enim vulputate duis ac nisi.",
  },
  {
    para: " A complete guide to tools and technology sales professionals should be using",
    paratwo:
      " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacus pretium dolor, id nulla tincidunt dolor. Dui, pharetra, tortor vel enim vulputate duis ac nisi.",
  },
  {
    para: " A complete guide to tools and technology sales professionals should be using",
    paratwo:
      " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacus pretium dolor, id nulla tincidunt dolor. Dui, pharetra, tortor vel enim vulputate duis ac nisi.",
  },
  {
    para: " A complete guide to tools and technology sales professionals should be using",
    paratwo:
      " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacus pretium dolor, id nulla tincidunt dolor. Dui, pharetra, tortor vel enim vulputate duis ac nisi.",
  },
  {
    para: " A complete guide to tools and technology sales professionals should be using",
    paratwo:
      " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacus pretium dolor, id nulla tincidunt dolor. Dui, pharetra, tortor vel enim vulputate duis ac nisi.",
  },
  {
    para: " A complete guide to tools and technology sales professionals should be using",
    paratwo:
      " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacus pretium dolor, id nulla tincidunt dolor. Dui, pharetra, tortor vel enim vulputate duis ac nisi.",
  },
  {
    para: " A complete guide to tools and technology sales professionals should be using",
    paratwo:
      " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacus pretium dolor, id nulla tincidunt dolor. Dui, pharetra, tortor vel enim vulputate duis ac nisi.",
  },
];

const account = [
  {
    para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Incidunt venenatis dolor sit amet, conur adip.",
    paratwo:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacus pretium dolor, id nulla tincidunt dolor. Dui, pharetra, tortor vel enim vulputate duis ac nisi. Imperdiet non feugiat lectus ipsum. Netus blandit nisi lorem Ipsum.",
  },
  {
    para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Incidunt venenatis dolor sit amet, conur adip.",
    paratwo:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacus pretium dolor, id nulla tincidunt dolor. Dui, pharetra, tortor vel enim vulputate duis ac nisi. Imperdiet non feugiat lectus ipsum. Netus blandit nisi lorem Ipsum.",
  },
  {
    para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Incidunt venenatis dolor sit amet, conur adip.",
    paratwo:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacus pretium dolor, id nulla tincidunt dolor. Dui, pharetra, tortor vel enim vulputate duis ac nisi. Imperdiet non feugiat lectus ipsum. Netus blandit nisi lorem Ipsum.",
  },
  {
    para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Incidunt venenatis dolor sit amet, conur adip.",
    paratwo:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacus pretium dolor, id nulla tincidunt dolor. Dui, pharetra, tortor vel enim vulputate duis ac nisi. Imperdiet non feugiat lectus ipsum. Netus blandit nisi lorem Ipsum.",
  },
  {
    para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Incidunt venenatis dolor sit amet, conur adip.",
    paratwo:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacus pretium dolor, id nulla tincidunt dolor. Dui, pharetra, tortor vel enim vulputate duis ac nisi. Imperdiet non feugiat lectus ipsum. Netus blandit nisi lorem Ipsum.",
  },
];

export default function Resources() {
  const navigate = useNavigate();

  const [details, setDetails] = useState(products);
  const [allProducts, setAllProducts] = useState(true);
  const [accounting, setAccounting] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [selected, setSelected] = useState("All Products");

  const allproductsHandler = () => {
    setAllProducts(true);
    setAccounting(false);
  };

  const allproductsHandler2 = (e) =>{
    setSelected(e.target.textContent)
    setAllProducts(true);
    setAccounting(false);
    setIsActive(false)
  
  }

  const accountingHandler = (e) => {
    
    setAccounting(true);
    setAllProducts(false);
    setSelected(e.target.textContent)

  };

  const accountingHandler2 = (e) => {
    setIsActive(false)
    setAccounting(true);
    setAllProducts(false);
    setSelected(e.target.textContent)
   

  };

 

  return (
    <div className="font-nunito ">
      <div
        // style={{
        //   background:
        //     "linear-gradient(160deg, rgba(12, 78, 126, 0.711) 24.17%, rgba(4, 37, 61, 0.9) 90.04%, rgba(11, 50, 96, 0.9) 90.04%)",
        // }}
        className=" h-[665px] hidden md:block bg-center bg-cover bg-no-repeat bg-[url('./assets/image/navbackground.png')]"
      >
        <div className="w-full h-[120px]">
          <div className="px-2 flex justify-between items-center w-full h-full">
            <div className="flex items-center">
              <div className="flex gap-[12px]">
                <img
                  src={logo1}
                  alt="logo"
                  className="ml-[35px] cursor-pointer w-[34px] h-[33px]"
                  onClick={() => navigate("/")}
                />
                <h1
                  className="text-[26px] text-[#FFFFFF] font-[700] tracking-widest cursor-pointer"
                  onClick={() => navigate("/")}
                >
                  PANKH
                </h1>
              </div>
              <ul className="flex ml-[50px] font-[700]">
                <li className="p-4 text-[20px] text-[#FFFFFF]  tracking-wide">
                  <Link to="/store"> Store</Link>
                </li>
                <li className="p-4 text-[20px] text-[#FFFFFF] ml-[15px]  tracking-wide relative">
                  <Link to="/resources"> Resources </Link>
                </li>
                <li className="p-4 text-[20px] text-[#FFFFFF] ml-[15px]  tracking-wide">
                  <Link to="/about">About</Link>
                </li>
                <li className="p-4 text-[20px] text-[#FFFFFF] ml-[15px]  tracking-wide">
                  <Link to="/features"> Features </Link>
                </li>
                <li className="p-4 text-[20px] text-[#FFFFFF] ml-[15px]  tracking-wide">
                  <Link to="/contact"> Contact Us </Link>
                </li>
              </ul>
            </div>
            <Link to={"/waitlist"}>
              <div className="flex m-4 relative">
                <span className="absolute -ml-[68px]">
                  <svg
                    width="96"
                    height="65"
                    viewBox="0 0 96 65"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className=" "
                  >
                    <g clip-path="url(#clip0_3055_64766)">
                      <path
                        d="M78.8164 10.0469L91.8941 20.8338L79.0131 29.6426"
                        stroke="#FFFFFF"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <path
                      d="M92 20.2263C42.7679 9.71354 12.4256 26.6061 2 64.1523"
                      stroke="#FFFFFF"
                      stroke-width="3"
                    />
                    <defs>
                      <clipPath id="clip0_3055_64766">
                        <rect
                          width="39.1935"
                          height="19.5255"
                          fill="white"
                          transform="matrix(0.0100383 0.99995 0.997109 0.0759842 75.4727 0)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <Link to="/waitlist">
                    <p className="w-[220px] italic text-center text-[22px] text-[#FFFFFF] -ml-[120px]">
                      Join the Waitlist instead
                    </p>
                  </Link>
                </span>

                <button
                  style={{
                    background:
                      "conic-gradient(from 300deg at 105.92% -41.84%, rgb(255, 165, 2) 192deg, rgb(212, 99, 9) 343deg)",
                  }}
                  className={`flex items-center font-[700] justify-center text-center tracking-widest capitalize border-solid rounded-[10px] w-[168px] h-[51px] bg-[#FFA502] text-[#FFFFFF]`}
                >
                  Join Waitlist
                </button>
              </div>
            </Link>
          </div>
        </div>
        <div className="ml-[50px] text-[#FFFFFF] text-[18px] flex">
          <Link to="/"> Home {" >"} </Link>
          <p className="ml-[8px] font-[600] tracking-wider text-[#FFFFFF] text-[20px] -mt-[2px]">
            Resources
          </p>
        </div>
        <div className="flex flex-col justify-center items-center">
          <div className="md:text-[55px] lg:font-[800] font-monterrat tracking-wider text-[#FFFFFF] mt-[105px]">
            Resources
          </div>
          <div className="text-[#FFFFFF] mt-[10px] tracking-wider md:text-[24px] md:font-[800]">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit.
          </div>
          <p className="text-[#FFFFFF] w-[974px] text-[18px] font-[800] mt-[15px] text-center">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde
            perferendis eveniet similique explicabo, iure laudantium officiis
            quasi mollitia aliquam hic minus, cupiditate harum dolores
            exercitationem, quaerat pariatur.
          </p>
          <div className="flex relative">
            {/* <button
              className={`mt-[65px] flex items-center font-[700] justify-center text-center border-[0.4px] capitalize border-solid rounded-[10px] mr-3 w-[320px] h-[69px] text-[#FFFFFF]`}
            >
              Learn more
            </button> */}
            {/* <span className="absolute mt-[73px] ml-[286px]">
              <svg
                width="114"
                height="57"
                viewBox="0 0 114 57"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_3055_64772)">
                  <path
                    d="M18.9375 10.0469L3.94511 20.8338L18.712 29.6426"
                    stroke="#00CECE"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <path
                  d="M4 20.8252C57 20.8237 93 11.4388 112 56"
                  stroke="#00CECE"
                  stroke-width="3"
                />
                <defs>
                  <clipPath id="clip0_3055_64772">
                    <rect
                      width="39.1941"
                      height="22.3687"
                      fill="white"
                      transform="matrix(-0.0115078 0.999934 -0.997798 0.0663261 22.7695 0)"
                    />
                  </clipPath>
                </defs>
              </svg>

              <Link to="/waitlist">
                <p className="w-[300px] italic text-center text-[30px] text-[#00CECE]">
                  Join the Waitlist instead
                </p>
              </Link>
            </span> */}
          </div>
        </div>
      </div>

      <ResponsiveNav Main={"Resources"} />
      <div className="bg-[#F8FDFF] pt-[20px]">
      <button onClick={() => navigate(-1)} className="text-[#747474] mb-4 md:hidden ml-5 mt-5 text-[14px] font-[700] gap-1 flex">
          <span className="mt-[3px] mr-1">
            <svg
              width="16"
              height="14"
              viewBox="0 0 16 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 6.00125H3.14L6.77 1.64125C6.93974 1.43704 7.0214 1.17375 6.99702 0.909329C6.97264 0.644902 6.84422 0.400991 6.64 0.231252C6.43578 0.0615137 6.1725 -0.0201482 5.90808 0.0042315C5.64365 0.0286112 5.39974 0.157036 5.23 0.361252L0.23 6.36125C0.196361 6.40898 0.166279 6.45911 0.14 6.51125C0.14 6.56125 0.14 6.59125 0.0700002 6.64125C0.0246737 6.75591 0.000941121 6.87796 0 7.00125C0.000941121 7.12454 0.0246737 7.24659 0.0700002 7.36125C0.0700002 7.41125 0.0699999 7.44125 0.14 7.49125C0.166279 7.54339 0.196361 7.59353 0.23 7.64125L5.23 13.6413C5.32402 13.7541 5.44176 13.8449 5.57485 13.9071C5.70793 13.9694 5.85309 14.0015 6 14.0013C6.23365 14.0017 6.46009 13.9203 6.64 13.7713C6.74126 13.6873 6.82496 13.5842 6.88631 13.4679C6.94766 13.3515 6.98546 13.2242 6.99754 13.0932C7.00961 12.9622 6.99573 12.8302 6.95669 12.7046C6.91764 12.579 6.8542 12.4623 6.77 12.3613L3.14 8.00125H15C15.2652 8.00125 15.5196 7.8959 15.7071 7.70836C15.8946 7.52082 16 7.26647 16 7.00125C16 6.73604 15.8946 6.48168 15.7071 6.29415C15.5196 6.10661 15.2652 6.00125 15 6.00125Z"
                fill="#747474"
              />
            </svg>
          </span>{" "}
          Back
        </button>
        <div className="lg:p-[50px] mb-[20px] md:mb-0">
          <div className="lg:flex bg-[#FFFFFF] lg:rounded-[20px] lg:p-[32px] p-[20px] ">
            <div className=" lg:mt-[20px] lg:mr-[70px] flex md:justify-start md:items-start flex-col justify-center items-center">
              <h2 className="text-[#074381] tracking-wider font-monterrat md:text-[32px] text-[20px] font-[700] md:font-[800]">
                Featured
              </h2>
              <h3 className="md:text-[24px] text-[18px] font-[700] text-center md:text-left text-[#074381] md:font-[800] md:mt-[20px]">
                A psum egestas naid loin your pharm aihismanage loremisiu.
              </h3>
              <div className="md:hidden mt-[25px]">
                <img src={mob} alt="" />
              </div>

              <h4 className="md:text-[18px] text-[14px] font-[600] text-[#747474D6] mt-[25px]">
                Lorem ipsum dolor sit amet, sem per consectetur adipiscing elit.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.{" "}
              </h4>

              <div className="flex gap-[100px] mt-[36px]  md:mt-[50px]">
                <div className="flex">
                <div>
                  <img src={logo} alt="" className="md:h-[34px] md:w-[34px] w-[30px] h-[30px]"  />
                </div>
                <h2 className="text-[#509ADE] md:text-[20px] text-[16px] font-[700] ml-[8px] md:ml-[16px]">
                  Alex Nathon
                </h2>
                </div>
                <h3 className="text-[#848484EB] md:text-[21px] text-[14px] font-[400] md:font-[500] md:ml-[26px]">
                  Dec 31, 2021
                </h3>
              </div>

              <button
                style={{
                  background:
                    "conic-gradient(from 300deg at 105.92% -41.84%, rgb(255, 165, 2) 192deg, rgb(212, 99, 9) 343deg)",
                }}
                className="text-[#FFFFFF] mt-[40px] md:mt-[70px] md:text-[18px] md:font-[700] md:px-[54px] md:py-[11px] w-full md:w-[200px] py-[7px] border-[#FFA502] border-[1px] rounded md:rounded-[10px]"
              >
                Read more
              </button>
            </div>
            <img src={image} alt="#" className="hidden md:block" />
          </div>
        </div>
        <div className="bg-[#062E4E] pb-[28px] md:pb-0">
          <div className="flex flex-col justify-center items-center px-[20px] md:px-0">
            <h2 className="text-[#FFFFFF] tracking-wider font-monterrat text-[20px] font-[700] md:text-[32px] mt-[50px] md:font-[800]">
              Customer Board
            </h2>
            <h3 className="md:text-[24px] text-[#FFFFFFB8] text-center text-[16px] font-[600] md:font-[800] mt-[10px]">
              Lorem ipsum dolor sit amet, sem per consectetur adipiscing elit.
            </h3>
          </div>
          <div className="flex md:overflow-hidden overflow-x-scroll scrollbar-hide  md:justify-evenly mt-[50px]  ">
            <div class="flex flex-nowrap gap-3 md:gap-8 pl-5 md:pl-0">
              {CardData.map((detail) => {
                return (
                  <div className="bg-[#FFFFFF] py-[14px] px-[13px] md:py-0 md:px-0 rounded-[20px] md:rounded-[24px] md:mb-[30px]">
                    <Link to="/blogs">
                      <img src={image2} alt="" className="md:m-[20px]" />
                      <div className="md:w-[350px] mt-[5px] md:mt-0 w-[300px] md:m-[25px] md:mb-[20px]">
                        <h2 className="text-[#074381] md:text-[22px] text-[16px] font-[500]  ">
                          {detail.cardDetail}
                          <span className="text-[#1174AC] ml-[5px] rounded text-[12px]   md:text-[14px] md:font-[700] bg-[#1174AC1F] md:px-[12px] md:py-[4px]">
                            Marketing
                          </span>
                        </h2>

                        <h3 className="text-[#747474] text-[13px] md:text-[18px] font-[400] mt-[10px]">
                          {detail.paragraph}
                        </h3>
                        <button className="text-[#D46309] md:text-[14px] text-[13px] font-[700] md:mt-[8px]">
                          Read more&gt;&gt;
                        </button>
                        <div className="flex justify-between mt-[25px] md:mt-[34px]">
                          <div className="flex">
                            <img
                              src={logo}
                              alt=""
                              className="w-[22px] h-[22px] md:w-[34px] md:h-[34px]"
                            />
                            <h2 className="text-[#509ADE] md:ml-[16px] ml-[8px] text-[14px] md:mt-[4px] md:text-[16px] font-[700]">
                              Alex Nathon
                            </h2>
                          </div>
                          <h3 className="text-[#3A4047] md:text-[14px] text-[14px] font-[400] md:font-[600]">
                            Fab 25, 2022
                          </h3>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="pb-[28px] md:pb-0">
          <div className="flex flex-col justify-center items-center md:mt-[40px] px-[20px] md:px-0 ">
            <h2 className="text-[#062E4E] tracking-wider font-monterrat text-[20px] font-[700] md:text-[32px] mt-[50px] md:font-[800]">
              Employee Board
            </h2>
            <h3 className="md:text-[24px] text-[#062E4E] text-center text-[16px] font-[600] md:font-[800] mt-[10px]">
              Lorem ipsum dolor sit amet, sem per consectetur adipiscing elit.{" "}
            </h3>
          </div>
          <div className="flex md:overflow-hidden overflow-x-scroll scrollbar-hide  md:justify-evenly mt-[50px]  ">
            <div class="flex flex-nowrap gap-3 md:gap-8 pl-5 md:pl-0">
              {CardData.map((detail) => {
                return (
                  <div className="bg-[#E3F2F5] py-[14px] px-[13px] md:py-0 md:px-0 rounded-[20px] md:rounded-[24px] md:mb-[30px]">
                    <Link to="/blogs">
                      <img src={image2} alt="" className="md:m-[20px]" />
                      <div className="md:w-[350px] mt-[5px] md:mt-0 w-[300px] md:m-[25px] md:mb-[20px]">
                        <h2 className="text-[#074381] md:text-[22px] text-[16px] font-[500]  ">
                          {detail.cardDetail}
                          <span className="text-[#1174AC] ml-[5px] rounded text-[12px]   md:text-[14px] md:font-[700] bg-[#1174AC1F] md:px-[12px] md:py-[4px]">
                            Marketing
                          </span>
                        </h2>

                        <h3 className="text-[#747474] text-[13px] md:text-[18px] font-[400] mt-[10px]">
                          {detail.paragraph}
                        </h3>
                        <button className="text-[#D46309] md:text-[14px] text-[13px] font-[700] md:mt-[8px]">
                          Read more&gt;&gt;
                        </button>
                        <div className="flex justify-between mt-[25px] md:mt-[34px]">
                          <div className="flex">
                            <img
                              src={logo}
                              alt=""
                              className="w-[22px] h-[22px] md:w-[34px] md:h-[34px]"
                            />
                            <h2 className="text-[#509ADE] md:ml-[16px] ml-[8px] text-[14px] md:mt-[4px] md:text-[16px] font-[700]">
                              Alex Nathon
                            </h2>
                          </div>
                          <h3 className="text-[#3A4047] md:text-[14px] text-[14px] font-[400] md:font-[600]">
                            Fab 25, 2022
                          </h3>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="bg-[#062E4E] pb-[28px] md:pb-0">
        <div className="flex flex-col justify-center items-center px-[20px] md:px-0">
          <h2 className="text-[#FFFFFF] tracking-wider font-monterrat text-[20px] font-[700] md:text-[32px] mt-[50px] md:font-[800]">
              B2B Marketplace
            </h2>
            <h3 className="md:text-[24px] text-[#FFFFFFB8] text-center text-[16px] font-[600] md:font-[800] mt-[10px]">
              Lorem ipsum dolor sit amet, sem per consectetur adipiscing elit.{" "}
            </h3>
          </div>
          <div className="flex md:overflow-hidden overflow-x-scroll scrollbar-hide  md:justify-evenly mt-[50px]  ">
            <div class="flex flex-nowrap gap-3 md:gap-8 pl-5 md:pl-0">
              {CardData.map((detail) => {
                return (
                  <div className="bg-[#FFFFFF] py-[14px] px-[13px] md:py-0 md:px-0 rounded-[20px] md:rounded-[24px] md:mb-[30px]">
                    <Link to="/blogs">
                      <img src={image2} alt="" className="md:m-[20px]" />
                      <div className="md:w-[350px] mt-[5px] md:mt-0 w-[300px] md:m-[25px] md:mb-[20px]">
                        <h2 className="text-[#074381] md:text-[22px] text-[16px] font-[500]  ">
                          {detail.cardDetail}
                          <span className="text-[#1174AC] ml-[5px] rounded text-[12px]   md:text-[14px] md:font-[700] bg-[#1174AC1F] md:px-[12px] md:py-[4px]">
                            Marketing
                          </span>
                        </h2>

                        <h3 className="text-[#747474] text-[13px] md:text-[18px] font-[400] mt-[10px]">
                          {detail.paragraph}
                        </h3>
                        <button className="text-[#D46309] md:text-[14px] text-[13px] font-[700] md:mt-[8px]">
                          Read more&gt;&gt;
                        </button>
                        <div className="flex justify-between mt-[25px] md:mt-[34px]">
                          <div className="flex">
                            <img
                              src={logo}
                              alt=""
                              className="w-[22px] h-[22px] md:w-[34px] md:h-[34px]"
                            />
                            <h2 className="text-[#509ADE] md:ml-[16px] ml-[8px] text-[14px] md:mt-[4px] md:text-[16px] font-[700]">
                              Alex Nathon
                            </h2>
                          </div>
                          <h3 className="text-[#3A4047] md:text-[14px] text-[14px] font-[400] md:font-[600]">
                            Fab 25, 2022
                          </h3>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="pb-[28px] md:pb-0">
          <div className="flex flex-col justify-center items-center  md:mt-[40px] px-[20px] md:px-0 ">
          <h2 className="text-[#062E4E] tracking-wider font-monterrat text-[20px] font-[700] md:text-[32px] mt-[50px] md:font-[800]">
              Inventory
            </h2>
            <h3 className="md:text-[24px] text-[#062E4E] text-center text-[16px] font-[600] md:font-[800] mt-[10px]">
              Lorem ipsum dolor sit amet, sem per consectetur adipiscing elit.{" "}
            </h3>
          </div>
          <div className="flex md:overflow-hidden overflow-x-scroll scrollbar-hide  md:justify-evenly mt-[50px]  ">
            <div class="flex flex-nowrap gap-3 md:gap-8 pl-5 md:pl-0">
              {CardData.map((detail) => {
                return (
                  <div className="bg-[#E3F2F5] py-[14px] px-[13px] md:py-0 md:px-0 rounded-[20px] md:rounded-[24px] md:mb-[30px]">
                    <Link to="/blogs">
                      <img src={image2} alt="" className="md:m-[20px]" />
                      <div className="md:w-[350px] mt-[5px] md:mt-0 w-[300px] md:m-[25px] md:mb-[20px]">
                        <h2 className="text-[#074381] md:text-[22px] text-[16px] font-[500]  ">
                          {detail.cardDetail}
                          <span className="text-[#1174AC] ml-[5px] rounded text-[12px]   md:text-[14px] md:font-[700] bg-[#1174AC1F] md:px-[12px] md:py-[4px]">
                            Marketing
                          </span>
                        </h2>

                        <h3 className="text-[#747474] text-[13px] md:text-[18px] font-[400] mt-[10px]">
                          {detail.paragraph}
                        </h3>
                        <button className="text-[#D46309] md:text-[14px] text-[13px] font-[700] md:mt-[8px]">
                          Read more&gt;&gt;
                        </button>
                        <div className="flex justify-between mt-[25px] md:mt-[34px]">
                          <div className="flex">
                            <img
                              src={logo}
                              alt=""
                              className="w-[22px] h-[22px] md:w-[34px] md:h-[34px]"
                            />
                            <h2 className="text-[#509ADE] md:ml-[16px] ml-[8px] text-[14px] md:mt-[4px] md:text-[16px] font-[700]">
                              Alex Nathon
                            </h2>
                          </div>
                          <h3 className="text-[#3A4047] md:text-[14px] text-[14px] font-[400] md:font-[600]">
                            Fab 25, 2022
                          </h3>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="md:flex ">
          <div className="bg-[#FFFFFF] hidden md:block border-[#CACACA7D] border-[1px] w-[280px] h-[900px] mt-[80px] mb-[50px] ml-[30px]">
            <h2 className="text-[#0D0D0D] font-monterrat text-[24px] font-[600] mt-[27px] ml-[28px]">
              Categories
            </h2>
            <ul className="cursor-pointer  ">
              <li
                className={`text-[#615F5F] text-[18px] font-[400] mt-[35px] hover:bg-[#124870] hover:text-[#FFFFFF] ${
                  allProducts && "bg-[#124870] text-[#FFF]"
                } pl-[31px] `}
                onClick={allproductsHandler}
              >
                All products
              </li>
              <li
                className={`text-[#615F5F] text-[18px] font-[400] pl-[31px] mt-[18px] hover:bg-[#124870] hover:text-[#FFFFFF] ${
                  accounting && "bg-[#124870] text-[#FFF]"
                } `}
                onClick={accountingHandler}
              >
                Accounting tools
              </li>
              <li className="text-[#615F5F] text-[18px] font-[400] pl-[31px] mt-[18px] hover:bg-[#124870] hover:text-[#FFFFFF] ">
                CRM
              </li>
              <li className="text-[#615F5F] text-[18px] font-[400] pl-[31px] mt-[18px] hover:bg-[#124870] hover:text-[#FFFFFF] ">
                Marketing
              </li>
              <li className="text-[#615F5F] text-[18px] font-[400] pl-[31px] mt-[18px] hover:bg-[#124870] hover:text-[#FFFFFF] ">
                Sales
              </li>
              <li className="text-[#615F5F] text-[18px] font-[400] pl-[31px] mt-[18px] hover:bg-[#124870] hover:text-[#FFFFFF] ">
                Developer tools
              </li>
              <li className="text-[#615F5F] text-[18px] font-[400] pl-[31px] mt-[18px] hover:bg-[#124870] hover:text-[#FFFFFF] ">
                Blogs
              </li>
              <li className="text-[#615F5F] text-[18px] font-[400] pl-[31px] mt-[18px] hover:bg-[#124870] hover:text-[#FFFFFF] ">
                Selling
              </li>
              <li className="text-[#615F5F] text-[18px] font-[400] pl-[31px] mt-[18px] hover:bg-[#124870] hover:text-[#FFFFFF] ">
                Supports
              </li>
              <li className="text-[#615F5F] text-[18px] font-[400] pl-[31px] mt-[18px] hover:bg-[#124870] hover:text-[#FFFFFF] ">
                Blogs
              </li>
              <li className="text-[#615F5F] text-[18px] font-[400] pl-[31px] mt-[18px] hover:bg-[#124870] hover:text-[#FFFFFF] ">
                WebHosting
              </li>
              <li className="text-[#615F5F] text-[18px] font-[400] pl-[31px] mt-[18px] hover:bg-[#124870] hover:text-[#FFFFFF] ">
                Branding
              </li>
              <li className="text-[#615F5F] text-[18px] font-[400] pl-[31px] mt-[18px] hover:bg-[#124870] hover:text-[#FFFFFF] ">
                Content
              </li>
              <li className="text-[#615F5F] text-[18px] font-[400] pl-[31px] mt-[18px] hover:bg-[#124870] hover:text-[#FFFFFF] ">
                Human resource
              </li>
              <li className="text-[#615F5F] text-[18px] font-[400] pl-[31px] mt-[18px] hover:bg-[#124870] hover:text-[#FFFFFF] ">
                File sharing
              </li>
              <li className="text-[#615F5F] text-[18px] font-[400] pl-[31px] mt-[18px] hover:bg-[#124870] hover:text-[#FFFFFF] ">
                HRMS
              </li>
              <li className="text-[#615F5F] text-[18px] font-[400] pl-[31px] mt-[18px] hover:bg-[#124870] hover:text-[#FFFFFF] ">
                Networking
              </li>
              <li className="text-[#615F5F] text-[18px] font-[400] pl-[31px] mt-[18px] hover:bg-[#124870] hover:text-[#FFFFFF] ">
                Database
              </li>
            </ul>
          </div>     
          <div className="md:hidden">
          <div className="flex flex-col justify-center items-center px-[20px]">
            <h2 className="text-[20px] text-[#074381] font-[700]">
              Blog Categories
            </h2>
            <div className="w-full mt-[15px]">
              <div
                onClick={(e) => setIsActive(!isActive)}
                className="flex justify-between items-center px-[16px] text-[18px] text-[#615F5F] font-[700] w-full h-[44px] border-[1px] border-[#1248703D]"
              >
                {selected}
                <span>
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.0558 6.44531H3.94484C3.5216 6.44531 3.28527 6.89219 3.54738 7.19727L10.6029 15.3785C10.8048 15.6127 11.1937 15.6127 11.3978 15.3785L18.4532 7.19727C18.7154 6.89219 18.479 6.44531 18.0558 6.44531Z"
                      fill="#124870"
                      fill-opacity="0.8"
                    />
                  </svg>
                </span>
              </div>
              {isActive && (
                <ul className=" border-[1px] border-[#1248703D] ">
                  {/* <li onClick={e =>setSelected(e.target.textContent)} className="text-[18px] text-[#615F5F] font-[700] hover:bg-[#124870]">All Products </li> */}

                  <li
                    className={`text-[#615F5F] text-[18px] font-[700] mt-[10px]   ${
                      allProducts && ""
                 } pl-[18px]  `}
                    // onClick={(e) => setSelected(e.target.textContent)}
                    onClick={allproductsHandler2}
                    
                    
                  >
                    All products
                  </li>
                  <li
                    className={`text-[#615F5F] text-[18px] font-[700] pl-[18px] mt-[18px]     ${
                      accounting && ""
                    } `}
                    onClick={accountingHandler2}
                  >
                    Accounting tools
                  </li>
                  <li
                    onClick={(e) => setSelected(e.target.textContent)}
                    className="text-[#615F5F] text-[18px] font-[700] pl-[18px] mt-[18px]      "
                  >
                    CRM
                  </li>
                  <li
                    onClick={(e) => setSelected(e.target.textContent)}
                    className="text-[#615F5F] text-[18px] font-[700] pl-[18px] mt-[18px]      "
                  >
                    Marketing
                  </li>
                  <li
                    onClick={(e) => setSelected(e.target.textContent)}
                    className="text-[#615F5F] text-[18px] font-[700] pl-[18px] mt-[18px]      "
                  >
                    Sales
                  </li>
                  <li
                    onClick={(e) => setSelected(e.target.textContent)}
                    className="text-[#615F5F] text-[18px] font-[700] pl-[18px] mt-[18px]      "
                  >
                    Developer tools
                  </li>
                  <li
                    onClick={(e) => setSelected(e.target.textContent)}
                    className="text-[#615F5F] text-[18px] font-[700] pl-[18px] mt-[18px]      "
                  >
                    Blogs
                  </li>
                  <li
                    onClick={(e) => setSelected(e.target.textContent)}
                    className="text-[#615F5F] text-[18px] font-[700] pl-[18px] mt-[18px]      "
                  >
                    Selling
                  </li>
                  <li
                    onClick={(e) => setSelected(e.target.textContent)}
                    className="text-[#615F5F] text-[18px] font-[700] pl-[18px] mt-[18px]      "
                  >
                    Supports
                  </li>
                  <li
                    onClick={(e) => setSelected(e.target.textContent)}
                    className="text-[#615F5F] text-[18px] font-[700] pl-[18px] mt-[18px]      "
                  >
                    Blogs
                  </li>
                  <li
                    onClick={(e) => setSelected(e.target.textContent)}
                    className="text-[#615F5F] text-[18px] font-[700] pl-[18px] mt-[18px]      "
                  >
                    WebHosting
                  </li>
                  <li
                    onClick={(e) => setSelected(e.target.textContent)}
                    className="text-[#615F5F] text-[18px] font-[700] pl-[18px] mt-[18px]      "
                  >
                    Branding
                  </li>
                  <li
                    onClick={(e) => setSelected(e.target.textContent)}
                    className="text-[#615F5F] text-[18px] font-[700] pl-[18px] mt-[18px]      "
                  >
                    Content
                  </li>
                  <li
                    onClick={(e) => setSelected(e.target.textContent)}
                    className="text-[#615F5F] text-[18px] font-[700] pl-[18px] mt-[18px]      "
                  >
                    Human resource
                  </li>
                  <li
                    onClick={(e) => setSelected(e.target.textContent)}
                    className="text-[#615F5F] text-[18px] font-[700] pl-[18px] mt-[18px]      "
                  >
                    File sharing
                  </li>
                  <li
                    onClick={(e) => setSelected(e.target.textContent)}
                    className="text-[#615F5F] text-[18px] font-[700] pl-[18px] mt-[18px]      "
                  >
                    HRMS
                  </li>
                  <li
                    onClick={(e) => setSelected(e.target.textContent)}
                    className="text-[#615F5F] text-[18px] font-[700] pl-[18px] mt-[18px]      "
                  >
                    Networking
                  </li>
                  <li
                    onClick={(e) => setSelected(e.target.textContent)}
                     className="text-[#615F5F] text-[18px] font-[700] pl-[18px] mt-[18px]     "
                  >
                    Database
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div> 
          <div>
            {allProducts && (
              <div
                className={`md:mt-[80px] mt-[40px]  md:ml-[20px] grid md:grid-cols-3 grid-cols-1 gap-[20px] md:mb-[48px] px-[25px] md:px-0 `}
              >
                {details.map((detail) => {
                  return (
                    <div className="md:w-[320px] bg-[#FFFFFF] shadow-sm rounded-[18px] p-[18px]">
                      <img src={image3} alt="" className="" />
                      <h2 className="text-[#074381] md:text-[20px] text-[16px] font-[500] mt-[18px]">
                        {detail.para}
                      </h2>
                      <h3 className="text-[#747474] md:text-[18px] font-[400] text-[13px] mt-[18px] w-[280px]">
                        {detail.paratwo}
                      </h3>
                      <button className="text-[#D46309] text-[14px] font-[700] md:mt-[8px]">
                        Read more&gt;&gt;
                      </button>

                      <div className="flex justify-between mt-[20px] md:mt-[34px]">
                        <div className="flex">
                          <img src={logo} alt="" />
                          <h2 className="text-[#509ADE] ml-[11px] md:ml-[16px] mt-[4px]  md:text-[16px] md:font-[700]">
                            Alex Nathon
                          </h2>
                        </div>
                        <h3 className="text-[#3A4047] text-[13px] mt-[8px] md:text-[14px] md:font-[600]">
                          Fab 25, 2022
                        </h3>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}

            {accounting && (
              <div className="md:mt-[80px] md:ml-[20px] ">
                {account.map((detail) => {
                  return (
                    <div className="flex md:mb-[24px]  ">
                      <div className="md:w-[1000px] md:h-[248px] mt-5 shadow-sm  bg-[#FFFFFF] pt-[30px] md:pt-0 px-[20px] md:px-0 pb-[10px] md:pb-0 md:rounded-[20px] rounded-[12px] flex">
                        <img src={image4} alt=""  className="w-[52px] mt-[8px] md:mt-0 h-[52px] md:w-[248px] md:h-[248px]"/>
                        <div className="md:ml-[28px] md:mt-[12px]">
                          <h2 className="text-[#074381] md:text-[22px] ml-[10px] md:ml-0 text-[16px] font-[500]">
                            {detail.para}
                          </h2>
                          <div className="ml-[-52px] md:ml-0">
                          <h3 className="text-[#747474] md:text-[14px] text-[13px] font-[400] mt-[12px] md:mt-[20px]">
                            {detail.paratwo}
                          </h3>
                          <div className="flex mt-[20px]">
                            <div className="md:w-[34px] md:h-[34px] w-[30px] h-[30px] rounded-[50%] ">
                              <img src={logo} alt="" />
                            </div>
                            <h2 className="text-[#509ADE] text-[16px] font-[700] ml-[11px]">
                              Alex Nathon
                            </h2>
                          </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}

            <div className="md:flex mt-[50px] hidden mb-[50px] ml-[45%] justify-self-center">
              <svg
                width="12"
                height="20"
                viewBox="0 0 12 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.6023 2.99922L4.60234 9.99922L11.6023 16.9992L10.2023 19.7992L0.402344 9.99922L10.2023 0.199219L11.6023 2.99922Z"
                  fill="#747474"
                />
              </svg>
              <p className="text-[#747474] text-[20px] font-[500] mx-[25px] -mt-[10px]">
                1 2 3 4
              </p>
              <svg
                width="12"
                height="20"
                viewBox="0 0 12 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.402344 16.9992L7.40234 9.99922L0.402344 2.99922L1.80234 0.199219L11.6023 9.99922L1.80234 19.7992L0.402344 16.9992Z"
                  fill="#747474"
                />
              </svg>
            </div>
          </div>
         
        </div>
      
      </div>
      <Footer />
    </div>
  );
}
