import image from "../../assets/image/carouselimg.png";
import image1 from "../../assets/image/carouselimg1.png";
import image2 from "../../assets/image/carouselimg2.png";
import image3 from "../../assets/image/carouselimg3.png";
import React from "react";
import { Carousel } from "react-responsive-carousel";

export default () => (
  <div className="md:py-[80px] md:px-[60px]">
    <Carousel autoPlay>
      <div className="font-nunito">
        <div className="bg-[#E3F2F5] px-[20px] lg:px-0  py-[30px] lg:py-0 lg:pt-[44px] lg:pb-[56px] lg:rounded-[58px] ">
          <div className="flex mb-[16px] md:hidden flex-col justify-center items-center">
            <h2 className="text-[#074381] text-[20px] font-[600]">
              Dashboard{" "}
            </h2>
            <h3 className="text-[#747474D1] text-[16px] font-[500]">
              Elevate Your Business with Dashboard's Leading Indicators.{" "}
            </h3>
          </div>
          <div className="flex">
            <img src={image} alt="" className="lg:ml-[44px] " />
            <div className="text-end hidden md:block lg:ml-[65px] mr-[52px]">
              <h2 className="text-[#074381] text-[32px] font-[800] mt-[15px]">
                Dashboard
              </h2>
              <h3 className="text-[#747474] text-[24px] font-[800] mt-[36px]">
                Elevate Your Business with Dashboard's Leading Indicators: Drive Proactive Decisions, Boost Performance, and Stay Ahead of the Curve!
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="bg-[#E3F2F5] px-[20px] lg:px-0  py-[30px] lg:py-0 lg:pt-[44px] lg:pb-[56px] lg:rounded-[58px] ">
          <div className="flex mb-[16px] md:hidden flex-col justify-center items-center">
            <h2 className="text-[#074381] text-[20px] font-[600]">
              Customer portal {" "}
            </h2>
            <h3 className="text-[#747474D1] text-[16px] font-[500]">
              A powerful tool for External Operations.{" "}
            </h3>
          </div>
          <div className="flex">
            <img src={image2} alt="" className="lg:ml-[44px]" />
            <div className="text-end hidden md:block ml-[58px] mr-[52px]">
              <h2 className="text-[#074381] text-[32px] font-[800] mt-[15px]">
                Customer portal <br/> External operations
              </h2>
              <h3 className="text-[#747474] text-[24px] font-[800] mt-[36px]">
                A powerful tool that serves as a valuable tool for businesses to better serve their customers, optimize their operations, and ultimately drive growth and success.
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="bg-[#E3F2F5] px-[20px] lg:px-0  py-[30px] lg:py-0 lg:pt-[44px] lg:pb-[56px] lg:rounded-[58px] ">
          <div className="flex mb-[16px] md:hidden flex-col justify-center items-center">
            <h2 className="text-[#074381] text-[20px] font-[600]">
              Employee portal{" "}
            </h2>
            <h3 className="text-[#747474D1] text-[16px] font-[500]">
              A tool to enhance Internal Operations.{" "}
            </h3>
          </div>

          <div className="flex">
            <img src={image1} alt="" className="lg:ml-[44px]" />
            <div className="text-end hidden md:block ml-[58px] mr-[52px]">
              <h2 className="text-[#074381] text-[32px] font-[800] mt-[15px]">
                Employee portal <br/> Internal operations
              </h2>
              <h3 className="text-[#747474] text-[24px] font-[800] mt-[36px]">
                A tool to enhance daily operations & employee productivity, a more organized & efficient work environment, ultimately leading to improved business outcomes.
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="bg-[#E3F2F5] px-[20px] lg:px-0  py-[30px] lg:py-0 lg:pt-[44px] lg:pb-[56px] lg:rounded-[58px] ">
          <div className="flex mb-[16px] md:hidden flex-col justify-center items-center">
            <h2 className="text-[#074381] text-[20px] font-[600]">
              B2B E-Commerce{" "}
            </h2>
            <h3 className="text-[#747474D1] text-[16px] font-[500]">
              A no-code feature allows you to set up your personalized e-commerce site within minutes.{" "}
            </h3>
          </div>
          <div className="flex">
            <img src={image3} alt="" className="lg:ml-[44px]" />
            <div className="text-end hidden md:block ml-[58px] mr-[52px]">
              <h2 className="text-[#074381] text-[30px] font-[800] mt-[15px]">
                B2B E - Commerce <br/> Market your business
              </h2>
              <h3 className="text-[#747474] text-[24px] font-[800] mt-[36px]">
                A no-code feature allows you to set up your personalized e-commerce site within minutes. Build your custom site, share pricing selectively, and expand your market reach effortlessly 
              </h3>
            </div>
          </div>
        </div>
      </div>
    </Carousel>
  </div>
);

//     <div>
//       <div className="bg-[#E3F2F5] mt-[80px] pt-[44px] pb-[56px] ">
//         <div className="flex">
//           <img src={image} alt="" className="ml-[44px]" />
//           <div className="text-end ml-[58px] mr-[52px]">
//             <h2 className="text-[#074381] text-[36px] font-[700] mt-[15px]">
//               Personal Dashboard, Experience Lorem Ipsum
//             </h2>
//             <h3 className="text-[#747474] text-[32px] font-[500] mt-[36px]">
//               Lorem ipsum dolor sit amet, sem per consectetur adipiscing elit. A
//               psum egestas naid loin your pha.
//             </h3>
//           </div>
//         </div>
//         <div
//           className="flex
// "
//         >
//           <div className="w-[12px] h-[12px] rounded-[50%] bg-[#FFFFFF] border-[1px] border-[#124870]"></div>
//           <div className="w-[12px] h-[12px] rounded-[50%] bg-[#FFFFFF] border-[1px] border-[#124870]"></div>
//           <div className="w-[12px] h-[12px] rounded-[50%] bg-[#FFFFFF] border-[1px] border-[#124870]"></div>
//           <div className="w-[12px] h-[12px] rounded-[50%] bg-[#FFFFFF] border-[1px] border-[#124870]"></div>
//         </div>
//       </div>
// </div>
//   );
// }
