import react from "react";
import image from "../../assets/image/cardimg.png";
import { Link, useNavigate } from "react-router-dom";
import ResponsiveNav from "../../components/responsiveNav";

export default function ResKnowMoreCard() {
  const navigate = useNavigate();

  return (
    <div>
    <ResponsiveNav Main={"Store"}/>
    <button onClick={() => navigate(-1)} className="text-[#747474] mb-4 md:hidden ml-5 mt-5 text-[14px] font-[700] gap-1 flex">
          <span className="mt-[3px] mr-1">
            <svg
              width="16"
              height="14"
              viewBox="0 0 16 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 6.00125H3.14L6.77 1.64125C6.93974 1.43704 7.0214 1.17375 6.99702 0.909329C6.97264 0.644902 6.84422 0.400991 6.64 0.231252C6.43578 0.0615137 6.1725 -0.0201482 5.90808 0.0042315C5.64365 0.0286112 5.39974 0.157036 5.23 0.361252L0.23 6.36125C0.196361 6.40898 0.166279 6.45911 0.14 6.51125C0.14 6.56125 0.14 6.59125 0.0700002 6.64125C0.0246737 6.75591 0.000941121 6.87796 0 7.00125C0.000941121 7.12454 0.0246737 7.24659 0.0700002 7.36125C0.0700002 7.41125 0.0699999 7.44125 0.14 7.49125C0.166279 7.54339 0.196361 7.59353 0.23 7.64125L5.23 13.6413C5.32402 13.7541 5.44176 13.8449 5.57485 13.9071C5.70793 13.9694 5.85309 14.0015 6 14.0013C6.23365 14.0017 6.46009 13.9203 6.64 13.7713C6.74126 13.6873 6.82496 13.5842 6.88631 13.4679C6.94766 13.3515 6.98546 13.2242 6.99754 13.0932C7.00961 12.9622 6.99573 12.8302 6.95669 12.7046C6.91764 12.579 6.8542 12.4623 6.77 12.3613L3.14 8.00125H15C15.2652 8.00125 15.5196 7.8959 15.7071 7.70836C15.8946 7.52082 16 7.26647 16 7.00125C16 6.73604 15.8946 6.48168 15.7071 6.29415C15.5196 6.10661 15.2652 6.00125 15 6.00125Z"
                fill="#747474"
              />
            </svg>
          </span>{" "}
          Back
        </button>
    <div className="w-full">
      <div className=" w-full md:hidden bg-[#FFFFFF] relative p-[24px]   ">
        <div className="flex flex-col justify-center items-center ">
          <div className="mt-[20px] w-[190px] h-[165px] flex justify-center items-center">
            <img src={image} alt="" />
          </div>
        </div>
        <div className="flex flex-col justify-center items-center mt-[20px]">
          <h2 className="text-[#124870] text-[24px] font-[600]">LoremIp</h2>
          <h3 className="text-[#615F5F] text-[20px] font-[600] mt-[12px]">
            Accounting tools
          </h3>
          <p className="text-[#9B9B9B] text-[16px] font-[600] mt-[30px]">
            Lorem ipsum dolor sit amet, consec tetur adip is cing elit. Quisque
            est male suada dict umst morbi varius justo, vulputate tincidunt
            nequ eest males.
          </p>
        </div>
        <div className="mt-[15px]">
          <div className="flex justify-between ">
            <h2 className="text-[#797979] text-[16px] font-[700]">
              Offered By:
            </h2>
            <h2 className="text-[#124870] text-[16px] font-[700]">
              SolutionsPvt.
            </h2>
          </div>
          <div className="flex justify-between mt-[5px]">
            <h2 className="text-[#797979] text-[16px] font-[700]">
              Connection Type:
            </h2>
            <h2 className="text-[#124870] text-[16px] font-[700]">TallySync</h2>
          </div>
          <div className="flex justify-between mt-[5px]">
            <h2 className="text-[#797979] text-[16px] font-[700]">Price:</h2>
            <h2 className="text-[#E47C07] text-[16px] font-[700]">₹4000.90</h2>
          </div>
        </div>
        <div>
          <button
            onClick={() => navigate("/productDetail")}
            className="bg-[#FFA502] text-[#FFFFFF] py-[13px] mt-[5px] w-full rounded-[2px] text-[16px] font-[600]"
          >
            Buy
          </button>
          <button
            onClick={() => navigate("/productDetail")}
            className="border-[#124870] border-[1px] mt-[10px] py-[13px] w-full rounded-[2px] text-[#124870] text-[16px] font-[600]"
          >
            See details
          </button>
        </div>
      </div>
    </div>
    </div>
  );
}
